import React from "react";
import translate from "../../../translations/translations.wrapper.jsx";
import "./datatooltip.scss";
import "./actionlessComments.scss";
import spinner from "assets/spinner.svg";
import { useSelector } from "react-redux";
import * as reduxSelectors from "../../../store/application.reducers";
import CommentsList from "components/taskdetails-remake/panels/newcomments/CommentsList.component";
import CommentsInput from "components/taskdetails-remake/panels/newcomments/CommentsInput.component";
import { CommentsPanelProps } from "types/comments";
import CommentsProfile from "components/taskdetails-remake/panels/newcomments/CommentsProfile.component";
import CommentsValidation from "components/taskdetails-remake/panels/newcomments/CommentsValidation.component";
import { COMMENT_SAVING_STATUS, COMMENTS_INPUT_MAX_LENGTH } from "utils/constants";

const CommentsPanel: React.FC<CommentsPanelProps> = (props) => {

    const currentUser = useSelector(reduxSelectors.getUsersLoggedInData);
    const validationError = props.comment?.length > COMMENTS_INPUT_MAX_LENGTH ? " validation-error" : "";

    return (
        <div className="d-flex align-items-stretch flex-column h-100">
            <div className="grid-panel-header">
                <strong>
                    {props.translate("taskDetail.comments")}
                    <span
                        className="mx-2 badge badge-grayed-out"> {props.commentsCount <= 999 ? props.commentsCount : "999+"}</span>
                </strong>
            </div>

            <CommentsList readOnly={props.readOnly} />
            {!(props.readOnly) &&
                <div className=" d-flex flex-column mt-auto py-16 pr-24 pl-0 border-box-sizing new-comment-area">
                    <div className="d-flex flex-row">
                        <div className="d-flex align-items-center">
                            <CommentsProfile
                                connectUserId={currentUser.connectUserId}
                                userName={currentUser.fullName} />
                        </div>
                        <div
                            className="col-md-12 pr-40 align-self-stretch">
                            <div className={"d-flex new-comments-input-with-tip" + (props.offline ? " disabled" : "")}>
                                <div
                                    className={"d-flex w-100 rounded-8 pl-8 input-border input-margin-left " + validationError}>
                                    <CommentsInput id="comments"
                                                   value={props.comment ? props.comment : ""}
                                                   placeholder={props.translate("taskDetail.sendComment")}
                                                   onChange={props.onChange}
                                                   disabled={props.offline}
                                    />
                                    {props.savingCommentStatus === COMMENT_SAVING_STATUS.SAVING ?
                                        <img src={spinner}
                                             alt={"saving"}
                                             className="d-flex align-items-end m-16 new-comments-saving-spinner" /> :
                                        props.saveCommentContent
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <CommentsValidation comment={props.comment}
                                        offline={props.offline}
                                        automaticSave={props.automaticSave} />
                </div>
            }
        </div>
    );
};

const withTranslations = translate(CommentsPanel);

export default withTranslations;