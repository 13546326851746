import * as Api from 'utils/api/api';
import {
    SSE_PROCESS_OVERVIEW_LOAD_COMPLETED,
    SSE_PROCESS_OVERVIEW_LOAD_MORE,
    SSE_PROCESS_ADVANCED_SEARCH_OPEN,
    SSE_PROCESS_OVERVIEW_COST_UNITS_LOADED,
    SSE_PROCESS_OVERVIEW_FILTER_CHANGED,
    SSE_PROCESS_OVERVIEW_INITIALIZE,
    SSE_PROCESS_OVERVIEW_LOADING,
    SSE_PROCESS_OVERVIEW_USER_ROLES_LOADED,
    SSE_PROCESS_PREVENT_DATA_RELOAD,
    SSE_PROCESS_SAVE_SCROLL_POSITION,
    SSE_PROCESS_OVERVIEW_LOAD_COMPLETE_ERROR,
} from "../store/actionTypes";

import {handleError} from 'utils/errorHandle.function';
import {returnCostUnits} from "components/myhistory/getCostUnits.function";
import _ from "lodash";
import {EventStreamContentType} from "@microsoft/fetch-event-source";
import { snowplowTrackEvent } from "utils/snowplow";

export function processOverviewLoading(cursor) {
    return {
        type: SSE_PROCESS_OVERVIEW_LOADING,
        data: cursor
    };
}

export function processOverviewInit() {
    return {
        type: SSE_PROCESS_OVERVIEW_INITIALIZE,
        data: null
    }
}

export function processOverviewLoadMore(data) {
    return {
        type: SSE_PROCESS_OVERVIEW_LOAD_MORE,
        data: data
    }
}

export function processOverviewLoadCompleted(data) {
    return {
        type: SSE_PROCESS_OVERVIEW_LOAD_COMPLETED,
        data: data
    }
}

export function processOverviewLoadCompleteException(data) {
    return {
        type: SSE_PROCESS_OVERVIEW_LOAD_COMPLETE_ERROR,
        data: data
    }
}

export function filterChanged(filter) {
    if (abortController != null) {
        console.error("Aborting request: filterChanged");
        abortController.abort();
    }

    return {
        type: SSE_PROCESS_OVERVIEW_FILTER_CHANGED,
        filter: filter
    };
}

export function toggleAdvancedSearch(isOpen) {
    return {
        type: SSE_PROCESS_ADVANCED_SEARCH_OPEN,
        advancedSearchOpen: isOpen
    };
}

export function costUnitsLoaded(costUnits = []) {
    return {
        type: SSE_PROCESS_OVERVIEW_COST_UNITS_LOADED,
        data: costUnits
    };
}

export function preventProcessReload(isStopDataReload) {
    return {
        type: SSE_PROCESS_PREVENT_DATA_RELOAD,
        data: isStopDataReload
    };
}

export function saveProcessScrollPosition(topScroll) {
    return {
        type: SSE_PROCESS_SAVE_SCROLL_POSITION,
        data: topScroll
    };
}

export function getUserRoles(data) {
    return {
        type: SSE_PROCESS_OVERVIEW_USER_ROLES_LOADED,
        data: data
    }
}


export function startInitialLoad() {
    return function (dispatch) {
        dispatch(processOverviewInit());

        let value = {};

        let getAssignmentType = Api.getAssignmentType().then(response => {
                value.userRoles = response;
            },
            error => {
                handleError(error);
            });

        return Promise.all([getAssignmentType]).then(
            () => {
                return dispatch(getUserRoles(value));
            }
        );
    }
}

export function getCostUnits() {
    return function (dispatch) {
        dispatch(returnCostUnits(costUnitsLoaded));
    }
}

class RetriableError extends Error {
}

class FatalError extends Error {
}

/**
 *
 * @type {null|AbortController}
 */
let abortController = null;

/**
 * @param {any} filters
 * @param {any} cursor
 */
export function loadMoreOverview(filters, cursor, userData) {
    return function (dispatch) {
        dispatch(processOverviewLoading(cursor));

        /**
         * @type {any[]}
         */
        let list = [];
        /**
         * @type {null|string}
         */
        let savedCursor = null;
        let retriableCounter = 0;

        let debouncedDispatch = _.debounce(() => {
            if (list.length > 0) {
                let rows = [...list];
                list = [];
                dispatch(processOverviewLoadMore({
                    page: 1,
                    records: 1,
                    cursor: savedCursor,
                    rows: rows
                }));
            }
        }, 100);

        if (abortController != null) {
            console.error("Aborting request");
            abortController.abort();
        }
        abortController = new AbortController();

        Api.getProcessOverviewSse(filters, {
            signal: abortController.signal,
            async onopen(response) {
                if (response.ok) {
                    const contentType = response.headers.get('content-type');
                    if (!(contentType === null || contentType === void 0 ? void 0 : contentType.startsWith(EventStreamContentType))) {
                        throw new Error(`Expected content-type to be ${EventStreamContentType}, Actual: ${contentType}`);
                    }
                } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
                    throw new FatalError("Client error");
                } else {
                    throw new RetriableError();
                }
            },
            onmessage: (event) => {
                if (event.event === "record") {
                    let processRecord = JSON.parse(event.data);
                    list.push(processRecord);
                    savedCursor = event.id;
                    debouncedDispatch();
                } else if (event.event === "completion") {
                    if (list.length > 0) {
                        debouncedDispatch.flush();
                    }
                    const cursor = (event.id != null && event.id !== "") ? event.id : null;
                    let completionEvent = JSON.parse(event.data);
                    if(completionEvent.state !== "EXCEPTION") {
                        abortController = null;
                    }
                    switch (completionEvent.state) {
                        case "OK":
                            dispatch(processOverviewLoadCompleted({cursor : cursor}));
                            break;
                        case "NO_MORE_DATA":
                            dispatch(processOverviewLoadCompleted({cursor : cursor}));
                            snowplowTrackEvent({
                                category: "see-process-overview",
                                action: "loadMoreOverview",
                                label: "NO_MORE_DATA",
                                value: event.id
                            }, userData);
                            break;
                        case "EXCEPTION":
                        case "TIME_EXCEEDED":
                        case "ABORTED":
                            dispatch(processOverviewLoadCompleteException({
                                cursor: cursor,
                                error: completionEvent.state,
                            }));
                            snowplowTrackEvent({
                                category: "see-process-overview",
                                action: "loadMoreOverview",
                                label: completionEvent.state,
                                value: event.id
                            }, userData);
                            break;
                        default: break;
                    }
                }
            },
            onclose: () => {
                if (abortController == null) {
                    return;
                }
                console.warn("connection closed before completion event");
                if (list.length > 0) {
                    debouncedDispatch.flush();
                }
                throw new RetriableError();
            },
            /**
             * @param {Error} err
             */
            onerror: (err) => {
                if (err instanceof FatalError) {
                    if (abortController != null) {
                        abortController.abort();
                    }
                    throw err;
                }
                else if(err instanceof RetriableError) {
                    retriableCounter++;
                    if(retriableCounter > 10){
                        retriableCounter = 0;
                        throw new FatalError("Too many retries");
                    }
                }
            }
        }, cursor).catch((error) => {
            console.error(error);
            handleError(error);
        });
    }
}
