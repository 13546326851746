import {AttachmentsForRenderProps} from "types/attachment";
import React, {useEffect} from "react";
import * as pdfjs from 'pdfjs-dist';
import {changeImageZoomPercentage} from "components/taskdetails-remake/taskDetails.action";
import Menu from "components/taskdetails-remake/panels/attachments/attachmentTypes/Images/Menu.function";
import {useSelector} from "react-redux";
import {Store} from "redux";
import * as reduxSelectors from "components/store/application.reducers";
import translate from "components/translations/translations.wrapper";
import AutoSizer from "react-virtualized/dist/es/AutoSizer";
import List from "react-virtualized/dist/es/List";
import {IMG_BASE} from "utils/constants";
import AlternativePageView
    from "components/taskdetails-remake/panels/attachments/attachmentTypes/AlternativePageView.function";

const OVERSCAN_ROW_COUNT = 5;
const IMG_MARGIN_BOTTOM = 16;

export const urlToBinary = async (url: string): Promise<ArrayBuffer> => {
    const encoder = await fetch(url);
    const arrayBuffer = await encoder.arrayBuffer();

    return arrayBuffer;
}

export const PdfAlternativeView = ({url, translate}: AttachmentsForRenderProps): React.ReactElement => {
    pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.mjs', import.meta.url).toString();

    const zoom = useSelector((store: Store) => reduxSelectors.getImageZoomPercentage(store));
    const [pdf, setPdf] = React.useState<pdfjs.PDFDocumentProxy>();
    const [totalPages, setTotalPages] = React.useState<number>(1);
    const [pageInView, setPageInView] = React.useState<number>(1);
    const [errorMessage, setErrorMessage] = React.useState<string>("");
    const [goToPage, setGoToPage] = React.useState<number | null>(null);


    const navigateToPage = (pageNumber: number) => {
        if (pageNumber <= totalPages && pageNumber > 0) {
            setPageInView(pageNumber - 1);
            setGoToPage(pageNumber - 1);
        }
    }

    const handleScroll = (event: any) => {
        const pageHeight = zoom ? ((zoom * IMG_BASE.height) / 100) - IMG_MARGIN_BOTTOM : IMG_BASE.height;
        let currentPage = parseInt(String((event.scrollTop / pageHeight)), 10) + 1;
        if (currentPage <= totalPages && currentPage > 0) {
            setPageInView(currentPage);
        }
    }


    useEffect(() => {
        if (url && !pdf) {
            urlToBinary(url).then(function (arrayBuffer) {
                let attachment = pdfjs.getDocument({data: arrayBuffer});
                attachment.promise.then(function (pdf) {
                    setPdf(pdf);
                    setTotalPages(pdf.numPages);

                }).catch(function (error) {
                    setErrorMessage(translate("taskDetail.panels.attachments.unavailableDetails"));
                });
            });
        }
    }, [url]);

    useEffect(() => {
        if (pdf && pageInView <= totalPages) {
            navigateToPage(pageInView);
        }
    }, [zoom]);

    const _rowRender = ({index, key, style}: any) => {
        return <AlternativePageView index={index} key={key} style={style} pdf={pdf} zoom={zoom}/>
    }


    return <div className={"attachment-image-container h-100"}>
        <Menu zoomPercentage={zoom} pageInView={pageInView} totalImages={totalPages}
              changeZoom={changeImageZoomPercentage} scrollToImage={navigateToPage}/>
        <div className={"alternative-pdf-container"}>
            {!pdf &&
                <span className={"spinner spinner-default-blue loading mt-128 float-left"}/>
            }
            {pdf &&
                <AutoSizer className="h-100 w-100">
                    {({width, height}: any) => (
                        <List
                            className=""
                            height={height || 500}
                            overscanRowCount={OVERSCAN_ROW_COUNT}
                            rowCount={totalPages}
                            rowHeight={(zoom * IMG_BASE.height) / 100}
                            rowRenderer={_rowRender}
                            id={"CANVAS_LIST"}
                            width={width || 500}
                            scrollToAlignment="start"
                            onScroll={handleScroll}
                            scrollToIndex={goToPage ? goToPage : undefined}
                        />
                    )}
                </AutoSizer>
            }

            {errorMessage &&
                <h3 className={"color-lightgrey"}>{errorMessage}</h3>
            }
        </div>
    </div>
}


export default translate(PdfAlternativeView);

