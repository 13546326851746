import React, {Fragment} from 'react';
import {Form} from 'react-bootstrap';
import NumericInput from 'react-numeric-input';
import SettingsButtonsBarView from './SettingsButtonsBarView.component';
import * as Api from 'utils/api/api';
import {loadUserData} from '../usercontext/user.action';
import {connect} from 'react-redux';
import translate from '../translations/translations.wrapper.jsx';
import {
    getUsersSettings,
    getUsersCurrentBusinessFeatures,
    getUsersData
} from '../store/application.reducers';
import SettingsWrapper from "./SettingsWrapper.component";
import {handleError, insufficientRightsError} from 'utils/errorHandle.function';
import {canViewConfiguration} from "../menu/Menu.component";
import {userHasAccessRights} from "utils/accessRights.function";

let canSave = true;
const TASK_SEQUENCE_OPTION = {
    OPEN_NEXT: "openNext",
    GO_BACK: "goBack"
};

export class OtherSettings extends SettingsWrapper {

    constructor() {
        super();
        this.state = {
            showCommentOnApprove: false,
            taskDueDays: 1,
            okEnabled: false,
            openNext: true,
            goBack: false
        };
        this.dueDayChange = this.dueDayChange.bind(this);
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
        this.openDialogChange = this.openDialogChange.bind(this);
        this.getData = this.getData.bind(this);
        this.changeTaskSequence = this.changeTaskSequence.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();

        if (!userHasAccessRights(this.props.userData.userRoles) && canViewConfiguration(this.props.userData.userRoles)) {
            handleError(insufficientRightsError);
            this.props.history.replace('/');
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.userSettings !== this.props.userSettings) {
            this.getData();
        }
    }

    getData(settings = this.props.userSettings) {
        this.setState({
            taskDueDays: settings.taskDueDays,
            showCommentOnApprove: settings.showCommentOnApprove,
            okEnabled: false,
            openNext: settings.nextTask,
            goBack: !settings.nextTask
        });
    }

    dueDayChange(value) {
        let {taskDueDays, showCommentOnApprove} = this.props.userSettings;
        let newState = Object.assign({}, this.state);
        let newValue = value;
        if (!Number.isInteger(newValue))
            newValue = 2;
        if (newValue < 0)
            newValue = 0;
        if (newValue > 14)
            newValue = 14;

        newState.taskDueDays = newValue;
        const enableOk = taskDueDays !== newState.taskDueDays || showCommentOnApprove !== newState.showCommentOnApprove;

        newState.okEnabled = enableOk
        this.props.blocker.shouldWaitForConfirmation(enableOk);
        this.setState({...newState});
    }

    changeTaskSequence(event, option) {
        let isChecked = event.target.checked;
        let newState = Object.assign({}, this.state);

        newState.openNext = option === TASK_SEQUENCE_OPTION.OPEN_NEXT ? isChecked : !isChecked;
        newState.goBack = option === TASK_SEQUENCE_OPTION.GO_BACK ? isChecked : !isChecked;
        //compare with this.props.userSettings instead of state
        const enableOk = newState.openNext !== this.state.openNext;
        newState.okEnabled = enableOk;
        this.props.blocker.shouldWaitForConfirmation(enableOk);
        this.setState({...newState});
    }

    openDialogChange(event) {
        let {taskDueDays, showCommentOnApprove} = this.props.userSettings;
        const enableOk = (taskDueDays !== this.state.taskDueDays || showCommentOnApprove !== event.target.checked);
        this.setState({
            showCommentOnApprove: event.target.checked,
            okEnabled: enableOk
        })
        this.props.blocker.shouldWaitForConfirmation(enableOk);
    }

    save() {
        if (canSave) {
            canSave = false;
            let newData = Object.assign({}, this.props.userSettings);
            newData.taskDueDays = this.state.taskDueDays;
            newData.showCommentOnApprove = this.state.showCommentOnApprove;
            newData.nextTask = this.state.openNext;
            Api.updateUserSettings(newData).then(response => {
                canSave = true;
                this.props.blocker.unblockNavigation();
                this.props.loadUserData();

            }, error => {
                canSave = true;
                this.setState({
                    okEnabled: true
                });
                this.props.blocker.unblockNavigation();
                handleError(error);
            });
        }
    }

    cancel() {
        this.props.loadUserData().then(() => {
            this.props.blocker.unblockNavigation();
        });
    }

    buildContent() {
        return (
            <section className="panel panel-default settings-panel" aria-labelledby="otherPreferencesTxt">
                <div className="panel-heading">
                    <div className="panel-title" id="otherPreferencesTxt">
                        {this.props.translate("settings.other.preferences")}
                    </div>
                </div>

                <article className="panel-body">
                    <label htmlFor="taskDueDaysInput">
                        {this.props.translate("settings.other.headsUp")} <NumericInput ref="taskDueDays"
                                                                                       id="taskDueDaysInput"
                                                                                       min={0} max={14}
                                                                                       tabIndex={0}
                                                                                       value={this.state.taskDueDays}
                                                                                       step={1} precision={0}
                                                                                       style={{input: {maxWidth: '70px'}}}
                                                                                       onChange={this.dueDayChange}/>
                        {this.props.translate("settings.other.workingDays")}
                    </label>


                    <hr className={"mt-3 mb-3"}/>
                    <Form.Check ref="showCommentOnApprove"
                                className={"checkbox"}
                                type="checkbox"
                                id={"showCommentOnApprove"}
                                checked={this.state.showCommentOnApprove}
                                onChange={this.openDialogChange}
                                label={this.props.translate("settings.other.openComment")}/>

                    <Fragment>
                        <hr className={"mt-3"}/>

                        <fieldset>
                            <legend className={"mb-3"}>{this.props.translate("settings.other.afterHandle")}</legend>

                            <Form.Check name="task-sequence-option" checked={this.state.openNext}
                                        type="radio"
                                        className="radio"
                                        id={"openNextOption"}
                                        onChange={(e) => {
                                            this.changeTaskSequence(e, TASK_SEQUENCE_OPTION.OPEN_NEXT)
                                        }}
                                        aria-label={this.props.translate("settings.other.afterHandle") + " " + this.props.translate("settings.other.openNextTask")}
                                        label={this.props.translate("settings.other.openNextTask")}/>


                            <br/>
                            <Form.Check name="task-sequence-option" checked={this.state.goBack}
                                        type="radio"
                                        className="radio"
                                        id={"goBack"}
                                        onChange={(e) => {
                                            this.changeTaskSequence(e, TASK_SEQUENCE_OPTION.GO_BACK)
                                        }}
                                        aria-label={this.props.translate("settings.other.afterHandle") + " " + this.props.translate("settings.other.returnToTaskList")}
                                        label={this.props.translate("settings.other.returnToTaskList")}/>


                        </fieldset>
                    </Fragment>

                    <SettingsButtonsBarView okCallback={this.save} cancelEnabled={this.state.okEnabled}
                                            okEnabled={this.state.okEnabled}
                                            cancelCallback={this.cancel}/>
                </article>
            </section>
        );
    }
}

const withTranslations = translate(OtherSettings);

const mapStateToProps = function (store) {
    return {
        userSettings: getUsersSettings(store),
        userBusinessFeatures: getUsersCurrentBusinessFeatures(store),
        userData: getUsersData(store)
    };
};

const connected = connect(mapStateToProps, {loadUserData})(withTranslations);
export default connected;