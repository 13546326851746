import React, {Component} from 'react';
import '../listWithActions/list.scss';
import * as reduxSelectors from '../store/application.reducers';
import {connect} from 'react-redux';
import UserSearch, {FindEmployees, ExceptionsRoles} from '../input/UserSearch.component';
import * as _ from "lodash";
import translate from '../translations/translations.wrapper.jsx';
import {EditDeleteButtons, SaveCancelButtons} from 'utils/listActionButtons.component';
import {handleError} from "../../utils/errorHandle.function";

export class Exceptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editMode: false,
            showNewRow: false
        };
        this.addRow = this.addRow.bind(this);
        this.cancelAddNewRow = this.cancelAddNewRow.bind(this);
        this.enterEditMode = this.enterEditMode.bind(this);
        this.exitEditMode = this.exitEditMode.bind(this);
        this.saveNewRow = this.saveNewRow.bind(this);
        this.editRow = this.editRow.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.changeRow = this.changeRow.bind(this);
        this.mapDataBeforeSave = this.mapDataBeforeSave.bind(this);
    }

    addRow() {
        this.setState({
            showNewRow: true,
            editMode: true
        });
    }

    cancelAddNewRow() {
        this.setState({
            showNewRow: false,
            editMode: false
        });
    }

    enterEditMode() {
        this.setState({editMode: true});
    }

    exitEditMode() {
        this.setState({editMode: false});
    }

    saveNewRow(newRow) {
        let newExceptions = this.props.data ? _.cloneDeep(this.props.data) : [];

        let duplicateException = false;

        newExceptions.forEach((row) => {
            if(row.value === newRow.value && row.role.value === newRow.role.value)
                duplicateException = true;
        });

        if(duplicateException){
            handleError({
                errorTitle: this.props.translate("costUnitExceptions.duplicateExceptionTitle"),
                errorMessages: this.props.translate("costUnitExceptions.duplicateException")
            });

            this.cancelAddNewRow();
            return;
        }

        newExceptions.push(newRow);

        this.props.changeExceptions(newExceptions);

        this.cancelAddNewRow();

        const mappedExceptions = this.mapDataBeforeSave(newExceptions);

        if (this.props.saveException) {
            const mappedNewException = mappedExceptions[mappedExceptions.length - 1];

            this.props.saveException(mappedExceptions, mappedNewException);
        } else {
            this.props.saveExceptions(mappedExceptions);
        }
    }

    deleteRow(row) {
        const roleNameExceptionId = this.props.data[row].id;

        let exceptions = _.cloneDeep(this.props.data);
        exceptions.splice(row, 1);

        this.props.changeExceptions(exceptions);
        let newExceptions = this.mapDataBeforeSave(exceptions);

        if (this.props.deleteException) {
            this.props.deleteException(roleNameExceptionId);
        } else {
            this.props.saveExceptions(newExceptions);
        }

    }

    mapDataBeforeSave(data) {
        let mappedData = data.map((exception) => {
            let users = exception.users ? (Array.isArray(exception.users) ? exception.users : [exception.users]).map((user => {
                return user.value
            })) : [];
            let result = {
                user: exception.value,
                users: users
            };

            if (this.props.showAuto)
                result.automaticApproval = exception.automaticApproval;
            if (exception.role)
                result.role = exception.role.value;
            result.id = exception.id;

            return result;
        });

        return mappedData;
    }

    editRow(newRow, index, id) {
        let newExceptions = this.mapDataBeforeSave(this.props.data);

        if (this.props.editException) {
            const exception = newExceptions.find((e) => e.id === id);

            this.props.editException(exception, id);
        } else {
            this.props.saveExceptions(newExceptions, id);
        }
    }

    changeRow(id, data) {
        let rows = _.cloneDeep(this.props.data);
        rows[id] = data;

        this.props.changeExceptions(rows);
    }


    render() {
        let rows = _.cloneDeep(this.props.data);

        let lines = (rows) ? rows.map((row, index) => {
            return <ExceptionsRow data={row}
                                  changeRow={this.changeRow}
                                  key={row.id || row.value}
                                  save={this.editRow}
                                  index={index}
                                  disabled={this.state.editMode || !this.props.allowActions}
                                  showAuto={this.props.showAuto}
                                  showRole={this.props.showRole}
                                  translate={this.props.translate}
                                  enterEditMode={this.enterEditMode}
                                  exitEditMode={this.exitEditMode}
                                  allowActions={this.props.allowActions}
                                  deleteRow={this.deleteRow}/>

        }) : [];

        return (
            <div className="actions-list no-side-padding">

                <ExceptionsHeaderRow translate={this.props.translate} showAuto={this.props.showAuto}
                                     showRole={this.props.showRole}/>

                <div className="exceptions-list">
                    {lines}
                    {this.state.showNewRow && this.props.allowActions &&
                    <NewRow cancelEdit={this.cancelAddNewRow}
                            save={this.saveNewRow}
                            rows={rows}
                            noDuplicates={this.props.noDuplicates}
                            showAuto={this.props.showAuto}
                            showRole={this.props.showRole}
                            translate={this.props.translate}/>
                    }
                    {this.props.allowActions &&
                    <AddNewRow addRow={this.addRow}
                               disabled={this.state.editMode}
                               showAuto={this.props.showAuto}
                               showRole={this.props.showRole}
                               noRows={lines.length <= 0}
                               translate={this.props.translate}/>
                    }
                </div>

            </div>
        )
    }
}

const withTranslations = translate(Exceptions);
const mapStateToProps = function (store) {
    return {
        workflows: reduxSelectors.getWorkflows(store),
        activeWorkflow: reduxSelectors.getActiveWorkflow(store),
        workflowChanged: reduxSelectors.getWorkflowChangedStatus(store)
    };
};

const connected = connect(mapStateToProps, {})(withTranslations);
export default connected;

const ExceptionsHeaderRow = (props) => {
    return (
        <div className="row header-row py-2 m-0">
            <div className={props.showRole ? "col-md-2" : "col-md-4"}>
                {props.translate("workflowDetails.exceptions.requester")}
            </div>
            {props.showRole &&
            <div className={"col-md-2"}>
                {props.translate("workflowDetails.exceptions.role")}
            </div>
            }
            <div className={props.showAuto ? "col-md-5" : "col-md-6"}>
                {props.translate("workflowDetails.exceptions.approver")}
            </div>
            {props.showAuto &&
            <div className="col-md-1 px-0">
                {props.translate("workflowDetails.exceptions.auto")}
                <span id="auto-tooltip" className="vismaicon vismaicon-sm vismaicon-filled vismaicon-help checkbox-icon"
                      title={props.translate("workflowDetails.exceptions.autoTooltip")}/>
            </div>
            }
            <div className="col-md-2 text-right no-padding">
                {props.translate("workflowDetails.exceptions.action")}
            </div>
        </div>
    )
};

const AddNewRow = (props) => {
    let iconDefaultClass = "vismaicon vismaicon-add-circle vismaicon-dynamic";
    let iconClass = props.disabled ? iconDefaultClass + " disabled" : iconDefaultClass;

    return (
        <div className="row py-2 m-0" onClick={props.addRow}>
            <div className="col-md-10 no-side-padding">
                {props.noRows &&
                    <span className="text-disabled">
                        {props.translate("workflowDetails.exceptions.noEmployees")}
                    </span>
                }
            </div>
            <div className="col-md-2 d-flex align-items-center justify-content-end">
                <span className={iconClass}/>
            </div>
        </div>
    )
};

class NewRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            approver: undefined,
            requester: undefined,
            automaticApproval: false,
            focusFirst: false,
            role: undefined,
            saveDisabled: true
        };

        this.cancel = this.cancel.bind(this);
        this.save = this.save.bind(this);
        this.setApprover = this.setApprover.bind(this);
        this.setRequester = this.setRequester.bind(this);
        this.setRole = this.setRole.bind(this);
        this.setAutomaticApproval = this.setAutomaticApproval.bind(this);
        this.checkSave = this.checkSave.bind(this);
    }

    componentDidMount() {
        this.setState({
            focusFirst: true
        });
    }

    cancel() {
        this.setState({
            approver: undefined,
            requester: undefined
        });

        this.props.cancelEdit();
    }

    checkSave() {
        let hasAllValue = Boolean(this.state.requester);

        if(Array.isArray(this.state.approver)) {
            hasAllValue = hasAllValue && this.state.approver && this.state.approver.length > 0;
        } else {
            hasAllValue = hasAllValue && this.state.approver;
        }

        if (this.props.showRole) {
            hasAllValue = hasAllValue && this.state.role;
        }

        this.setState({saveDisabled: !hasAllValue});
    }

    save() {
        let users = (!this.state.automaticApproval && this.state.approver) ? this.state.approver.map((user => {
            return {valid: user.valid, value: user.value, label: user.label};
        })) : [this.state.approver];

        let newRowValid = false;
        let newRowLabel = "";
        let newRowValue = "";

        if (this.state.requester) {
            newRowValid = this.state.requester.valid;
            newRowLabel = this.state.requester.label;
            newRowValue = this.state.requester.value;
        }

        let newRow = {
            valid: newRowValid,
            label: newRowLabel,
            value: newRowValue,
            users: users,
            role: this.state.role,
            automaticApproval: this.state.automaticApproval
        };

        this.props.save(newRow);
    }

    setApprover(value) {
        this.setState({approver: value}, () => {
            this.checkSave();
        });

    }

    setAutomaticApproval(event) {
        let approver = _.cloneDeep(this.state.approver);

        if (event.target.checked) {
            if (approver?.length > 0) {
                approver = {valid: approver[0].valid, label: approver[0].label, value: approver[0].value};
            }
        }
        else {
            approver = [{valid: approver.valid, label: approver.label, value: approver.value}];
        }

        this.setState({
            automaticApproval: event.target.checked,
            approver: approver
        }, () => {
            this.checkSave();
        });

    }

    setRequester(value) {
        this.setState({requester: value, focusFirst: false}, () => {
            this.checkSave();
        });
    }

    setRole(value) {
        this.setState({role: value}, () => {
            this.checkSave();
        });
    }

    render() {
        const filterValues = this.props.noDuplicates ? (this.props.rows || []).map((row) => row.value) : [];

        return (
            <div className="row editing align-items-center py-2">
                <div className={`${this.props.showRole ? "col-md-2" : "col-md-4"}`}>
                    <FindEmployees propagateValue={this.setRequester}
                                   filterValues={filterValues}
                                   value={this.state.requester}
                                   refresh={this.state.focusFirst}
                                   translate={this.props.translate}/>
                </div>

                {this.props.showRole &&
                <div className="col-md-2">
                    <ExceptionsRoles propagateValue={this.setRole}
                                     isRule={true}
                                     value={this.state.role}
                                     translate={this.props.translate}/>
                </div>
                }
                <div className={this.props.showAuto ? "col-md-5" : "col-md-6"}>
                    <UserSearch multi={!this.state.automaticApproval}
                                propagateValue={this.setApprover}
                                value={this.state.approver}
                                includeAdminApprover={true}
                                translate={this.props.translate}/>
                </div>
                {this.props.showAuto &&
                    <div className="col-md-1 text-center px-0 d-flex align-items-center justify-content-center">
                        <div className="checkbox m-0">
                            <input
                                id={`automatic-approval-checkbox-new`}
                                type="checkbox"
                                ref="automaticApproval"
                                onClick={this.setAutomaticApproval}
                            />
                            <label htmlFor={`automatic-approval-checkbox-new`} />
                        </div>
                    </div>
                }
                <div className="col-md-2 text-end d-flex align-items-center justify-content-end">
                    <SaveCancelButtons
                        saveCallback={this.save}
                        saveDisabled={this.state.saveDisabled}
                        cancelCallback={this.cancel}
                        translate={this.props.translate}
                    />
                </div>
            </div>
        )
    }
}

class ExceptionsRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inEditMode: false,
            focusFirst: false,
            approver: this.props.data.automaticApproval ? {
                valid: props.data.users[0]?.valid,
                label: props.data.users[0]?.label,
                value: props.data.users[0]?.value
            } : props.data.users
        };

        this.cancel = this.cancel.bind(this);
        this.delete = this.delete.bind(this);

        this.enterEditMode = this.enterEditMode.bind(this);
        this.exitEditMode = this.exitEditMode.bind(this);

        this.save = this.save.bind(this);
        this.setApprover = this.setApprover.bind(this);
        this.setRequester = this.setRequester.bind(this);

        this.toggleAutomaticApproval = this.toggleAutomaticApproval.bind(this);
    }

    cancel() {
        this.setState({
            inEditMode: false
        });

        this.props.cancelEdit();
    }

    delete() {
        this.props.deleteRow(this.props.index);
    }

    enterEditMode() {
        this.props.enterEditMode();
        this.setState({
            inEditMode: true
        }, () => {
            //wait until the edit mode is enabled to force the focus on the first UserSearch input
            this.setState({focusFirst: true});
        });
    }

    exitEditMode() {
        this.props.exitEditMode();
        this.setState({inEditMode: false});
    }

    save() {
        let newRow = {
            approver: this.props.approver,
            requester: this.props.requester,
            automaticApproval: this.props.data.automaticApproval
        };

        this.exitEditMode();
        this.props.save(newRow, this.props.index, this.props.data.id);
    }


    setApprover(approver) {
        let row = _.cloneDeep(this.props.data);
        row.users = this.props.data.automaticApproval ? [approver] : approver;

        this.setState({
            approver: approver
        });

        this.props.changeRow(this.props.index, row);
    }

    setRequester(requester) {
        let row = _.cloneDeep(this.props.data);
        row.valid = requester.valid;
        row.value = requester.value;
        row.label = requester.label;

        this.props.changeRow(this.props.index, row);
    }

    toggleAutomaticApproval() {
        let isChecked = this.refs.automaticApproval.checked;
        let values = _.cloneDeep(this.props.data);
        values.automaticApproval = isChecked;

        let currentUsers = _.cloneDeep(this.state.approver);

        if (isChecked) {
            if (currentUsers.length > 0) {
                currentUsers = {valid: currentUsers[0].valid, label: currentUsers[0].label, value: currentUsers[0].value};
            }
            values.users = [currentUsers.value];
        }
        else {
            currentUsers = [{valid: currentUsers.valid, label: currentUsers.label, value: currentUsers.value}];
            values.users = currentUsers.map(user => {
                return user.value
            });

        }
        this.setState({
            approver: currentUsers
        });
        values.users = currentUsers;
        this.props.changeRow(this.props.index, values);
    }

    render() {
        let requester = {
            valid: this.props.data.valid,
            label: this.props.data.label,
            value: this.props.data.value
        };

        let role = this.props.data.role ? {
            label: this.props.data.role.label,
            value: this.props.data.role.value
        } : null;

        if (this.state.inEditMode && this.props.allowActions) {
            return (
                <div className="row editing align-items-center py-2">
                    <div className={this.props.showRole ? "col-md-2" : "col-md-4"}>
                        <FindEmployees propagateValue={this.setRequester}
                                       disabled={true}
                                       refresh={this.state.focusFirst}
                                       value={requester}/>

                    </div>

                    {this.props.showRole &&
                    <div className="col-md-2">
                        <ExceptionsRoles propagateValue={this.setRole}
                                         disabled={true}
                                         value={role}/>
                    </div>
                    }

                    <div className={this.props.showAuto ? "col-md-5" : "col-md-6"}>
                        <UserSearch propagateValue={this.setApprover}
                                    multi={!this.props.data.automaticApproval}
                                    value={this.state.approver}
                                    translate={this.props.translate}/>
                    </div>
                    {this.props.showAuto &&
                    <div className="col-md-1 text-center px-0">
                        <div className="checkbox">
                            <input
                                id={`automatic-approval-checkbox-${this.props.index}`}
                                type="checkbox"
                               ref="automaticApproval"
                               onClick={this.toggleAutomaticApproval}
                               checked={this.props.data.automaticApproval}
                            />
                            <label htmlFor={`automatic-approval-checkbox-${this.props.index}`} />
                        </div>
                    </div>
                    }
                    <div className="col-md-2 d-flex align-items-center justify-content-end">
                        <SaveCancelButtons
                            saveCallback={this.save}
                            cancelCallback={this.exitEditMode}
                            translate={this.props.translate}
                        />
                    </div>
                </div>
            )
        }
        else {

            let approversList = this.state.approver.length > 0 ? this.state.approver.map((app, index) => {
                return (<span key={index}>
                    <span className={app.valid === false ? "text-danger text-bold" : ""}>{app.label}</span>
                    {(index !== this.state.approver.length - 1) && <span>, </span>}
                </span>);
            }) : (this.props.data.automaticApproval ? <span>{this.state.approver.label}</span> : []);

            return (
                <div className="row py-2 align-items-center m-0">
                    <div className={(this.props.showRole ? "col-md-2" : "col-md-4") + (requester.valid === false ? " text-danger text-bold" : "")}>
                        {requester &&
                        <span>{requester.label}</span>
                        }
                    </div>

                    {this.props.showRole &&
                    <div className="col-md-2">
                        {role &&
                        <span>{role.label}</span>
                        }
                    </div>
                    }

                    <div className={this.props.showAuto ? "col-md-5" : "col-md-6"}>
                        {approversList}
                    </div>
                    {this.props.showAuto &&
                    <div className="col-md-1 text-center px-0">
                        <div className="checkbox">
                            <input type="checkbox" checked={this.props.data.automaticApproval} disabled />
                            <label />
                        </div>
                    </div>
                    }
                    <div className="col-md-2 d-flex align-items-center justify-content-end">
                        <EditDeleteButtons
                            disabled={this.props.disabled}
                            editCallback={this.enterEditMode}
                            deleteCallback={this.delete}
                            translate={this.props.translate}
                        />
                    </div>
                </div>
            )
        }
    }
}
