import React, {Component, Fragment} from 'react';
import translate from "../../../translations/translations.wrapper.jsx";
import "./workflowdetails.scss";
import WorkflowDetailsWizard from "./WorkflowDetailsWizard.component"
import WorkflowDetailsApproversRow from "./WorkflowDetailsApproversRow.component";
import arrowDown from "../../../../assets/Icon-horizontal arrows.svg";
import WorkflowHistoryTable from "./WorkflowHistoryTable.component";
import {connect} from "react-redux";
import Skeleton from 'react-loading-skeleton';
import {eventBus} from "../../../pagelayout/EventBus";
import {getWorkflowDetails} from "../../taskDetails.action";
import * as reduxSelectors from "../../../store/application.reducers";


class WorkflowDetailsPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            approversListVisible: true,
            currentWorkflowStepVisible: false,
            workflowHistory: false,
            hideSkippedSteps: window.localStorage.getItem("hideSkippedSteps"),
            loading: false
        }

        this.toggleApproversList = this.toggleApproversList.bind(this);
        this.toggleCurrentWorkflowStep = this.toggleCurrentWorkflowStep.bind(this);
        this.toggleWorkflowHistory = this.toggleWorkflowHistory.bind(this);
        this.toggleSkippedSteps = this.toggleSkippedSteps.bind(this);
        this.loadWorkflowDetails = this.loadWorkflowDetails.bind(this);
    }

    loadWorkflowDetails() {
        this.setState({
            loading: true
        })
        this.props.getWorkflowDetails(this.props.taskId, this.props.isDocument).then(() => {
            this.setState({
                loading: false
            });
        })
    }

    componentDidMount() {
        this.loadWorkflowDetails();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.taskId !== prevProps.taskId || (this.props.taskId === prevProps.taskId && prevProps.taskDetails?.documentVersion !== this.props.taskDetails?.documentVersion)) {
            this.setState({
                loading: true
            }, () => {
                this.loadWorkflowDetails();
            });
        }
    }

    toggleApproversList() {
        this.setState({
            approversListVisible: !this.state.approversListVisible
        }, () => eventBus.dispatch('@@grid/refreshGridLayout'));
    }

    toggleCurrentWorkflowStep() {
        this.setState({
            currentWorkflowStepVisible: !this.state.currentWorkflowStepVisible
        }, () => eventBus.dispatch('@@grid/refreshGridLayout'));
    }

    toggleWorkflowHistory() {
        this.setState({
            workflowHistory: !this.state.workflowHistory
        }, () => eventBus.dispatch('@@grid/refreshGridLayout'));
    }

    toggleSkippedSteps() {
        if (this.state.hideSkippedSteps) {
            window.localStorage.removeItem("hideSkippedSteps");
            this.setState({
                hideSkippedSteps: false
            })
        } else {
            window.localStorage.setItem("hideSkippedSteps", "true");
            this.setState({
                hideSkippedSteps: true
            })
        }
    }

    renderStepsLoading() {
        return <div className="grid-workflow-details-panel-loading">
            <Skeleton className="steps-loading-skeleton"/>
            <Skeleton className="description-loading-skeleton"/>
            <Skeleton className="time-loading-skeleton"/>
        </div>
    }

    renderAssignmentsLoading() {
        return <div className="grid-workflow-details-panel-loading">
            {this.state.approversListVisible && <Skeleton className="assignments-loading-skeleton"/>}
        </div>
    }

    renderHistoryLoading() {
        return <div className="grid-workflow-details-panel-loading">
            {this.state.workflowHistory && <Skeleton className="history-loading-skeleton"/>}
        </div>
    }

    render() {
        const activeStep = this.props.data?.process?.steps.find((step) => {
            return step.status === 'ACTIVE' || step.status === 'CURRENT'
        });

        const hasSkippedSteps = this.props.data?.process?.steps.some((step) => {
            return step.status === 'SKIPPED'
        });

        const incompleteAssignments = activeStep ? activeStep.incompleteAssignments.map((assignment) => {
            return <WorkflowDetailsApproversRow assignment={assignment} key={assignment.userDTO.idUser}
                                                completed={false}/>
        }) : null;

        const completedAssignments = activeStep ? activeStep.completedAssignments.map((assignment) => {
            return <WorkflowDetailsApproversRow assignment={assignment} key={assignment.userDTO.idUser}
                                                completed={true}/>
        }) : null;

        return this.props.data ? (
            <div className={`grid-workflow-details-panel ${this.props.className || ''}`}>
                <div className="grid-panel-header workflow-details-panel-header">
                    <strong>{this.props.translate("taskDetail.workflowDetails")}</strong>
                    <div className="workflow-header-info">
                        {hasSkippedSteps ?
                            <div className="active-slider me-3">
                                <label className="switch switch-label mb-0">
                                    <input type="checkbox"
                                           checked={this.state.hideSkippedSteps}
                                           onChange={this.toggleSkippedSteps}
                                    />
                                    <span className={"togglemark " + (this.state.hideSkippedSteps ? "" : " disabled")}/>
                                </label>
                                {this.props.translate("taskDetail.hideSkippedSteps")}
                            </div> : null}
                        {this.props.data?.process?.twoPersonsRuleStatus?.planned ?
                            <div className="margin-left-8"
                                 title={this.props.translate("workflowDetails.4EyesApproval.checkboxInfo")}>
                                <span className="vismaicon vismaicon-info"/>
                                {this.props.translate("taskDetail.4-eyesActive")}
                            </div> : null}
                    </div>
                </div>
                <div className="workflow-details-panel-body">
                    {this.state.loading ?
                        this.renderStepsLoading()
                        : (
                            typeof this.props.data === 'object' && this.props.data.process && this.props.data.process.steps ?
                                <WorkflowDetailsWizard data={this.props.data}
                                                       hideSkippedSteps={this.state.hideSkippedSteps}
                                />
                                : null
                        )
                    }
                    <div className="current-workflow-step">
                        {activeStep ?
                            <div>
                                <div className="current-workflow-step-title cursor-pointer"
                                     onClick={this.toggleCurrentWorkflowStep}>
                                    {this.props.translate("taskDetail.currentWorkflowStep")}
                                    {this.state.loading ? null : <div>
                                        {' -'}
                                        <span>
                                            {' '}{activeStep.name}
                                        </span>
                                    </div>}
                                    <img
                                        className={`arrow-icon ${this.state.currentWorkflowStepVisible ? '' : 'right'}`}
                                        src={arrowDown} alt="arrow down"
                                    />
                                </div>
                                {this.state.currentWorkflowStepVisible ?
                                    <div>
                                        {incompleteAssignments.length > 0 ?
                                            <div className="row current-workflow-table-header">
                                                <div className="col-md-3">
                                                    {this.props.translate("taskDetail.currentApprovers")}
                                                </div>
                                                <div className="col-md-9">
                                                    {this.props.translate("taskDetail.reason")}
                                                </div>
                                            </div> : null}
                                        <div>
                                            {this.state.loading ? this.renderAssignmentsLoading() : incompleteAssignments}
                                        </div>
                                        {completedAssignments.length > 0 ?
                                            <div className="row current-workflow-table-header">
                                                <div className="col-md-12 cursor-pointer"
                                                     onClick={this.toggleApproversList}>
                                                    {this.props.translate("taskDetail.pastApprovers")}
                                                    <img
                                                        className={`arrow-icon ${this.state.approversListVisible ? '' : 'right'}`}
                                                        src={arrowDown} alt="arrow down"/>
                                                </div>
                                            </div> : null}
                                        {this.state.approversListVisible ?
                                            <div>
                                                {completedAssignments}
                                            </div> : null}
                                    </div> : null
                                }
                            </div> : null}

                        {this.props.historyData ?
                            <div>
                                <div className="current-workflow-step-title cursor-pointer mt-4"
                                     onClick={this.toggleWorkflowHistory}>
                            <span>
                                  {this.props.translate("taskDetail.workflowHistory")}
                            </span>
                                    <img className={`arrow-icon ${this.state.workflowHistory ? '' : 'right'}`}
                                         src={arrowDown} alt="arrow down"/>
                                </div>
                                {
                                    this.state.loading ?
                                        this.renderHistoryLoading()
                                        : (this.state.workflowHistory &&
                                            <WorkflowHistoryTable data={this.props.historyData}/>)
                                }
                            </div> : null}

                    </div>
                </div>

            </div>
        ) : null;
    }
}

const withTranslations = translate(WorkflowDetailsPanel);

const mapStateToProps = function (store) {
    return {
        data: reduxSelectors.getTaskWorkflowDetails(store),
        historyData: reduxSelectors.getTaskWorkflowHistory(store)
    };
};
const connected = connect(mapStateToProps, {
    getWorkflowDetails
})(withTranslations);

export default connected;