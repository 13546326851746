import React, {Component} from 'react';
import Confirm from '../popup/ConfirmActionPopup.component';
import history from '../router/history.jsx';


//Simona TODO This should be removed after we release some of the configurations
export class ConfigurationWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showWarning: false,
            nextLocation: '',
            currentContext: props.currentContextId,
            unblockRouter: null,
            move: false
        };
        this.remain = this.remain.bind(this);
        this.leave = this.leave.bind(this);
        this.getData = this.getData.bind(this);
        this._routerWillLeave = this._routerWillLeave.bind(this);
    }

    componentDidMount() {
        this.setState({
            move: false
        });
        // if (!this.state.unblockRouter)
        //     this.setState({
        //         unblockRouter: history.block(this._routerWillLeave)
        //     });
        this.getData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.currentContextId !== prevProps.currentContextId) {
            this.getData();
            this.setState({
                currentContext: prevProps.currentContextId
            });
        }
    }

    componentWillUnmount() {
        // if (this.state.unblockRouter) {
        //     this.state.unblockRouter();
        //     this.setState({
        //         unblockRouter: null
        //     });
        // }
    }

    _routerWillLeave(nextLocation) {
        if (this.refs.old_settings) {
            if (this.state.move)
                return true;

            if (!this.refs.old_settings.contentWindow.hasUnsavedModifications || !this.refs.old_settings.contentWindow.hasUnsavedModifications()) {
                return true;
            } else {
                this.setState({
                    showWarning: true,
                    nextLocation: nextLocation
                });
                return false;
            }
        }
        else {
            //user made changes
            if (this.state.okEnabled) {
                if (this.state.move) {
                    this.setState({
                        okEnabled: false
                    });
                    return true;
                } else {
                    this.setState({
                        showWarning: true,
                        nextLocation: nextLocation,
                        move: false
                    });
                    return false;
                }
            }
        }

        this.setState({
            okEnabled: false
        });

        return true;
    }

    leave() {
        this.setState({
            move: true
        }, () => {
            if (this.refs.old_settings && this.refs.old_settings.contentWindow)
                this.refs.old_settings.contentWindow.revertChanges();
          //  history.push(this.state.nextLocation.pathname);
        });
        this.props.blocker.blocker?.proceed();
    }

    remain() {
        this.props.blocker.blocker?.reset();
        this.setState({
            showWarning: false
        });
    }

    getData() {
        // to be overriden by children
    }

    buildContent() {
        // to be overriden by children
    }

    render() {
        let content = this.buildContent();
        return (
            <div>
                {this.props.blocker?.isNavigationBlocked() &&
                    <Confirm translate={this.props.translate}
                             message={this.props.translate("settings.confirmPageLeave")}
                             handleAction={this.remain}
                             closeCallback={this.leave}/>
                }
                <main className="settings-page">
                    {content}
                </main>
            </div>
    )
    }
    }

    export default ConfigurationWrapper;

