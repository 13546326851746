import React, {Component, Fragment} from 'react';
import '../../listWithActions/list.scss';
import ExceptionsRow from "./ExceptionsRow.component";
import * as _ from "lodash";
import {handleError} from "../../../utils/errorHandle.function";
import SearchGroupInput from "../../input/SearchGroupInput.component";
import {DropdownButton, Dropdown} from "react-bootstrap";
import loadingSpinner from '../../../assets/spinner.svg';
import {AddExceptionPopup} from "./AddExceptionPopup.component";
import arrowDown from "../../../assets/arrow-down-icon.png";

export class ExceptionsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editMode: false,
            showNewRow: false,
            filters: {
                requester: '',
                approver: '',
                role: ''
            },
            sortBy: 'requester',
            sortingDirection: 'asc'
        };

        this.enterEditMode = this.enterEditMode.bind(this);
        this.exitEditMode = this.exitEditMode.bind(this);
        this.saveNewRow = this.saveNewRow.bind(this);
        this.editRow = this.editRow.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.changeRow = this.changeRow.bind(this);
        this.mapDataBeforeSave = this.mapDataBeforeSave.bind(this);
        this.onRequesterFilterChange = this.onRequesterFilterChange.bind(this);
        this.onApproverFilterChange = this.onApproverFilterChange.bind(this);
        this.onRequesterFilterClear = this.onRequesterFilterClear.bind(this);
        this.onApproverFilterClear = this.onApproverFilterClear.bind(this);
        this.onRolesFilterSelect = this.onRolesFilterSelect.bind(this);

    }

    enterEditMode() {
        this.setState({editMode: true});
    }

    exitEditMode() {
        this.setState({editMode: false});
    }

    saveNewRow(newRow) {
        this.props.hideAddExceptionPopup();

        let newExceptions = this.props.data ? _.cloneDeep(this.props.data) : [];

        let duplicateException = false;

        newExceptions.forEach((row) => {
            if(row.value === newRow.value && row.role && row.role.value === newRow.role.value)
                duplicateException = true;
        });

        if(duplicateException){
            handleError({
                errorTitle: this.props.translate("costUnitExceptions.duplicateExceptionTitle"),
                errorMessages: this.props.translate("costUnitExceptions.duplicateException")
            });

            this.props.hideAddExceptionPopup();
            return;
        }

        newExceptions.push(newRow);

        this.props.changeExceptions(newExceptions);

        this.props.hideAddExceptionPopup();

        const mappedExceptions = this.mapDataBeforeSave(newExceptions);
        const mappedNewException = mappedExceptions[mappedExceptions.length - 1];

        this.props.saveException(mappedExceptions, mappedNewException);
    }

    deleteRow(row) {
        const roleNameExceptionId = this.props.data[row].id;

        let exceptions = _.cloneDeep(this.props.data);
        exceptions.splice(row, 1);

        this.props.changeExceptions(exceptions);
        this.props.deleteException(roleNameExceptionId);

    }

    mapDataBeforeSave(data) {
        let mappedData = data.map((exception) => {
            let users = exception.users ? (Array.isArray(exception.users) ? exception.users : [exception.users]).map((user => {
                return user.value
            })) : [];
            let result = {
                user: exception.value,
                users: users
            };
            if (exception.role)
                result.role = exception.role.value;
            result.id = exception.id;

            return result;
        });

        return mappedData;
    }

    editRow(index, id) {
        let newExceptions = this.mapDataBeforeSave(this.props.data);
        const exception = newExceptions.find((e) => e.id === id);

        this.props.editException(exception, id);
    }

    changeRow(id, data) {
        let rows = _.cloneDeep(this.props.data);
        rows[id] = data;

        this.props.changeExceptions(rows);
    }

    onRequesterFilterChange(event) {
        this.setState({
            filters: {
                ...this.state.filters,
                requester: event.target.value
            }
        })
    }

    onApproverFilterChange(event) {
        this.setState({
            filters: {
                ...this.state.filters,
                approver: event.target.value
            }
        })
    }

    onRequesterFilterClear(event) {
        this.setState({
            filters: {
                ...this.state.filters,
                requester: ''
            }
        })
    }

    onApproverFilterClear(event) {
        this.setState({
            filters: {
                ...this.state.filters,
                approver: ''
            }
        })
    }

    onRolesFilterSelect(property, value) {
        this.setState({
            filters: {
                ...this.state.filters,
                role: property
            }
        })
    }


    render() {
        let rows = _.cloneDeep(this.props.data);
        let roles = '';
        if(rows) {
            // Retain row index
            rows.forEach((row, index) => row.index = index);

            // Map roles to menu items
            roles = rows.map((row) => {
                return (row.role || {}).label
            });
            roles = roles
                .filter((role, index) => {
                    return role && roles.indexOf(role) === index;
                }).map((role, index) => {
                    return (
                        <Dropdown.Item key={index}
                                  value={role}
                                  type={role}
                                  eventKey={role}>
                            {role}
                        </Dropdown.Item>
                    );
                });

            // Sort rows
            if (this.state.sortBy === 'approver') {
                rows.forEach((row) => {
                    if (row.users) {
                        row.users.sort((a, b) => a.label.localeCompare(b.label))
                    }
                });
            }

            // eslint-disable-next-line array-callback-return
            rows.sort((a,b) => {
                // Sort by requester
                if(this.state.sortBy === 'requester') {
                    if(a.valid === false) {
                        return -1;
                    }
                    if(b.valid === false) {
                        return 1;
                    }
                    if (this.state.sortingDirection === 'asc') {
                        return a.label.localeCompare(b.label)
                    } else {
                        return b.label.localeCompare(a.label)
                    }
                // Sort by role
                } else if (this.state.sortBy === 'role') {
                    if (this.state.sortingDirection === 'asc') {
                        return (a.role || {label: ''}).label.localeCompare((b.role || {label: ''}).label)
                    } else {
                        return (b.role || {label: ''}).label.localeCompare((a.role || {label: ''}).label)
                    }
                // Sort by approver
                } else if (this.state.sortBy === 'approver') {
                    if(a.users.find((user) => user.valid === false)) {
                        return -1;
                    }
                    if(b.users.find((user) => user.valid === false)) {
                        return 1;
                    }
                    if (this.state.sortingDirection === 'asc') {
                        return a.users.map((user) => user.label).join(',')
                            .localeCompare(b.users.map((user) => user.label).join(','))
                    } else {
                        return b.users.map((user) => user.label).join(',')
                            .localeCompare(a.users.map((user) => user.label).join(','))
                    }
                }
            });

            // Filter rows
            rows = rows
                .filter((row) => {
                    //Hide rows without role (role deleted)
                    return row.role && row.role.label;
                })
                .filter((row) => {
                    // Filter by requester
                    let matches = true;
                    if(this.state.filters.requester) {
                        matches = row.label.toLowerCase().includes(this.state.filters.requester.toLowerCase())
                    }
                    return matches
                })
                .filter((row) => {
                    // Filter by role
                    let matches = true;
                    if(this.state.filters.role) {
                        matches = row.role.label === this.state.filters.role;
                    }
                    return matches
                })
                .filter((row) => {
                    // Filter by approver
                    let matches = true;
                    if(this.state.filters.approver) {
                        matches = row.users.find((user) => {
                            return user.label.toLowerCase().includes(this.state.filters.approver.toLowerCase())
                        })
                    }
                    return matches
                });
        }

        let lines = (rows) ? rows.map((row, index) => {
            return <ExceptionsRow data={row}
                                  changeRow={this.changeRow}
                                  key={row.id || row.value}
                                  save={this.editRow}
                                  index={row.index}
                                  disabled={this.state.editMode || !this.props.allowActions}
                                  translate={this.props.translate}
                                  enterEditMode={this.enterEditMode}
                                  exitEditMode={this.exitEditMode}
                                  allowActions={this.props.allowActions}
                                  deleteRow={this.deleteRow}/>

        }) : [];

        return (
            <div className="exceptions-list actions-list">

                {!this.props.emptyExceptions || this.props.loading ?
                    <Fragment>
                        <ExceptionsHeaderRow
                            translate={this.props.translate}
                            sortBy={this.state.sortBy}
                            sortingDirection={this.state.sortingDirection}
                            onClickSort={(value) => {
                                this.setState({
                                    sortBy: value,
                                    sortingDirection: value === this.state.sortBy ? (this.state.sortingDirection === 'asc' ? 'desc' : 'asc') : 'asc'
                                })
                            }}/>


                        <div className="exceptions-list">
                            <div className="row exceptions-row exceptions-filters-row m-0">
                                <div className="col-md-3">
                                    <SearchGroupInput placeholder={this.props.translate("workflowDetails.exceptions.findRequester")}
                                                      value={this.state.filters.requester}
                                                      onInputChange={this.onRequesterFilterChange}
                                                      onClearInput={this.onRequesterFilterClear}
                                                      translate={this.props.translate}
                                    />
                                </div>
                                <div className="col-md-2">
                                    <DropdownButton id="roles-filter-select"
                                                    className="align-left"
                                                    bsPrefix="btn btn-default"
                                                    title={this.state.filters.role || this.props.translate("workflowDetails.exceptions.allRoles")}
                                                    onSelect={this.onRolesFilterSelect}
                                    >
                                        <Dropdown.Item key={-1}
                                                       value={''}
                                                       eventKey={''}>
                                            {this.props.translate("workflowDetails.exceptions.allRoles")}
                                        </Dropdown.Item>
                                        {roles}
                                    </DropdownButton>
                                </div>
                                <div className="col-md-6">
                                    <SearchGroupInput placeholder={this.props.translate("workflowDetails.exceptions.findApprover")}
                                                      value={this.state.filters.approver}
                                                      onInputChange={this.onApproverFilterChange}
                                                      onClearInput={this.onApproverFilterClear}
                                                      translate={this.props.translate}
                                    />
                                </div>
                            </div>
                            <div className="exceptions-list-scroller">
                                {lines}
                            </div>
                            { this.props.loading
                                ? <div className="exceptions-loading">
                                    <img src={loadingSpinner} alt="Loading.." />
                                </div>
                                : <div className="row mx-0">
                                    <div className="col-md-12">
                                        {this.props.translate("costUnitExceptions.totalOf")}
                                        <strong>{lines.length}</strong>
                                        {this.props.translate("costUnitExceptions.exceptions")}
                                    </div>
                                </div>
                            }
                        </div>
                    </Fragment> : null
                }

                { this.props.addExceptionPopupVisible ?
                    <AddExceptionPopup translate={this.props.translate}
                                       noDuplicates={this.props.noDuplicates}
                                       closeCallback={this.props.hideAddExceptionPopup}
                                       handleAction={this.saveNewRow} />
                    : null
                }
            </div>
        )
    }
}

export default ExceptionsList;

const ExceptionsHeaderRow = (props) => {
    const arrow = (<img className={`p-1 me-2 arrow-icon ${props.sortingDirection === "asc" ? 'up' : ''}`}
                        src={arrowDown}
                        alt="arrow down"/>);

    return (
        <div className="row header-row m-0">
            <div className="col-md-3" onClick={() => props.onClickSort('requester')}>
                {props.translate("workflowDetails.exceptions.requester")}
                {props.sortBy === 'requester' ? arrow : null}
            </div>
            <div className={"col-md-2"} onClick={() => props.onClickSort('role')}>
                {props.translate("workflowDetails.exceptions.role")}
                {props.sortBy === 'role' ? arrow : null}
            </div>
            <div className={"col-md-6"} onClick={() => props.onClickSort('approver')}>
                {props.translate("workflowDetails.exceptions.approvers")}
                {props.sortBy === 'approver' ? arrow : null}
            </div>
            <div className="col-md-1 text-right">
                {props.translate("roles.actions")}
            </div>
        </div>
    )
};




