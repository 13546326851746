import React, {Component} from 'react';
import _ from 'lodash';
import {LOADING_STATUS, OVERVIEW_STATUS, TASK_ACTIONS} from 'utils/constants.js';
import {
    OVERVIEW_ALL_STATUS,
    OVERVIEW_FULL,
    OVERVIEW_NO_ROLE_NO_SELECT,
    OVERVIEW_NO_SELECT
} from '../resizableTable/columnsMap';
import * as reduxSelectors from '../store/application.reducers';
import {connect} from 'react-redux';
import {
    changeActiveCounter,
    filterChanged,
    getCounters,
    getOverviewCounters,
    loadMoreOverview,
    overviewTaskSelect,
    sortingColumnChanged
} from './taskOverview.action';
import {ResizableTable} from '../resizableTable/Table.component';
import {formatDate, formatDateTime} from 'utils/valueFormatter.function';
import * as Api from 'utils/api/api.js';
import AsyncTaskActionExecutor from './AsyncTaskActionExecutor.component';
import {taskAction} from '../mytasks/action/taskAction.action';
import 'reactabular-resizable/style.css';
import './task_overview.scss';
import {userHasAccessRights} from 'utils/accessRights.function';
import {pageLoaded} from '../router/route.action';
import translate from '../translations/translations.wrapper.jsx';

export class TaskOverview extends Component {

    constructor(props) {
        super();
        this.state = {
            companyDocumentTypes: []
        };
        this.loadData = this.loadData.bind(this);
        this.mapTasks = this.mapTasks.bind(this);
        this.refreshGrid = this.refreshGrid.bind(this);
        this.loadMoreTasks = this.loadMoreTasks.bind(this);
        this.changeCounter = this.changeCounter.bind(this);
        this.selectTasks = this.selectTasks.bind(this);
        this.getColumns = this.getColumns.bind(this);
    }


    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentContextId !== this.props.currentContextId && userHasAccessRights(this.props.userData.userRoles, this.props.location)) {
            this.loadData();
        }
        if (this.props != null && this.props.loadingStatus !== prevProps.loadingStatus && this.props.loadingStatus === LOADING_STATUS.DONE) {
            this.props.pageLoaded();
        }
    }

    loadData() {
        Api.getCompanyDocumentTypes().then(function (response) {
            this.setState({
                companyDocumentTypes: response
            });
        }.bind(this));

        this.props.getCounters();
        this.props.getOverviewCounters(this.props.filters, this.props.activeCounter);
    }

    mapTasks(tasks, selectedTasks) {

        let newTaskList = _.cloneDeep(tasks);
        let taskList = newTaskList.rows;
        newTaskList.rows = taskList ? taskList.map((task, index) => {
            let foundTask = _.findIndex(selectedTasks, (x => x === task.key));
            task.isSelected = foundTask !== -1;
            task.taskActivatedDate = formatDateTime(task.taskActivatedDate);
            task.taskDueDate = formatDate(task.taskDueDate);
            task.processCreatedDate = formatDateTime(task.processCreatedDate);
            task.invoiceDate = formatDate(task.invoiceDate);
            task.dueDate = formatDate(task.dueDate);
            // reactabular needs a unique id for each row
            task.index = index;
            return task;
        }) : [];

        return newTaskList;
    }

    refreshGrid(objfilter) {
        let newFilter = Object.assign({}, this.props.filters, objfilter);
        this.props.getCounters();
        this.props.getOverviewCounters(newFilter, this.props.activeCounter);
    }

    loadMoreTasks(callObject) {
        this.props.loadMoreOverview(callObject, this.props.activeCounter);
    }

    changeCounter(index) {
        this.props.changeActiveCounter(index + 1, this.props.filters);
    }

    selectTasks(column) {
        if (this.props.tasks.rows.length > 0) {
            let selectedTasks = [];
            if (column.length > 0) {
                let tasksIds = this.props.tasks.rows.map((task) => {
                    return task.key;
                });
                selectedTasks = selectedTasks.concat(tasksIds);
            } else {
                selectedTasks = selectedTasks.concat(this.props.tasks.rows[column].key)
            }
            this.props.overviewTaskSelect(selectedTasks);
        }
    }

    getColumns() {
        switch (this.props.activeCounter) {
            case OVERVIEW_STATUS.futureTasks:
                return OVERVIEW_NO_ROLE_NO_SELECT;
            case OVERVIEW_STATUS.completedTasks:
                return OVERVIEW_NO_SELECT;
            case OVERVIEW_STATUS.allTasks:
                return OVERVIEW_ALL_STATUS;
            default:
                return OVERVIEW_FULL;
        }
    }

    render() {

        let {activeCounter, counters, tasks, selectedTasks} = this.props;
        let mappedTasks = this.mapTasks(Object.assign({}, tasks), selectedTasks);
        let columns = this.getColumns();

        let selector_values = Object.assign({}, {"role_select": this.props.userRoles}, {"documentType": this.state.companyDocumentTypes});

        let countersList = counters ? Object.keys(counters).map((counter, index) => {
            return (
                <button key={index} type="button"
                        onClick={this.changeCounter.bind(this, index)}
                        className={"btn btn-default " + ((index + 1) === activeCounter ? "active" : "")}>
                    {this.props.translate("taskOverview." + counter)}
                    {index < 2 && counters[counter] > 0 &&
                        <span className="margin-left-8 badge badge-danger"> {counters[counter]}</span>
                    }
                    {index === 2 && counters[counter] > 0 &&
                        <span className="margin-left-8 badge badge-success"> {counters[counter]}</span>
                    }
                    {index === 3 && counters[counter] > 0 &&
                        <span className="margin-left-8 badge badge-primary"> {counters[counter]}</span>
                    }
                </button>);
        }) : null;

        return (
            <div className="col-md-12 px-0">
                <AsyncTaskActionExecutor translate={this.props.translate} reloadTasks={this.refreshGrid}/>
                <div className="btn-toolbar btn-toolbar-primary mx-0">
                    <span className="filter_name pull-left float-left mr-24">
                            {this.props.translate("taskOverview.tasks")}
                        </span>

                    <div className="col-md-10 btn-group float-left px-0" role="group">
                        {countersList}
                    </div>
                </div>
                <ResizableTable tasks={mappedTasks}
                                defaultColumns={columns}
                                selectedTasks={selectedTasks}
                                activeCounter={activeCounter}
                                loadingStatus={this.props.loadingStatus}
                                selectors={selector_values}
                                sortingLogic={this.props.sortingLogic}
                                filterChanged={this.props.filterChanged}
                                sortingColumnChanged={this.props.sortingColumnChanged}
                                taskSelect={this.selectTasks}
                                translate={this.props.translate}
                                filters={this.props.filters}
                                loadMoreTasks={this.loadMoreTasks}
                                refreshGrid={this.refreshGrid}
                />

                {activeCounter < 4 && this.props.userData.userRoles.systemAdministrator &&
                    <OverviewButtons selectedTaskKeys={this.props.selectedTasks}
                                     translate={this.props.translate}
                                     action={this.props.taskAction}/>
                }
            </div>
        )
    }
}

const withTranslations = translate(TaskOverview);

const mapStateToProps = function (store) {
    return {
        counters: reduxSelectors.getOverviewCounters(store),
        activeCounter: reduxSelectors.getOverviewActiveCounter(store),
        tasks: reduxSelectors.getOverviewTasks(store),
        filters: reduxSelectors.getOverviewFilter(store),
        sortingLogic: reduxSelectors.getOverviewSortingLogic(store),
        userRoles: reduxSelectors.getOverviewUserRoles(store),
        loadingStatus: reduxSelectors.getOverviewLoadingStatus(store),
        selectedTasks: reduxSelectors.getOverviewSelectedTasks(store),
        currentContextId: reduxSelectors.getUsersCurrentContextId(store),
        userData: reduxSelectors.getUsersData(store)
    };
};
const connected = connect(mapStateToProps, {
    getOverviewCounters,
    getCounters,
    changeActiveCounter,
    filterChanged,
    loadMoreOverview,
    sortingColumnChanged,
    overviewTaskSelect,
    taskAction,
    pageLoaded
})(withTranslations);

export default connected;

const OverviewButtons = ({selectedTaskKeys, action, translate, backToMyTasks}) => {

    let reassignClass = "btn btn-primary float-right px-4" + (selectedTaskKeys.length > 0 ? "" : " disabled");

    return (
        <div className="fixed-bottom margin-bottom-12 px-3 mb-3">
            <div className="col-md-3 float-right">
                <button type="btn" className={reassignClass}
                        onClick={() => {
                            action(TASK_ACTIONS.REASSIGN, selectedTaskKeys);
                        }}>
                    {translate("taskOverview.reassign")}
                </button>
            </div>
        </div>
    )
};
