import React, {Component} from 'react';
import * as _ from 'lodash';
import {Collapse} from 'react-bootstrap';
import {
    PROCESS_TYPE,
    PROCESS_DETAILS_URL_PART,
    WORKFLOW_PROCESS_STATUS,
    TABLE_COLUMNS_TYPES,
    HISTORY_FILTERS,
    LOADING_STATUS
} from "utils/constants";
import {
    COLUMN_MAP_ACTIVE,
    PROCESS_OVERVIEW_COMPLETED
} from '../resizableTable/columnsMap';
import * as reduxSelectors from '../store/application.reducers';
import {connect} from 'react-redux';
import {
    getOverviewCounters,
    processTypeChanged,
    loadMoreOverview,
    filterChanged,
    getCostUnits,
    toggleAdvancedSearch,
    preventProcessReload,
    saveProcessScrollPosition
} from './processOverview.action';
import {HistoryAdvancedSearch} from '../myhistory/HistoryAdvancedSearch.component';
import {ResizableTable} from '../resizableTable/Table.component';
import {formatDate, formatDateTime} from "utils/valueFormatter.function";
import * as Api from '../../utils/api/api.js';
import 'reactabular-resizable/style.css';
import '../myhistory/my_history.scss';
import {userHasAccessRights} from "utils/accessRights.function";
import history from '../router/history.jsx';
import {pageLoaded} from '../router/route.action';
import translate from '../translations/translations.wrapper.jsx';
import {snowplowTrackEvent} from "utils/snowplow";
import {launchDarkly} from "utils/launchDarkly";
import AdvancedSearchFiltering from "components/myhistory/AdvancedSearchFiltering.component";
import Confirm from "components/popup/ConfirmActionPopup.component";

export class ProcessOverview extends Component {

    constructor(props) {
        super();
        this.state = {
            companyDocumentTypes: [],
            showAdvancedSearch: false,
            showClearAdvancedSearchPopup: false
        };
        this.useAdvancedSearchStyleImprovements = launchDarkly.userClient && launchDarkly.userClient.variation("fe-advanced-search-style-improvements", false);
        this.loadData = this.loadData.bind(this);
        this.refreshGrid = this.refreshGrid.bind(this);
        this.loadMoreTasks = this.loadMoreTasks.bind(this);
        this.changeCounter = this.changeCounter.bind(this);
        this.filterProcess = this.filterProcess.bind(this);
        this.onSelectRow = this.onSelectRow.bind(this);
        this.onRow = this.onRow.bind(this);
        this.getTaskWithTranslation = this.getTaskWithTranslation.bind(this);
        this.onAccountSearch = this.onAccountSearch.bind(this);
        this.onCostUnitSearch = this.onCostUnitSearch.bind(this);
        this.getFilterValue = this.getFilterValue.bind(this);
        this.callServer = this.callServer.bind(this);
        this.onAdvancedSearchFiltersClear = this.onAdvancedSearchFiltersClear.bind(this);
        this.advancedSearchFiltersActive = this.advancedSearchFiltersActive.bind(this);
        this.noOfAdvancedSearchFiltersActive = this.noOfAdvancedSearchFiltersActive.bind(this);
        this.confirmClearAdvancedSearch = this.confirmClearAdvancedSearch.bind(this);
        this.showClearAdvancedSearchPopup = this.showClearAdvancedSearchPopup.bind(this);
        this.hideClearAdvancedSearchPopup = this.hideClearAdvancedSearchPopup.bind(this);
    }


    componentDidMount() {
        if (this.props.shouldPreventReload) {
            this.props.preventProcessReload(false);
            this.loadData(this.props.processType, this.props.filters, false)
            return;
        }
        this.loadData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentContextId !== this.props.currentContextId && userHasAccessRights(this.props.userData.userRoles, this.props.location)) {
            this.loadData();
        }
        if (this.props != null && this.props.loadingStatus !== prevProps.loadingStatus && this.props.loadingStatus === LOADING_STATUS.DONE) {
            this.props.pageLoaded();
        }
    }

    loadData(process = this.props.processType, filters = this.props.filters, updateStore = true) {
        const loadTimeStart = +new Date();
        Api.showHistorySearch().then(function (response) {
            this.setState({
                showAdvancedSearch: response
            });

            if (updateStore && response) {
                this.props.getCostUnits(filters);
            }
        }.bind(this));

        Api.getCompanyDocumentTypes().then(function (response) {
            this.setState({
                companyDocumentTypes: response
            });
        }.bind(this));

        Api.getProcessOverviewStates().then(function (response) {
            this.setState({
                processStates: response
            });
        }.bind(this));

        if (updateStore) {
            this.props.getOverviewCounters(filters, process).then((response) => {
                const rowsNumber = response.data.tasks.records;
                const loadTime = (+new Date()) - loadTimeStart;
                snowplowTrackEvent({
                    category: "process_overview",
                    action: "get_process_overview_completed",
                    label: "execution_time",
                    value: loadTime
                }, this.props.userConfiguration);

                snowplowTrackEvent({
                    category: "process_overview",
                    action: "get_process_overview_completed",
                    label: "rows_number",
                    value: rowsNumber
                }, this.props.userConfiguration);
            });
        }
    }

    refreshGrid(objfilter) {
        let newFilter = Object.assign({}, this.props.filters, objfilter);
        this.props.getOverviewCounters(newFilter, this.props.processType);
    }

    loadMoreTasks(callObject) {
        this.props.loadMoreOverview(callObject, this.props.processType);
    }

    changeCounter(index) {
        this.props.changeActiveCounter(index + 1, this.props.filters);
    }

    onSelectRow(key) {
        history.push(PROCESS_DETAILS_URL_PART + key);
    }


    onRow(task) {
        return {
            onClick: () => this.onSelectRow(task.key)
        }
    }

    filterProcess(type) {
        if (type !== this.props.processType) {
            let newType = this.props.processType === PROCESS_TYPE.ACTIVE ? PROCESS_TYPE.COMPLETED : PROCESS_TYPE.ACTIVE;
            this.props.processTypeChanged(newType);
            let filters = _.cloneDeep(this.props.filters);
            if (filters.columns.status) {
                delete filters.columns.status;
            }
            this.loadData(newType, filters);
        }
    }

    getTaskWithTranslation(tasks) {
        if (tasks) {
            let newTaskList = _.cloneDeep(tasks);
            let taskList = newTaskList.allTasks;
            let value = taskList ? taskList.map((task, index) => {
                task.displayId = task.displayId ? task.displayId : task.externalApplicationId;
                task.lastChanged = task.lastChanged ? formatDateTime(task.lastChanged) : '-';
                task.taskDueDate = formatDate(task.taskDueDate);
                task.received = formatDateTime(task.received);
                task.status = this.props.translate("workflowProcessStatus." + WORKFLOW_PROCESS_STATUS.asString(task.status));
                task.acknowledged = this.props.translate("boolean." + task.acknowledged);
                // reactabular needs a unique id for each row
                task.index = index;
                return task;
            }) : [];
            newTaskList.rows = value;
            delete newTaskList.allTasks;

            return newTaskList;
        }
        return [];
    }

    getFilterValue(searchValue, existingFilter) {
        let newFilter = Object.assign({}, existingFilter);
        if (searchValue) {
            let key = Object.keys(searchValue)[0];
            newFilter[key] = (searchValue[key]);
        }
        return newFilter;
    }

    onAccountSearch(query) {
        let accountObject = {};
        accountObject[HISTORY_FILTERS.ACCOUNT] = query ? {
            number: query.value,
            label: query.label
        } : undefined;

        let newFilter = this.getFilterValue(accountObject, this.props.filters);
        this.props.filterChanged(newFilter);
        this.callServer(newFilter);
    }

    onCostUnitSearch(value, selectedValue) {
        let unitsObject = {};
        let currentCostUnit = Object.assign([], this.props.filters.costUnits);
        let foundQuery = false;

        let query = {
            dimension: value,
            number: selectedValue ? selectedValue.value : undefined,
            label: selectedValue ? selectedValue.label : undefined
        };

        //search if the filter has a previous value
        currentCostUnit.forEach((unit) => {
            if (unit.dimension === query.dimension && typeof query.number !== "undefined") {
                unit.number = query.number;
                unit.label = query.label;

                foundQuery = true;
            } else if (typeof query.number === "undefined") {
                //filter out the removed Filter value
                currentCostUnit = currentCostUnit.filter(elem => {
                    return elem.dimension !== query.dimension
                });
                foundQuery = true;
            }
        });
        //this is a new filter
        if (!foundQuery) {
            currentCostUnit.push(query);
        }
        let newCostUnits = Object.assign([], currentCostUnit);
        unitsObject[HISTORY_FILTERS.COST_UNITS] = newCostUnits;
        let newFilter = this.getFilterValue(unitsObject, this.props.filters);
        this.props.filterChanged(newFilter);
        this.callServer(newFilter);
    }

    callServer(filter) {
        this.props.getOverviewCounters(filter, this.props.processType);
    }

    noOfAdvancedSearchFiltersActive() {
        const filters = this.props.filters;
        let activeFilters = filters && filters.account ? 1 : 0;
        activeFilters += filters && filters.costUnits ? filters.costUnits.length : 0;
        return activeFilters;
    }

    advancedSearchFiltersActive() {
        const filters = this.props.filters;
        return filters && (filters.costUnits.length > 0 || filters.account);
    }

    onAdvancedSearchFiltersClear() {
        const newFilter = _.cloneDeep(this.props.filters);
        newFilter.costUnits = [];
        newFilter.account = undefined;
        this.props.filterChanged(newFilter);
        this.callServer(newFilter);
    }

    confirmClearAdvancedSearch() {
        this.onAdvancedSearchFiltersClear();
        this.hideClearAdvancedSearchPopup();
    }

    showClearAdvancedSearchPopup() {
        this.setState({showClearAdvancedSearchPopup: true});
    }

    hideClearAdvancedSearchPopup() {
        this.setState({showClearAdvancedSearchPopup: false});
    }


    render() {
        let {processType, translate} = this.props;
        let selector_values = Object.assign({}, {"role_select": this.props.userRoles}, {"documentType": this.state.companyDocumentTypes});
        let mappedColumns = (processType === PROCESS_TYPE.COMPLETED) ? PROCESS_OVERVIEW_COMPLETED : COLUMN_MAP_ACTIVE;
        selector_values[TABLE_COLUMNS_TYPES.STATUS] = this.state.processStates;

        let mappedTasks = Object.assign({}, this.props.tasks);
        mappedTasks = this.getTaskWithTranslation(mappedTasks);

        //map cost units to current filters
        let costValue = this.props.costUnitsOptions ? this.props.costUnitsOptions.map(unit => {
            unit.selectedValue = undefined;
            this.props.filters.costUnits.forEach(value => {
                if ((value.dimension === unit.dimension)) {
                    unit.selectedValue = value.number;
                    unit.label = value.label;
                }
            });
            return unit;
        }) : [];

        //map acount value
        let account = this.props.filters && this.props.filters.account ? {
            selectedValue: this.props.filters.account.number,
            label: this.props.filters.account.label
        } : {};

        return (
            this.useAdvancedSearchStyleImprovements ?
                <div className="col-md-12 px-0 process-overview">
                    {this.state.showClearAdvancedSearchPopup &&
                        <Confirm translate={this.props.translate}
                                 title={this.props.translate("popUp.clearAdvancedSearch.title")}
                                 message={this.props.translate("popUp.clearAdvancedSearch.message")}
                                 confirmButtonText={this.props.translate("popUp.clear")}
                                 confirmButtonColor={'btn-default'}
                                 handleAction={this.confirmClearAdvancedSearch}
                                 closeCallback={this.hideClearAdvancedSearchPopup}
                        />}
                    <div className="btn-toolbar btn-toolbar-primary btn-toolbar-advanced my-0">
                        <div className="btn-group" role="group">
                            <button type="button"
                                    onClick={this.filterProcess.bind(this, PROCESS_TYPE.ACTIVE)}
                                    className={"btn btn-default " + (processType === PROCESS_TYPE.ACTIVE ? "active" : "")}>
                                {translate("myHistory.activeProcesses")}
                            </button>

                            <button type="button"
                                    onClick={this.filterProcess.bind(this, PROCESS_TYPE.COMPLETED)}
                                    className={"btn btn-default " + (processType === PROCESS_TYPE.COMPLETED ? "active" : "")}>
                                {translate("myHistory.completedProcesses")}
                            </button>
                        </div>
                        {this.state.showAdvancedSearch &&
                            <div role="presentation" className="advanced-search"
                                 onClick={this.props.toggleAdvancedSearch.bind(null, !this.props.advancedSearchOpen)}>
                                <span className="search-title"> {translate("myHistory.advancedSearch")}</span>
                                {this.noOfAdvancedSearchFiltersActive() > 0 &&
                                    <span
                                        className="margin-left-8 label label-info">{this.noOfAdvancedSearchFiltersActive()}</span>
                                }
                                <span
                                    className={"margin-left-8 appicon sort-arrow sort-arrow-" + (this.props.advancedSearchOpen ? "down" : "right")}/>
                            </div>
                        }
                    </div>

                    {this.state.showAdvancedSearch && this.props.advancedSearchOpen &&
                        <Collapse in={this.props.advancedSearchOpen}>
                            <AdvancedSearchFiltering costUnits={costValue}
                                                     account={account}
                                                     isClearActive={this.advancedSearchFiltersActive()}
                                                     onSearch={this.onCostUnitSearch}
                                                     onAccountSearch={this.onAccountSearch}
                                                     onClearFilters={this.showClearAdvancedSearchPopup}/>
                        </Collapse>
                    }
                    <ResizableTable tasks={mappedTasks}
                                    className={this.state.showAdvancedSearch && this.props.advancedSearchOpen ? "hasAdvancedSearchStyleImprovements rows_" + Math.ceil((costValue.length + 1) / 4) : ""}
                                    defaultColumns={mappedColumns}
                                    filterChanged={this.props.filterChanged}
                                    selectors={selector_values}
                                    translate={this.props.translate}
                                    filters={this.props.filters}
                                    refreshGrid={this.refreshGrid}
                                    loadMoreTasks={this.loadMoreTasks}
                                    loadingStatus={this.props.loadingStatus}
                                    onRow={this.onRow.bind(this)}
                                    saveScrollPosition={this.props.saveProcessScrollPosition}
                                    scrollTop={this.props.scrollTop}
                    />
                </div> :
                <div className="col-md-12 px-0 process-overview">
                    <div className="btn-toolbar btn-toolbar-primary btn-toolbar-advanced my-0">
                        <div>
                            <div className="btn-group" role="group">
                                <button type="button"
                                        onClick={this.filterProcess.bind(this, PROCESS_TYPE.ACTIVE)}
                                        className={"btn btn-default " + (processType === PROCESS_TYPE.ACTIVE ? "active" : "")}>
                                    {translate("myHistory.activeProcesses")}
                                </button>

                                <button type="button"
                                        onClick={this.filterProcess.bind(this, PROCESS_TYPE.COMPLETED)}
                                        className={"btn btn-default " + (processType === PROCESS_TYPE.COMPLETED ? "active" : "")}>
                                    {translate("myHistory.completedProcesses")}
                                </button>
                            </div>
                            {this.state.showAdvancedSearch &&
                                <div role="presentation" className="advanced-search"
                                     onClick={this.props.toggleAdvancedSearch.bind(null, !this.props.advancedSearchOpen)}>
                                        <span className="search-title">
                                            {translate("myHistory.advancedSearch")}
                                        </span>
                                    <span
                                        className={"margin-left-8 appicon sort-arrow sort-arrow-" + (this.props.advancedSearchOpen ? "down" : "right")}/>
                                </div>
                            }
                        </div>
                        {this.state.showAdvancedSearch && this.advancedSearchFiltersActive() &&
                            <div className="advanced-search-clear" onClick={this.onAdvancedSearchFiltersClear}>
                                <span className="sort-arrow clear-icon"/>
                                <span className="clear-title">{translate("myHistory.clearAllFilters")}</span>
                            </div>
                        }
                    </div>

                    {this.state.showAdvancedSearch && this.props.advancedSearchOpen &&
                        <Collapse in={this.props.advancedSearchOpen}>
                            <div className="panel-body advanced-search-container px-0 transparent-background">
                                <HistoryAdvancedSearch costUnits={costValue}
                                                       account={account}
                                                       onSearch={this.onCostUnitSearch}
                                                       onAccountSearch={this.onAccountSearch}
                                                       translate={translate}/>
                            </div>
                        </Collapse>
                    }

                    <ResizableTable tasks={mappedTasks}
                                    className={this.state.showAdvancedSearch && this.props.advancedSearchOpen ? "hasAdvanced rows_" + Math.ceil((costValue.length + 1) / 4) : ""}
                                    defaultColumns={mappedColumns}
                                    filterChanged={this.props.filterChanged}
                                    selectors={selector_values}
                                    translate={this.props.translate}
                                    filters={this.props.filters}
                                    refreshGrid={this.refreshGrid}
                                    loadMoreTasks={this.loadMoreTasks}
                                    loadingStatus={this.props.loadingStatus}
                                    onRow={this.onRow.bind(this)}
                                    saveScrollPosition={this.props.saveProcessScrollPosition}
                                    scrollTop={this.props.scrollTop}
                    />
                </div>
        )
    }
}

const withTranslations = translate(ProcessOverview);

const mapStateToProps = function (store) {
    return {
        counters: reduxSelectors.getProcessOverviewCounters(store),
        activeCounter: reduxSelectors.getOverviewActiveCounter(store),
        advancedSearchOpen: reduxSelectors.getProcessToggleAdvancedSearch(store),
        processType: reduxSelectors.getProcessOverviewProcessType(store),
        tasks: reduxSelectors.getProcessOverviewTasks(store),
        filters: reduxSelectors.getProcessOverviewFilter(store),
        userRoles: reduxSelectors.getOverviewUserRoles(store),
        loadingStatus: reduxSelectors.getProcessOverviewLoadingStatus(store),
        currentContextId: reduxSelectors.getUsersCurrentContextId(store),
        costUnitsOptions: reduxSelectors.getProcessCostUnitsOptions(store),
        userData: reduxSelectors.getUsersData(store),
        shouldPreventReload: reduxSelectors.shouldPreventProcessReload(store),
        scrollTop: reduxSelectors.getProcessScrollTop(store),
        userConfiguration: reduxSelectors.getUsersData(store)
    };
};
const connected = connect(mapStateToProps, {
    getOverviewCounters,
    processTypeChanged,
    loadMoreOverview,
    filterChanged,
    getCostUnits,
    toggleAdvancedSearch,
    preventProcessReload,
    saveProcessScrollPosition,
    pageLoaded
})(withTranslations);

export default connected;

