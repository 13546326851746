//@ts-nocheck
import "./style/taskdetail.scss";
import "./style/taskDetailsTemplates.scss";
import React from "react";
import "./grid/grid.scss";
import TaskDetailsSubnavbar from "./navigation/Subnavbar.component";
import {TaskDetailsSidebar} from "./navigation/Sidebar.component";
import AsyncTaskActionExecutorTask from "./AsyncTaskActionExecutor.component";
import Confirm from "../popup/ConfirmActionPopup.component";
import HandledTask from "../popup/HandledTaskWarning.component";
import {
    CONFIRMATION, DETAILS_VIEW_TYPE, DOCUMENT_OVERVIEW_STATUS, LOADING_STATUS,
    WORKFLOW_DETAILS, DOCUMENT_DETAILS, DOCUMENT_PREVIEW, COMMENTS, EDITOR, ACCOUNTING_GRID,
    PANELS_BY_DOCTYPE_AND_INTEGRATION, DOCUMENT_TYPE, TASK_ACTIONS, DETAILS_STATUS,
} from "utils/constants";
import ActionlessComments from "./panels/newcomments/ActionlessComments.component.tsx";
import ActionComments from "./panels/newcomments/ActionComments.component.tsx";
import CommentsPanel from "./panels/comments/CommentsPanel.component";
import DocumentData from "./panels/DocumentData.component";
import WorkflowDetailsPanel from "./panels/workflowdetails/WorkflowDetailsPanel.component";
import AttachmentsPanel from "./panels/attachments/AttachmentsPanel.component";
import {connect} from "react-redux";
import {approveMultipleShortcutKey, loadTasks} from "../mytasks/myTasks.action";
import {pageDataLoaded, pageLoaded} from "../router/route.action";
import {changeTryToLoopTasksFlag} from "../mytasks/action/taskAction.action";
import translate from "../translations/translations.wrapper.jsx";
import {canDo, keyPressHandler} from "utils/keyPressHandler.function";
import * as reduxSelectors from "../store/application.reducers";
import DocumentEditorPanel from "./panels/editor/DocumentEditorPanel.component";
import AccountingGridPanel from "./panels/editor/AccountingGridPanel.component";
import MouseDown from "./icons/mouse-down.svg";
import {eventBus} from "../pagelayout/EventBus";
import {snowplowTrackEvent} from "utils/snowplow";
import "./style/task_details.scss";
import {ResizableBox} from "react-resizable";
import "react-resizable/css/styles.css";
import {SaveDiscardMessage} from "utils/confirmMoreMessages";
import {
    changeUnsavedComment,
    taskCommentStartSaving,
} from "components/taskdetails-remake/taskDetails.action.js";
import { launchDarkly } from "utils/launchDarkly";


class TaskDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            renderEditorInPortal: window.localStorage.getItem("@@grid/editor/portal") && window.localStorage.getItem("@@grid/editor/portal") !== "false",
            handledWarningEnabled: true,
            resizeHandlerHeight: "100%",
            itemsVisible: {
                [WORKFLOW_DETAILS]: window.localStorage.getItem(`@@grid/workflow-details/visible`) !== "false",
                [DOCUMENT_DETAILS]: window.localStorage.getItem(`@@grid/document-details/visible`) !== "false",
                [DOCUMENT_PREVIEW]: window.localStorage.getItem(`@@grid/document-preview/visible`) !== "false",
                [COMMENTS]: window.localStorage.getItem(`@@grid/comments/visible`) !== "false",
                [EDITOR]: window.localStorage.getItem(`@@grid/editor/visible`) !== "false",
                [ACCOUNTING_GRID]: window.localStorage.getItem(`@@grid/accounting-grid/visible`) !== "false",
            },
        };

        this.timeOnPage = 0;
        this.resizeInstances = 0;
        this.logTimeOnPage = this.logTimeOnPage.bind(this);

        this.remain = this.remain.bind(this);
        this.togglePanelVisibility = this.togglePanelVisibility.bind(this);
        this.isEditorReadOnly = this.isEditorReadOnly.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.getPanelsVisibleForIntegration = this.getPanelsVisibleForIntegration.bind(this);
        this.computeLayoutClass = this.computeLayoutClass.bind(this);
        this.onRenderInPortalChange = this.onRenderInPortalChange.bind(this);
        this.saveEditorChanges = this.saveEditorChanges.bind(this);
        this.discardEditorChanges = this.discardEditorChanges.bind(this);
        this.sendMessageToEditor = this.sendMessageToEditor.bind(this);


        this.onResize = this.onResize.bind(this);
        this.onResizeStart = this.onResizeStart.bind(this);
        this.onResizeStop = this.onResizeStop.bind(this);
        this.handleWindowResize = this.handleWindowResize.bind(this);
        this.setRootStyle = this.setRootStyle.bind(this);
        this.maximizeAttachmentsPanel = this.maximizeAttachmentsPanel.bind(this);
        this.minimizeAttachmentsPanel = this.minimizeAttachmentsPanel.bind(this);
        this.isAttachmentPanelWidthDefault = this.isAttachmentPanelWidthDefault.bind(this);
        this.isAttachmentPanelMaxWidth = this.isAttachmentPanelMaxWidth.bind(this);
        this.pushResizeEventToPanels = this.pushResizeEventToPanels.bind(this);
        this.isHandled = this.isHandled.bind(this);
        this.getCommentsCount = this.getCommentsCount.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.discardChanges = this.discardChanges.bind(this);
        this.getEnableSaveDiscard = this.getEnableSaveDiscard.bind(this);
        this.blockNavigation = this.blockNavigation.bind(this);
        this.computeCommentsComponent = this.computeCommentsComponent.bind(this);

        this.resizeHandler = React.createRef();
        this.workflowDetails = React.createRef();
        this.grid = React.createRef();
        this.editorPanelRef = React.createRef();
        this.attachmentsPanelRef = React.createRef();
        this.enableModularViewResize = document.body.clientWidth > 1300;
        this.useNewAddComments = launchDarkly.companyClient && launchDarkly.companyClient.variation("fe-improve-add-comments", false);

    }

    async componentDidMount() {
        const attachmentWidth = document.body.clientWidth < 1300 ? this.grid.current?.clientWidth : this.grid.current?.clientWidth * 0.5;
        this.timeOnPage = Date.now();
        this.resizeInstances = 0;
        this.setState({
            handledWarningEnabled: true,
            resizeHandlerHeight: this.grid.current?.clientHeight,
            commentsEditing: false,
            handleCommentsSave: false,
            handleCommentsDiscard: false,
            width: parseInt(window.localStorage.getItem(`@@grid/attachments-width`)) || attachmentWidth,
        }, () => {
            if (this.enableModularViewResize) {
                this.handleWindowResize();
            }
        });
        //handle shortcut keys
        window.addEventListener("keydown", this.handleKeyPress);
        if (this.enableModularViewResize)
            window.addEventListener("resize", this.handleWindowResize);

        if (this.refs.details) {
            this.setState({
                panelHeight: this.refs.details.getBoundingClientRect().height,
            });
        }

        let shouldEnforceDialog = this.props.taskDetails.skipRestart && this.props.taskDetails.reapproveType === CONFIRMATION;
        this.props.approveMultipleShortcutKey(shouldEnforceDialog);
        this.props.changeTryToLoopTasksFlag(true);
    }

    componentDidUpdate(prevProps, prevState) {
        // we became 'loaded' :)
        if (this.props.loadingStatus !== prevProps.loadingStatus && this.props.loadingStatus === LOADING_STATUS.DONE) {
            // stop tracking virtual page
            this.props.pageLoaded();
            let shouldEnforceDialog = this.props.taskDetails.skipRestart && this.props.docEditedFlag && this.props.taskDetails.reapproveType === CONFIRMATION;
            this.props.approveMultipleShortcutKey(shouldEnforceDialog);
        }

        if (this.props.params.taskId !== prevProps.params.taskId) {
            this.timeOnPage = Date.now();
            this.resizeInstances = 0;
            this.setState({
                handledWarningEnabled: true,
                commentsEditing: false,
                handleCommentsSave: false,
                handleCommentsDiscard: false,
            });
        }

        // if (this.props.taskActionsRunning !== prevProps.taskActionsRunning && this.props.taskActionsRunning && this.props.commentsHaveUnsavedChanges) {
        //     this.props.routerBlocker?.unblockNavigation();
        // }

        if (this.props.commentsHaveUnsavedChanges !== prevProps.commentsHaveUnsavedChanges || this.props.iframeHasUnsavedChanges !== prevProps.iframeHasUnsavedChanges) {
            this.blockNavigation();
        }
    }

    componentWillUnmount() {
        snowplowTrackEvent({
            category: "task_details",
            action: "resize",
            label: "resize_with_drag",
            value: this.resizeInstances,
        }, this.props.userConfiguration);
        window.removeEventListener("keydown", this.handleKeyPress);
        if (this.enableModularViewResize)
            window.removeEventListener("resize", this.handleWindowResize);
    }

    logTimeOnPage() {
        if (this.props.taskActionsRunning && (this.props.actionParams.type === TASK_ACTIONS.APPROVE || this.props.actionParams.type === TASK_ACTIONS.REJECT)) {
            snowplowTrackEvent({
                category: "task_details",
                action: "task_details",
                label: "task_details_time_on_page",
                value: (Date.now() - this.timeOnPage) / 1000,
            }, this.props.userConfiguration);
        }
    }

    remain() {
        this.props.routerBlocker.blocker?.reset();
        this.setState({
            confirmNavigation: false,
        });
    }

    togglePanelVisibility(panelId) {
        this.setState({
            itemsVisible: {
                ...this.state.itemsVisible,
                [panelId]: !this.state.itemsVisible[panelId],
            },
        }, () => {
            Object.keys(this.state.itemsVisible)
                .forEach((key) => window.localStorage.setItem(`@@grid/${key}/visible`, this.state.itemsVisible[key]));

            if (!this.state.itemsVisible[COMMENTS] && !this.state.itemsVisible[WORKFLOW_DETAILS] && !this.state.itemsVisible[DOCUMENT_DETAILS]) {
                eventBus.dispatch("@@grid/refreshGridLayout");
                this.setState({
                    resizeHandlerHeight: this.grid.current?.clientHeight,
                });
            }
            eventBus.dispatch("@@grid/refreshAttachmentsPanelLayout");

            snowplowTrackEvent({
                category: "task_details",
                action: "show_hide_module",
                label: "toggle_module_" + panelId,
                value: this.state.itemsVisible[panelId],
            }, this.props.userConfiguration);
        });
    }

    isEditorReadOnly() {
        return this.props.type === DETAILS_VIEW_TYPE.HISTORY_TASK
            || this.props.type === DETAILS_VIEW_TYPE.PROCESS
            || (this.props.type === DETAILS_VIEW_TYPE.DOCUMENT && this.props.activeCounter !== DOCUMENT_OVERVIEW_STATUS.BLOCKED);
    }

    handleKeyPress(event) {
        if (canDo(event)) {
            keyPressHandler(event, this.props.taskAction, [this.props.params.taskId], this.props.activeActions, this.props.routerBlocker.history);
        }
    }

    getPanelsVisibleForIntegration() {
        const applicationName = this.props.task.externalApplicationName || this.props.task.applicationTypeName || "";
        const docType = DOCUMENT_TYPE.asString(this.props.task.documentType);
        const integrationsForDocType = PANELS_BY_DOCTYPE_AND_INTEGRATION[docType];
        return integrationsForDocType ? (integrationsForDocType[applicationName] || PANELS_BY_DOCTYPE_AND_INTEGRATION.DEFAULT)
            : PANELS_BY_DOCTYPE_AND_INTEGRATION.DEFAULT;
    }

    computeLayoutClass(panelsVisible, accountingGridVisible) {
        let result = "grid";
        result += panelsVisible[WORKFLOW_DETAILS] ? "_workflow" : "";
        result += panelsVisible[DOCUMENT_DETAILS] ? "_data" : "";
        result += panelsVisible[DOCUMENT_PREVIEW] ? "_preview" : "";
        result += panelsVisible[COMMENTS] ? "_comments" : "";
        result += (this.props.taskDetails.externalEditorAvailable || accountingGridVisible) ? ((this.state.renderEditorInPortal ? " editor-small" : " editor-large")) : "";

        return result;
    }

    onRenderInPortalChange(value) {
        this.setState({
                renderEditorInPortal: value,
            },
            () => {
                this.pushResizeEventToPanels();
                window.localStorage.setItem("@@grid/editor/portal", value);
                this.setState({
                    resizeHandlerHeight: this.grid.current?.clientHeight,
                });
            });
    };

    onResize = (event, { node, size, handle }) => {
        let attachmentsPanelWidth = size.width || this.resizeHandler.current?.clientWidth;
        let gridWidth = this.grid.current?.clientWidth - 10;
        let otherWidth = gridWidth - attachmentsPanelWidth - 8;
        if (otherWidth > 500) {
            this.setState({ width: attachmentsPanelWidth });
            this.setRootStyle(otherWidth, attachmentsPanelWidth);
        }
    };

    onResizeStop() {
        window.localStorage.setItem(`@@grid/attachments-width`, this.state.width);
        this.editorPanelRef?.current?.classList.remove("no-pointer-events");
        this.attachmentsPanelRef?.current?.classList.remove("no-pointer-events");
        this.resizeInstances = this.resizeInstances + 1;
        this.pushResizeEventToPanels();
    }

    onResizeStart() {
        this.editorPanelRef?.current?.classList.add("no-pointer-events");
        this.attachmentsPanelRef?.current?.classList.add("no-pointer-events");
    }

    handleWindowResize() {
        this.enableModularViewResize = document.body.clientWidth > 1300;

        let attachmentPanelWidth = this.enableModularViewResize ? this.grid.current?.clientWidth : this.state.width || this.resizeHandler.current?.clientWidth;
        let gridWidth = this.grid.current?.clientWidth - 10;
        let otherWidth;

        if (attachmentPanelWidth > gridWidth) {
            attachmentPanelWidth = gridWidth / 2 - 5;
            otherWidth = gridWidth / 2 - 5;
        } else {
            otherWidth = gridWidth - attachmentPanelWidth - 16;
        }

        this.setState({ width: attachmentPanelWidth });
        this.setRootStyle(otherWidth, attachmentPanelWidth);
        this.pushResizeEventToPanels();
    }

    setRootStyle(leftPanelWidth, rightPanelWidth) {
        const root = document.documentElement;
        root?.style.setProperty(
            "--columns_2_width_50_Template", leftPanelWidth + "px " + rightPanelWidth + "px",
        );
        root?.style.setProperty(
            "--columns_3_width_46_Template", leftPanelWidth / 2 + "px " + leftPanelWidth / 2 + "px " + rightPanelWidth + "px",
        );
    }

    maximizeAttachmentsPanel() {
        let leftSideWidth = 500;
        const gridWidth = this.grid.current?.clientWidth - 20;
        const attachmentPanelWidth = gridWidth - leftSideWidth;
        this.setState({ width: attachmentPanelWidth }, () => {
            this.pushResizeEventToPanels();
        });
        window.localStorage.setItem(`@@grid/attachments-width`, attachmentPanelWidth);
        this.setRootStyle(leftSideWidth, attachmentPanelWidth);
    }

    minimizeAttachmentsPanel() {
        if (!this.isAttachmentPanelWidthDefault()) {
            const gridWidth = this.grid.current?.clientWidth - 20;
            let attachmentPanelWidth = gridWidth * 0.5;
            const leftSideWidth = gridWidth - attachmentPanelWidth;
            this.setState({ width: attachmentPanelWidth }, () => {
                this.pushResizeEventToPanels();
            });
            window.localStorage.setItem(`@@grid/attachments-width`, attachmentPanelWidth);
            this.setRootStyle(leftSideWidth, attachmentPanelWidth);
        }
    }

    isAttachmentPanelWidthDefault() {
        const gridTotalWidth = this.grid.current?.clientWidth - 10;
        const defaultAttPanelWidth = gridTotalWidth * 0.5;
        const attPanelWidth = this.resizeHandler.current?.clientWidth;

        return attPanelWidth >= defaultAttPanelWidth - 50 && attPanelWidth <= defaultAttPanelWidth + 50;
    }

    isAttachmentPanelMaxWidth() {
        const gridTotalWidth = this.grid.current?.clientWidth - 10;
        const attPanelWidth = this.resizeHandler.current?.clientWidth;
        const leftPanelWidth = gridTotalWidth - attPanelWidth;

        return leftPanelWidth >= 450 && leftPanelWidth <= 550;
    }

    pushResizeEventToPanels() {
        eventBus.dispatch("@@grid/refreshGridLayout");
        eventBus.dispatch("@@grid/refreshAttachmentsPanelLayout");
    }

    sendMessageToEditor(message) {
        snowplowTrackEvent({
            category: "task_details",
            action: "forgot_to_save_changes",
            label: "forgot_to_save_changes_in_editor",
            value: message.command,
        }, this.props.userConfiguration);

        const iframeElement = document.getElementById("Document-Editor-Iframe");
        const target = new URL(iframeElement?.src).origin;
        iframeElement?.contentWindow.postMessage(message, target);
    }

    saveEditorChanges() {
        const message = {
            command: "saveChanges",
        };
        this.sendMessageToEditor(message);
    }

    discardEditorChanges() {
        const message = {
            command: "discardChanges",
        };
        this.sendMessageToEditor(message);
    }

    saveCommentsChanges() {
        this.props.taskCommentStartSaving();
    }

    discardCommentsChanges() {
        this.props.changeUnsavedComment(null);
    }

    getEnableSaveDiscard() {
        return this.props.iframeHasUnsavedChanges && this.props.iframeVBNXTSaveDiscardFlag || (this.props.commentsHaveUnsavedChanges && !this.props.iframeHasUnsavedChanges);
    }

    saveChanges() {
        if (this.props.iframeHasUnsavedChanges) {
            this.saveEditorChanges();
        }
        if (this.props.commentsHaveUnsavedChanges) {
            this.saveCommentsChanges();
        }
    }

    discardChanges() {
        if (this.props.iframeHasUnsavedChanges) {
            this.discardEditorChanges();
        }
        if (this.props.commentsHaveUnsavedChanges) {
            this.discardCommentsChanges();
        }
    }

    blockNavigation() {
        this.props.routerBlocker?.shouldBlockNavigation(this.props.iframeHasUnsavedChanges || this.props.commentsHaveUnsavedChanges);
    }

    isHandled() {
        const currentStatus = DETAILS_STATUS[this.props.workflowData?.status];
        return this.props.allowActionlessComments &&
            currentStatus !== DETAILS_STATUS.ACTIVE &&
            currentStatus !== DETAILS_STATUS.BLOCKED;
    }

    getCommentsCount() {
        return this.props.allowActionlessComments ? this.props.commentsCount : (this.props.task.numberOfComments || this.props.taskDetails.numberOfComments);
    }

    computeCommentsComponent() {
        if (!this.useNewAddComments)
            return (<CommentsPanel taskId={this.props.params.taskId}
                                   readOnly={this.props.isHistory || this.props.isHandled}
                                   isDocument={this.props.isDocument}
                                   commentsCount={this.props.task.numberOfComments || this.props.taskDetails.numberOfComments} />);
        if (this.props.allowActionlessComments)
            return (<ActionlessComments taskId={this.props.params.taskId}
                                        readOnly={this.isHandled()}
                                        addCommentHandler={this.props.addCommentHandler}
                                        getCommentsHandler={this.props.getCommentsHandler}
            />);

        return (<ActionComments taskId={this.props.params.taskId}
                                readOnly={this.props.isHistory || this.props.isHandled}
                                isDocument={this.props.isDocument}
                                commentsCount={this.props.task.numberOfComments || this.props.taskDetails.numberOfComments}
        />);
    }

    render() {

        const editorReadOnly = this.isEditorReadOnly();
        const areActionCommentsVisible = this.props.allowActionlessComments ? true : !this.props.isDocument;

        const editorOnly = this.state.itemsVisible[EDITOR] && ![WORKFLOW_DETAILS, DOCUMENT_DETAILS, DOCUMENT_PREVIEW, COMMENTS].some((id) => this.state.itemsVisible[id]);
        const panelsVisibleForIntegration = this.getPanelsVisibleForIntegration();

        const panelsVisible = {
            [WORKFLOW_DETAILS]: this.state.itemsVisible[WORKFLOW_DETAILS] && panelsVisibleForIntegration[WORKFLOW_DETAILS],
            [DOCUMENT_DETAILS]: this.state.itemsVisible[DOCUMENT_DETAILS] && panelsVisibleForIntegration[DOCUMENT_DETAILS],
            [DOCUMENT_PREVIEW]: this.state.itemsVisible[DOCUMENT_PREVIEW] && panelsVisibleForIntegration[DOCUMENT_PREVIEW],
            [COMMENTS]: this.state.itemsVisible[COMMENTS] && panelsVisibleForIntegration[COMMENTS] && areActionCommentsVisible,
            [EDITOR]: this.state.itemsVisible[EDITOR] && panelsVisibleForIntegration[EDITOR],
            [ACCOUNTING_GRID]: this.state.itemsVisible[ACCOUNTING_GRID] && panelsVisibleForIntegration[ACCOUNTING_GRID],
        };


        const accountingGridVisible = this.props.task?.hasAccountingGrid || this.props.task?.allowGridEdit || (this.props.taskDetails && this.props.taskDetails.allowGridEdit);
        const containerClass = this.computeLayoutClass(panelsVisible, accountingGridVisible);
        this.logTimeOnPage();


        return (
            <div id="task-details-wrapper" className={`${editorOnly ? "editor-only" : ""}`}>
                <AsyncTaskActionExecutorTask
                    translate={this.props.translate}
                    type={this.props.type}
                    taskDetails={this.props.taskDetails}
                    navigateBetweenTasks={this.props.navigateBetweenTasks}
                    reloadTasks={() => this.props.loadTasks()}
                />

                {this.state.confirmNavigation || this.props.routerBlocker?.isNavigationBlocked() &&
                    <Confirm translate={this.props.translate}
                             handleAction={this.remain}
                             hideClose={true}
                             noTitle={true}
                             confirmButtonText={"popUp.confirm.gotit"}
                             confirmButtonColor={"btn-default"}
                             enableSaveDiscard={this.getEnableSaveDiscard()}
                             moreContent={
                                 <SaveDiscardMessage translate={this.props.translate}
                                                     iframeHasUnsavedChanges={this.props.iframeHasUnsavedChanges}
                                                     commentsHaveUnsavedChanges={this.props.commentsHaveUnsavedChanges}
                                 />
                             }
                             isHandleMultipleActions={this.props.iframeHasUnsavedChanges && this.props.commentsHaveUnsavedChanges}
                             saveCallback={this.saveChanges}
                             discardCallback={this.discardChanges}
                             confirmIcon={"vismaicon-warning"}
                             closeCallback={this.remain} />
                }

                {this.props.task.isHandled && this.state.handledWarningEnabled &&
                    <HandledTask translate={this.props.translate}
                                 handleAction={this.remain}
                                 closeCallback={() => {
                                     this.setState({ handledWarningEnabled: false });
                                 }} />
                }

                <TaskDetailsSubnavbar editorReadOnly={editorReadOnly} {...this.props}
                                      togglePanelVisibility={this.togglePanelVisibility}
                                      panelVisibility={panelsVisible} />

                <div className="grid-layout-with-sidebar ">
                    <div className={`grid-layout-wrapper`}>
                        <div className="grid-panel-wrapper">

                            <img src={MouseDown} className={"more-items-img border-0 display-none"}
                                 alt={"scroll down"} />

                            <div ref={this.grid}
                                 className={containerClass + " grid-base "}>

                                <div className={"grid-panel-wrapper blue attachments-panel"}
                                     ref={this.resizeHandler}>
                                    <div className={"attachments-panel__grid-panel grid-panel "}>
                                        {this.enableModularViewResize ?

                                            <ResizableBox
                                                width={this.state.width}
                                                maxConstraints={[this.grid.current?.clientWidth - 500, 500]}
                                                minConstraints={[500, 500]}
                                                resizeHandles={["w"]}
                                                handle={<MyHandle translate={this.props.translate}
                                                                  height={this.grid.current?.clientHeight} />}
                                                draggableOpts={{ grid: [25, 25] }}
                                                onResize={this.onResize}
                                                onResizeStop={this.onResizeStop}
                                                onResizeStart={this.onResizeStart}>
                                                <div ref={this.attachmentsPanelRef}>
                                                    <AttachmentsPanel attachments={this.props.attachments}
                                                                      width={this.state.width}
                                                                      loadingStatus={this.props.loadingStatus}
                                                                      taskDetails={this.props.taskDetails}
                                                                      translate={this.props.translate}
                                                                      type={this.props.type}
                                                                      rerender={this.state.reRenderAttachments}
                                                                      maximizePanel={this.maximizeAttachmentsPanel}
                                                                      minimizePanel={this.minimizeAttachmentsPanel}
                                                                      isAttachmentPanelWidthDefault={this.isAttachmentPanelWidthDefault()}
                                                                      isAttachmentPanelMaxWidth={this.isAttachmentPanelMaxWidth()}
                                                                      isFullWidth={this.state.itemsVisible[COMMENTS] || this.state.itemsVisible[WORKFLOW_DETAILS] || this.state.itemsVisible[DOCUMENT_DETAILS]}
                                                    />
                                                </div>
                                            </ResizableBox> :
                                            <AttachmentsPanel attachments={this.props.attachments}
                                                              loadingStatus={this.props.loadingStatus}
                                                              taskDetails={this.props.taskDetails}
                                                              translate={this.props.translate}
                                                              type={this.props.type}
                                                              isFullWidth={this.state.itemsVisible[COMMENTS] || this.state.itemsVisible[WORKFLOW_DETAILS] || this.state.itemsVisible[DOCUMENT_DETAILS]}
                                            />


                                        }

                                    </div>
                                </div>


                                {this.props.taskDetails.externalEditorAvailable &&
                                    <div className={"editor" + (this.state.renderEditorInPortal ? " small" : "")}
                                         ref={this.editorPanelRef}>
                                        <DocumentEditorPanel {...this.props}
                                                             renderInPortal={this.state.renderEditorInPortal}
                                                             editorReadOnly={this.isEditorReadOnly()}
                                                             onRenderInPortalChange={this.onRenderInPortalChange} />
                                    </div>
                                }


                                <div className="doc-data">
                                    <div className={"grid-panel"}>
                                        <DocumentData taskId={this.props.params.taskId}
                                                      documentType={this.props.task.documentType}
                                                      translate={this.props.translate}
                                                      readOnly={this.props.readOnly}
                                                      isDocument={this.props.isDocument} />
                                    </div>
                                </div>

                                <div className="workflow-details">
                                    <div className={"grid-panel"} ref={this.workflowDetails}>
                                        <WorkflowDetailsPanel taskId={this.props.params.taskId}
                                                              isDocument={this.props.isDocument} />
                                    </div>
                                </div>

                                <div className="comments">
                                    <div className={"grid-panel"}>
                                        {this.computeCommentsComponent()}
                                    </div>
                                </div>

                                {accountingGridVisible &&
                                    <div className={"accounting-grid-panel"}>
                                        <AccountingGridPanel {...this.props}
                                                             renderInPortal={this.state.renderEditorInPortal}
                                                             onRenderInPortalChange={this.onRenderInPortalChange} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div role={"complementary"} aria-label={"modules-actions"}>
                        <TaskDetailsSidebar togglePanelVisibility={this.togglePanelVisibility}
                                            documentData={this.props.documentData}
                                            panelVisibility={panelsVisible}
                                            panelAvailability={panelsVisibleForIntegration}
                                            translate={this.props.translate}
                                            isCommentsVisible={areActionCommentsVisible}
                                            commentsCount={this.getCommentsCount()}
                        />
                    </div>
                </div>

            </div>
        );
    }
}

const withTranslations = translate(TaskDetails);

const
    connected = connect((store) => ({
        workflowData: reduxSelectors.getTaskWorkflowDetails(store),
        userLanguage: reduxSelectors.getLanguage(store),
        documentData: reduxSelectors.getTaskDocumentData(store),
        taskActionsRunning: reduxSelectors.areTaskActionsRunning(store),
        actionParams: reduxSelectors.getActionParameters(store),
        userConfiguration: reduxSelectors.getUsersData(store),
        commentsCount: reduxSelectors.getTaskCommentsCount(store),
        iframeHasUnsavedChanges: reduxSelectors.getIframeHasUnsavedChanges(store),
        commentsHaveUnsavedChanges: reduxSelectors.getCommentsHaveUnsavedChanges(store),
        iframeVBNXTSaveDiscardFlag: reduxSelectors.getVBNXTSaveDiscardFlag(store),
    }), {
        loadTasks,
        pageLoaded,
        pageDataLoaded,
        approveMultipleShortcutKey,
        changeTryToLoopTasksFlag,
        taskCommentStartSaving,
        changeUnsavedComment,
    })(withTranslations);

export default connected;

// @ts-ignore
class MyHandleComponent extends React.Component {
    render() {
        const { handleAxis, innerRef, ...props } = this.props;
        return <div ref={innerRef} className={`handle-resize-${handleAxis}`} {...props}
                    style={{ height: this.props.height - 10 + "px" }}>
            <div className={"resize-handle-blue"} title={props.translate("taskDetail.dragToResize")} />
        </div>;
    }
}

const MyHandle = React.forwardRef((props, ref) => <MyHandleComponent innerRef={ref} {...props} />);
