import React from 'react';
import {FormGroup, FormControl, Form} from 'react-bootstrap';
import {KEYS} from '../../utils/constants';
import PropTypes from "prop-types";


const SearchGroupInput = (props) => {

    //prevent enter from being pressed
    let handleKeyDown = function (event) {
        if (event.keyCode === KEYS.ENTER) {
            event.stopPropagation();
            event.preventDefault();
        }
    }

    return (
        <Form className="col-md-12 px-0" style={props.width ? {width: props.width} : {}} aria-label={props.ariaLabel}>
            <FormGroup className="search-group px-0">
                <FormControl type="text"
                             name={"searchInput"}
                             placeholder={props.placeholder ? props.placeholder : props.translate("myTasks.search")}
                             aria-label={props.ariaLabel ? props.ariaLabel : props.translate("myTasks.search")}
                             onKeyDown={handleKeyDown}
                             value={props.value}
                             onChange={props.onInputChange}/>
                <span className="search-icon"/>
                <span className={"clear-search " + (props.value ? "show" : "")}
                      onClick={props.onClearInput}/>
            </FormGroup>
        </Form>
    );
}

export default SearchGroupInput;


SearchGroupInput.propTypes = {
    translate: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onInputChange: PropTypes.func,
    onClearInput: PropTypes.func
};