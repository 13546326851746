import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import * as reduxSelectors from '../store/application.reducers';

import ConfigurationWrapper from '../configuration/ConfigurationWrapper.component';
import {
    getOrganizationChartUsers,
    changeSelectedNode,
    changeSelectedDate,
    orgChartFilterChanged,
    getOrgChartTreeNodes,
    sortingColumnChanged,
    loadMoreUsers
} from './organisationChart.action';
import {ResizableTable} from '../resizableTable/Table.component';
import DatePicker from 'react-flatpickr';
import {ORGANISATION_CHART} from '../resizableTable/columnsMap';
import translate from '../translations/translations.wrapper.jsx';
import "./organisationChart.scss";
import moment from "moment";


export class OrganisationChart extends ConfigurationWrapper {

    constructor(props) {
        super(props);

        this.state = {
            tableWidth: ((document.body.clientWidth - 40) / 1.54)
        };

        this.changeSelectedDate = this.changeSelectedDate.bind(this);
        this.getData = this.getData.bind(this);
        this.loadMoreUsers = this.loadMoreUsers.bind(this);
        this.loadNodeChildren = this.loadNodeChildren.bind(this);
        this.refreshGrid = this.refreshGrid.bind(this);
        this.sortingColumnChanged = this.sortingColumnChanged.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.onChangeSelectedNode = this.onChangeSelectedNode.bind(this);
    }

    componentDidMount(props) {
        super.componentDidMount(props);
        window.addEventListener("resize", this.updateDimensions);
    }

    changeSelectedDate(value) {
        this.props.changeSelectedDate(moment(value).format("YYYY-MM-DD"), this.props.filters);
    }

    getData() {
        this.props.getOrgChartTreeNodes(this.props.selectedDate);
    }

    loadMoreUsers(callObject) {
        this.props.loadMoreUsers(callObject, this.props.selectedDate, this.props.selectedNode);
    }

    loadNodeChildren(nodeId) {
        this.props.getOrgChartTreeNodes(this.props.selectedDate, nodeId);
    }

    refreshGrid(filter) {
        this.props.getOrganizationChartUsers(this.props.selectedDate, this.props.selectedNode, filter);
    }

    sortingColumnChanged(value) {
        this.props.sortingColumnChanged(value);
    }

    updateDimensions() {
        this.setState({
            tableWidth: this.refs.tableParent.getBoundingClientRect().width - 24
        });
    }

    onChangeSelectedNode(node) {
        this.props.changeSelectedNode(node);

        if (this.props.onChangeSelectedNode) {
            this.props.onChangeSelectedNode(node);
        }
    }

    buildContent() {

        let selector_values = {
            role_select: this.props.roles
        };


        let tree = this.props.orgChartTree ? this.props.orgChartTree.rows.map((value, index) => {
            return (<OrgChartNode key={index} node={value}
                                  selectedNode={this.props.selectedNode}
                                  selectedDate={this.props.selectedDate}
                                  children={this.props.children}
                                  loadNodeChildren={this.loadNodeChildren}
                                  getOrganizationChartUsers={this.props.getOrganizationChartUsers}
                                  changeSelectedNode={this.onChangeSelectedNode}
            />);
        }) : <span/>;

        return (
            <div id="Organisation-Chart" className={this.props.isCompact ? "compact" : ""}>
                {!this.props.isCompact &&
                <h3 className="no-border d-flex align-items-center pt-4">
                    {this.props.translate("organisation.header")}
                    <span className="vismaicon vismaicon-filled vismaicon-help ms-4"
                          title={this.props.translate("organisation.headerInfo")}/>
                </h3>
                }

                {(this.props.orgChartTree && this.props.orgChartTree.rows.length > 0) ?
                    <Fragment>
                        <div className="d-flex align-items-center mb-4">
                            <div className="me-2">
                                {this.props.translate("organisation.inspectDate")}
                            </div>
                            <div>
                                <DatePicker
                                    options={{
                                        dateFormat: 'd/m/Y',
                                        allowInput: true
                                    }}
                                    value={moment(this.props.selectedDate, "YYYY-MM-DD").toDate()}
                                    onChange={([value]) => this.changeSelectedDate(value)}
                                />
                            </div>
                        </div>
                        <div className="row mx-0">
                            <div className={this.props.isCompact ? "col-md-12" : "col-md-4"}>
                                <div className="panel">
                                    {tree}
                                </div>
                            </div>
                            {!this.props.isCompact &&
                            <div className="col-md-8 px-0" ref="tableParent">
                                <ResizableTable tasks={this.props.orgChartUsers}
                                                filters={this.props.filters}
                                                tableWidth={this.state.tableWidth}
                                                defaultColumns={ORGANISATION_CHART}
                                                loadingStatus={this.props.loadingStatus}
                                                translate={this.props.translate}
                                                selectors={selector_values}
                                                filterChanged={this.props.orgChartFilterChanged}
                                                refreshGrid={this.refreshGrid}
                                                hideRowsNumber={true}
                                                sortingLogic={this.props.sortingLogic}
                                                sortingColumnChanged={this.sortingColumnChanged}
                                                loadMoreTasks={this.loadMoreUsers}
                                                noDataString={this.props.translate("organisation.noOrganisationData")}
                                />
                            </div>
                            }
                        </div>

                    </Fragment> :
                    <span>{this.props.translate("generic.noData")}</span>
                }
            </div>
        )
    }
}

const withTranslations = translate(OrganisationChart);

const mapStateToProps = function (store) {
    return {
        currentContextId: reduxSelectors.getUsersCurrentContextId(store),
        userRoles: reduxSelectors.getUsersRoles(store),
        orgChartUsers: reduxSelectors.getOrgChartUsers(store),
        selectedNode: reduxSelectors.getOrgChartSelectedNode(store),
        userLanguage: reduxSelectors.getLanguage(store),
        selectedDate: reduxSelectors.getOrgChartSelectedDate(store),
        roles: reduxSelectors.getOrgChartRoles(store),
        filters: reduxSelectors.getOrgChartFilters(store),
        orgChartTree: reduxSelectors.getOrgChartTree(store),
        sortingLogic: reduxSelectors.getOrgChartSortingLogic(store),
        sortingColumn: reduxSelectors.getOrgChartSortingColumn(store),
        loadingStatus: reduxSelectors.getOrgChartUsersLoadingStatus(store),
        children: reduxSelectors.getOrgChartChildNodes(store)
    };
};

const connected = connect(mapStateToProps, {
    getOrganizationChartUsers,
    changeSelectedNode,
    changeSelectedDate,
    orgChartFilterChanged,
    getOrgChartTreeNodes,
    sortingColumnChanged,
    loadMoreUsers
})(withTranslations);

export default connected;

class OrgChartNode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        };

        this.buildChildContent = this.buildChildContent.bind(this);
        this.getData = this.getData.bind(this);
        this.openNode = this.openNode.bind(this);
    }

    buildChildContent() {
        let {children, node} = this.props;

        let tree = children[node.id] && children[node.id].rows ? children[node.id].rows.map((value, index) => {
            if (!value.leaf) {
                return <OrgChartNode key={index}
                                     node={value}
                                     selectedNode={this.props.selectedNode}
                                     selectedDate={this.props.selectedDate}
                                     children={children}
                                     loadNodeChildren={this.props.loadNodeChildren}
                                     getOrganizationChartUsers={this.props.getOrganizationChartUsers}
                                     changeSelectedNode={this.props.changeSelectedNode}/>
            }
            else {
                return (
                    <OrgChartChildNode selected={(this.props.selectedNode && this.props.selectedNode.id === value.id)}
                                       key={index}
                                       selectedDate={this.props.selectedDate}
                                       node={value}
                                       changeSelectedNode={this.props.changeSelectedNode}
                                       getOrganizationChartUsers={this.props.getOrganizationChartUsers}
                    />
                );
            }
        }) : <span/>;

        return tree;
    }

    getData() {
        this.props.changeSelectedNode(this.props.node);
        this.props.getOrganizationChartUsers(this.props.selectedDate, this.props.node);
    };

    openNode(event) {
        event.stopPropagation();
        if (!this.props.node.children) {
            this.props.loadNodeChildren(this.props.node.id);
        }

        this.setState({
            open: !this.props.node.children
        });
    }

    render() {
        let {node} = this.props;

        return (
            <div className={this.props.selectedNode && this.props.selectedNode.id === node.id ? " selected" : ""}>
                <div className=" collapsable-head col-md-12" onClick={this.getData}>
                    {!node.leaf ?
                        <span
                            className={`vismaicon ${this.state.open ? " vismaicon-arrow-down-circle" : " vismaicon-arrow-right-circle"}`}
                            onClick={this.openNode}/>
                        :
                        <span className="vismaicon invisible"/>
                    }
                    <span className="unit-title">{node.item.organizationUnitCode} - {node.item.name}</span>
                </div>

                <div
                    className={"collapsable-content col-md-12" + (this.state.open ? " display-block" : " dispay-none")}>
                    {this.buildChildContent()}
                </div>
            </div>
        )
    }

}

const OrgChartChildNode = (props) => {
    let getData = function () {
        props.changeSelectedNode(props.node);
        props.getOrganizationChartUsers(props.selectedDate, props.node);
    };

    return (<div className={"col-md-12 tree-child" + (props.selected ? " selected" : "")}
                 onClick={getData}>
        {props.node.item.organizationUnitCode} - {props.node.item.name}
    </div>);
};




