import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import * as reduxSelectors from '../../store/application.reducers';
import {
    deleteCostUnitRole,
    getCostUnitsRoles,
    saveCostUnitsRoles,
    editCostUnitsRoles,
    sortCostUnitRoles, getCostUnitsBranches
} from '../costunits.action';
import {LOADING_STATUS} from "../../../utils/constants";
import arrow from "../../../assets/ill-pointing_arrow-top-right.svg";
import businessman from "../../../assets/businessman.png";
import {AssignApproversGridHeader} from "./AssignApproversGridHeader.component";
import {AssignApproversRow} from "./CostUnitsApproversRow.component";
import SearchGroupInput from "../../input/SearchGroupInput.component";
import {DropdownButton, Dropdown} from "react-bootstrap";

class AssignApproverGrid extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLastRow: false,
            isEditing: false,
            filters: {
                approver: '',
                role: '',
                deputy: '',
                branch: ''
            },
        };
        this.insertNewRow = this.insertNewRow.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
        this.setEditMode = this.setEditMode.bind(this);
        this.onRoleFilterChange = this.onRoleFilterChange.bind(this);
        this.onRoleFilterClear = this.onRoleFilterClear.bind(this);
        this.onApproverFilterChange = this.onApproverFilterChange.bind(this);
        this.onApproverFilterClear = this.onApproverFilterClear.bind(this);
        this.onDeputyFilterSelect = this.onDeputyFilterSelect.bind(this);
        this.onBranchFilterChange = this.onBranchFilterChange.bind(this);
    }

    componentDidMount() {
        this.props.getCostUnitsRoles();
        this.props.getCostUnitsBranches();
    }

    componentDidUpdate(prevProps) {
        if (this.props.costUnitRoles && prevProps.costUnitRoles && prevProps.costUnitRoles.costUnitId !== this.props.costUnitRoles.costUnitId) {
            this.cancelEdit();
        }
    }

    insertNewRow() {
        this.setState({showLastRow: true, isEditing: true});
        this.props.preventNavigation(true);
    }

    cancelEdit() {
        this.setState({showLastRow: false, isEditing: false});
        this.props.preventNavigation(false);
    }

    setEditMode(isEditing) {
        this.setState({isEditing: isEditing, okEnabled: isEditing});
        this.props.preventNavigation(isEditing);
    }

    onRoleFilterChange(event) {
        this.setState({
            filters: {
                ...this.state.filters,
                role: event.target.value
            }
        })
    }

    onRoleFilterClear(event) {
        this.setState({
            filters: {
                ...this.state.filters,
                role: ''
            }
        })
    }

    onApproverFilterChange(event) {
        this.setState({
            filters: {
                ...this.state.filters,
                approver: event.target.value
            }
        })
    }

    onApproverFilterClear(event) {
        this.setState({
            filters: {
                ...this.state.filters,
                approver: ''
            }
        })
    }

    onDeputyFilterSelect(property) {
        this.setState({
            filters: {
                ...this.state.filters,
                deputy: property ? property === 'true' : ''
            }
        })
    }

    onBranchFilterChange(property) {
        this.setState({
            filters: {
                ...this.state.filters,
                branch: property
            }
        })
    }

    render() {
        let costUnitCombinations = this.props.costUnitRoles && this.props.costUnitRoles.rows.length > 0 ?
            this.props.costUnitRoles.rows
                //Filters
                .filter((row) => {
                    // Filter by role
                    let matches = true;
                    if (this.state.filters.role) {
                        matches = row.roleName.toLowerCase().includes(this.state.filters.role.toLowerCase())
                    }
                    return matches
                })
                .filter((row) => {
                    // Filter by approver
                    let matches = true;
                    if (this.state.filters.approver) {
                        matches = row.userNameAndEmail.toLowerCase().includes(this.state.filters.approver.toLowerCase())
                    }
                    return matches
                })
                .filter((row) => {
                    // Filter by branch
                    let matches = true;
                    if (this.state.filters.branch !== '') {
                        matches = row.branchId === this.state.filters.branch;
                    }
                    return matches
                })
                .filter((row) => {
                    // Filter by deputy
                    let matches = true;
                    if (this.state.filters.deputy !== '') {
                        matches = row.deputy === this.state.filters.deputy;
                    }
                    return matches
                })
                .map((costUnit, index) => {
                    return (<AssignApproversRow
                        key={`${costUnit.idUser}-${costUnit.role}-${costUnit.branchId}-${index}`}
                        translate={this.props.translate}
                        costUnit={costUnit}
                        nodeId={this.props.costUnitRoles.costUnitId}
                        nodeLevel={this.props.costUnitRoles.costUnitLevel}
                        deleteCostUnitRole={this.props.deleteCostUnitRole}
                        costUnitTypes={this.props.costUnitTypes}
                        isEditing={this.state.isEditing}
                        setEditMode={this.setEditMode}
                        sort={this.props.sort}
                        allowActions={this.props.allowActions}
                    />)
                }) :
            (this.state.showLastRow ? "" :
                    (this.props.approversTree?.selectedCostUnit ?
                            <div className="assign-approvers-list-empty">
                                <img className="no-approvers-arrow" src={arrow} alt="Arrow"/>
                                <img src={businessman} alt="Business man"/>
                                <h2>{this.props.translate("costUnitAssignApprovers.noApprovers")}</h2>
                                <p>{this.props.translate("costUnitAssignApprovers.noApproversBody")}</p>
                            </div> :
                            <h2 className="text-center py-3 color-grey">{this.props.translate("costUnitAssignApprovers.noCombinationSelected")}</h2>
                    )
            );

        return (
            <Fragment>
                <h2 className="no-border d-flex align-items-center pt-4">{this.props.translate("costUnitAssignApprovers.header")}
                    <span className="vismaicon vismaicon-filled vismaicon-help ms-4"
                          title={this.props.translate("costUnitApprovers.headerInfo")}/>


                    <button className="btn btn-primary btn-margin-right ms-auto"
                            onClick={this.insertNewRow}
                            disabled={!this.props.costUnitRoles || this.state.showLastRow || this.state.isEditing || !this.props.allowActions}>
                        {this.props.translate("costUnitAssignApprovers.assignApprover")}
                    </button>
                </h2>


                <div className="actions-list">
                    <AssignApproversGridHeader translate={this.props.translate}
                                               id={this.props.costUnitRoles ? this.props.costUnitRoles.costUnitId : null}
                                               level={this.props.costUnitRoles ? this.props.costUnitRoles.costUnitLevel : null}
                                               sort={this.props.sort}
                                               onSort={this.props.sortCostUnitRoles}/>

                    {this.props.loadingStatus === LOADING_STATUS.DONE &&
                        <div id="cost-units-assign-approvers-panel" className="main-panel">

                            {/*Filters*/}
                            {this.props.costUnitRoles?.rows?.length > 0 && this.props.approversTree?.selectedCostUnit &&
                                <div className="row mt-4">
                                    <div
                                        className={`${this.props.costUnitsBranches.length > 0 ? "col-md-2" : "col-md-3"}`}>
                                        <div className="margin-left-8">
                                            <SearchGroupInput
                                                placeholder={this.props.translate("costUnitAssignApprovers.findRole")}
                                                value={this.state.filters.role}
                                                onInputChange={this.onRoleFilterChange}
                                                onClearInput={this.onRoleFilterClear}
                                                translate={this.props.translate}/>
                                        </div>
                                    </div>
                                    <div
                                        className={`${this.props.costUnitsBranches.length > 0 ? "col-md-4" : "col-md-6"}`}>
                                        <SearchGroupInput
                                            placeholder={this.props.translate("costUnitAssignApprovers.findApprover")}
                                            value={this.state.filters.approver}
                                            onInputChange={this.onApproverFilterChange}
                                            onClearInput={this.onApproverFilterClear}
                                            translate={this.props.translate}/>
                                    </div>

                                    {this.props.costUnitsBranches.length > 0 &&
                                        <div className="col-md-3">
                                            <DropdownButton id="branch-filter"
                                                            className="align-left"
                                                            bsPrefix="btn btn-default"
                                                            title={this.props.costUnitsBranches.find((branch) => {
                                                                return branch.branchId === this.state.filters.branch
                                                            })?.name || this.props.translate("costUnitAssignApprovers.anyBranch")}>
                                                <Dropdown.Item key={-1}
                                                               value={''}
                                                               eventKey={''}
                                                               onClick={() => this.onBranchFilterChange('')}>
                                                    {this.props.translate("costUnitAssignApprovers.anyBranch")}
                                                </Dropdown.Item>
                                                {this.props.costUnitsBranches.filter((branch) => {
                                                    return branch.active && !branch.deleted
                                                }).map((branch, index) => {
                                                    return (<Dropdown.Item key={branch.branchId || index}
                                                                           value={branch.branchId}
                                                                           type={branch.name}
                                                                           eventKey={branch.branchId}
                                                                           onClick={() => this.onBranchFilterChange(branch.branchId)}>
                                                        {branch.name}
                                                    </Dropdown.Item>);
                                                })}
                                            </DropdownButton>
                                        </div>
                                    }
                                    <div className="col-md-2">
                                        <DropdownButton id="deputy-filter"
                                                        bsPrefix="btn btn-default"
                                                        className="align-left"
                                                        title={this.props.translate(`costUnitAssignApprovers.${this.state.filters.deputy === '' ? 'all' : this.state.filters.deputy ? 'selected' : 'unselected'}`)}
                                                        onSelect={this.onDeputyFilterSelect}>
                                            <Dropdown.Item key={-1} value={''} eventKey={''}>
                                                {this.props.translate("costUnitAssignApprovers.all")}
                                            </Dropdown.Item>
                                            <Dropdown.Item key={0} value={true} eventKey={true}>
                                                {this.props.translate("costUnitAssignApprovers.selected")}
                                            </Dropdown.Item>
                                            <Dropdown.Item key={1} value={false} eventKey={false}>
                                                {this.props.translate("costUnitAssignApprovers.unselected")}
                                            </Dropdown.Item>
                                        </DropdownButton>
                                    </div>
                                </div>}

                            {costUnitCombinations}
                            {this.props.costUnitRoles && this.props.costUnitRoles.rows.length > 0 && costUnitCombinations.length === 0 &&
                                <h2 className="text-center no-border color-grey">
                                    {this.props.translate("costUnitAssignApprovers.noResultsOnFilter")}
                                </h2>
                            }

                            {this.state.showLastRow &&
                                <AssignApproversRow
                                    translate={this.props.translate}
                                    costUnit={{
                                        userNameAndEmail: "",
                                        idUser: "",
                                        roleName: this.props.costUnitTypes.length > 0 ? this.props.costUnitTypes[0].name : "",
                                        role: this.props.costUnitTypes.length > 0 ? this.props.costUnitTypes[0].roleId : '',
                                        branchName: this.state.filters.branch ? this.props.costUnitsBranches.find((branch) => branch.branchId === this.state.filters.branch).name : this.props.translate("costUnitAssignApprovers.anyBranch"),
                                        branchId: this.state.filters.branch ? this.state.filters.branch : 0,
                                        deputy: false
                                    }}
                                    lastRow={true}
                                    nodeId={this.props.costUnitRoles.costUnitId}
                                    nodeLevel={this.props.costUnitRoles.costUnitLevel}
                                    cancelEdit={this.cancelEdit}
                                    costUnitTypes={this.props.costUnitTypes}
                                    sort={this.props.sort}
                                    allowActions={this.props.allowActions}
                                />
                            }
                        </div>
                    }
                </div>
            </Fragment>

        );
    }
}

const mapStateToProps = function (store) {
    return {
        costUnitTypes: reduxSelectors.getCostUnitRoleTypes(store),
        loadingStatus: reduxSelectors.getApproversLoadingStatus(store),
        sort: reduxSelectors.getRolesSort(store),
        approversTree: reduxSelectors.getApproversTree(store),
        costUnitsBranches: reduxSelectors.getCostUnitBranches(store)
    };
};
const AssignApproversGrid = connect(mapStateToProps, {
    deleteCostUnitRole,
    getCostUnitsRoles,
    getCostUnitsBranches,
    saveCostUnitsRoles,
    editCostUnitsRoles,
    sortCostUnitRoles
})(AssignApproverGrid);
export default AssignApproversGrid;





