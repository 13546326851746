//region imports
import React, {Component} from 'react';
import {connect} from 'react-redux';
import TaskView from './TaskView.component.jsx';
import TasksActionButtons from './MyTasksButtonsBarView.component.jsx';
import TaskListHeader from './TaskListHeaderView.component.jsx';
import Search from './SearchView.component.jsx';
import '../pagelayout/global.scss';
import {ServerError} from '../errorpage/ErrorPage.component';
import translate from '../translations/translations.wrapper.jsx';
import {MyTasksActions} from './AvailableActions.class';
import {
    changeAllTaskSelection,
    changeCompanyFilter,
    changeSearchFilter,
    changeSort,
    changeTaskSelection,
    loadTasks,
    saveScrollPosition,
    changeDisplayedDate,
    changeAutopayURL
} from './myTasks.action';

import {loadHistory} from '../myhistory/myHistory.action';
import {
    actionFinished,
    changeOneHandleOnlyFlag,
    taskAction,
    changeTryToLoopTasksFlag
} from './action/taskAction.action';
import {pageDataLoaded, pageLoaded} from '../router/route.action';
import * as reduxSelectors from '../store/application.reducers';
import {login} from "utils/api/odp.api";
import {LOADING_STATUS, TASK_ACTIONS} from "utils/constants";
import {keyPressHandler} from "utils/keyPressHandler.function";
import AsyncTaskActionExecutor from './AsyncTaskActionExecutor.component';
import Batch from "./PaymentBatch.component";
import {approveMultipleTasks} from './action/approveMultipleTasks.action';
import TableFooter from "components/resizableTable/SSETableFooter.component.tsx";


export class MyTasks extends Component {

    constructor(props) {
        super(props);
        this.sortTasks = this.sortTasks.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.toggleTask = this.toggleTask.bind(this);
        this.scrollContainer = React.createRef();
        this.list = React.createRef();
    }

    componentDidMount() {

        //handle shortcut keys
        window.addEventListener('keydown', this.handleKeyPress);
        // start loading tasks
        this.props.loadTasks();
        // reset the flag, allowing only one handle
        this.props.changeOneHandleOnlyFlag(true);
        //my tasks actions should not try to loop the tasks
        this.props.changeTryToLoopTasksFlag(false);

        this.props.changeAutopayURL(null);

        // reset the dialog if opened - it holds data about task, but data might have expired (for example when user changes the context),
        // so we should close the dialog each time user returns to myTasks
        this.props.actionFinished();

        if (this.props.scrollTop && this.scrollContainer.current && this.scrollContainer.current.scrollTop) {
            this.scrollContainer.current.scrollTop = this.props.scrollTop;
        }
    }

    sortTasks(event) {
        this.props.changeSort(event);
    }

    componentWillUnmount() {
        let scrollPosition = this.scrollContainer?.current?.scrollTop ? this.scrollContainer.current.scrollTop : 0;
        window.removeEventListener('keydown', this.handleKeyPress);
        this.props.saveScrollPosition(scrollPosition);
    }

    componentDidUpdate(prevProps) {
        // we became 'loaded' :)
        if (this.props.loadingStatus !== prevProps.loadingStatus && this.props.loadingStatus === LOADING_STATUS.DONE) {
            // stop tracking virtual page
            this.props.pageLoaded();
        }
        if ((this.props.scrollTop || this.props.changeDisplayedDate) && this.list && this.scrollContainer && this.props.scrollTop > 0) {
            this.scrollContainer.current.scrollTop = this.props.scrollTop;
        }
    }

    handleKeyPress(e) {
        e.stopPropagation();
        let activeActions = new MyTasksActions(this.props.tasks.selectedTasks);
        keyPressHandler(e, this.props.taskAction, this.props.tasks.selectedKeys, activeActions);
    }

    toggleTask(event) {
        let scrollPosition = this.scrollList && this.scrollList.scrollTop ? this.scrollList.scrollTop : 0;
        this.props.saveScrollPosition(scrollPosition);
        this.props.changeTaskSelection(event);
    }

    getTasksAsComponent() {
        return this.props.tasks.filteredTasks.map(taskWrapper => {
            const task = taskWrapper.task;
            const taskProps = {
                task: task,
                translate: this.props.translate,
                selectCallback: this.toggleTask,
                selected: taskWrapper.selected,
                displayedDate: this.props.displayedDate
            };
            const taskActions = new MyTasksActions(task);
            // pass callback, only if actions are allowed
            if (taskActions.isEnabled(TASK_ACTIONS.APPROVE)) {
                taskProps.approveCallback = () => {
                    this.props.taskAction(TASK_ACTIONS.APPROVE, [task.key]);
                };
            }
            if (taskActions.isEnabled(TASK_ACTIONS.REJECT)) {
                taskProps.rejectCallback = () => {
                    this.props.taskAction(TASK_ACTIONS.REJECT, [task.key]);
                };
            }
            if (taskActions.isEnabled(TASK_ACTIONS.REVIEW)) {
                taskProps.reviewCallback = () => {
                    this.props.taskAction(TASK_ACTIONS.REVIEW, [task.key]);
                };
            }

            return (
                <TaskView
                    key={task.id}
                    {...taskProps}
                />);

        })
    }

    render() {

        switch (this.props.loadingStatus) {

            case LOADING_STATUS.LOADING:
                return (
                    <div className="col-md-12">
                        <span className="spinner spinner-default-blue loading"/>
                    </div>
                );

            case LOADING_STATUS.ERROR:
                return (
                    <ServerError msg={this.props.tasks}/>
                );


            case LOADING_STATUS.DONE:
                let activeActions = new MyTasksActions(this.props.tasks.selectedTasks);

                // flags
                const oneCompanyOnlyUser = this.props.availableContextsCount === 1;


                const tasksAsComponents =this.getTasksAsComponent();

                let content = tasksAsComponents.length > 0 ? tasksAsComponents :
                    <NoTasksFound translate={this.props.translate}/>;

                if (this.props.tasks.allTasks.length === 0 && this.props.tasksWithPayment.length === 0 && !this.props.batchTasks) {
                    return (
                        <NoTasks translate={this.props.translate}/>
                    );
                } else {
                    let myTasksClass = this.props.hiddenTasks > 0 ? "my-tasks-content hidden-tasks-warning" : "my-tasks-content";
                    if (this.props.batchTasks) {
                        myTasksClass += " batch-tasks-area";
                    }

                    return (
                        <div className={"ast_task_list " + (this.props.hiddenTasks === 0 ? "margin-top" : "")}
                             ref="mytasks">
                            {this.props.hiddenTasks > 0 &&
                                (<section><HiddenTasksWarning hiddenTasks={this.props.hiddenTasks}
                                                              translate={this.props.translate}/></section>)
                            }
                            {this.props.batchTasks &&
                                <section className="batch-tasks">
                                    <Batch batch={this.props.batchTasks}
                                           translate={this.props.translate}/>
                                </section>
                            }

                            <AsyncTaskActionExecutor translate={this.props.translate}/>
                            <Search updateSearchCallback={this.props.changeSearchFilter}
                                    updateCompanyFilterCallback={this.props.changeCompanyFilter}
                                    searchValue={this.props.search}
                                    onlyCurrentCompany={this.props.company}
                                    oneCompanyOnlyUser={oneCompanyOnlyUser}
                                    translate={this.props.translate}
                                    displayedDate={this.props.displayedDate}
                                    changeDisplayedDate={this.props.changeDisplayedDate}
                            />
                            <main className={myTasksClass}>
                                <ul className="list-unstyled">
                                    <li className="task-list-container no-padding margin-bottom app-width">
                                        <div className="task-header app-width">
                                            <TaskListHeader
                                                sortByCallback={this.sortTasks}
                                                selectAllCallback={this.props.changeAllTaskSelection}
                                                currentSort={this.props.sort}
                                                translate={this.props.translate}
                                                currentAllSelected={this.props.tasks.areAllSelected}
                                                displayedDate={this.props.displayedDate}
                                            />
                                        </div>

                                        <div className="task-list-scroll-wrapper" ref={this.scrollContainer}>
                                            <ul className="task-list-content" aria-live="polite" ref={elem => {
                                                this.list = elem && elem.getBoundingClientRect();
                                            }}>
                                                {content}
                                            </ul>
                                        </div>
                                    </li>
                                </ul>

                                <TableFooter/>
                                <TasksActionButtons
                                    selectedTaskKeys={this.props.tasks.selectedKeys}
                                    userSettings={this.props.userConfiguration.userSettings}
                                    tasks={this.props.tasks.filteredTasks}
                                    translate={this.props.translate}
                                    action={this.props.taskAction}
                                    actions={activeActions}
                                />

                            </main>
                        </div>
                    );
                }

            default:
                // pass translate, otherwise it creates another connected component, which break the unit testing of myTasks.component
                return <ServerError translate={this.props.translate}/>;
        }

    }
}

const withTranslations = translate(MyTasks);

const mapStateToProps = function (store) {
    return {
        userConfiguration: reduxSelectors.getUsersData(store),
        currentContextId: reduxSelectors.getUsersCurrentContextId(store),
        availableContextsCount: reduxSelectors.getAvailableContextsCount(store),
        tasks: reduxSelectors.getTasksForRender(store),
        loadingStatus: reduxSelectors.getLoadingStatus(store),
        sort: reduxSelectors.getSort(store),
        search: reduxSelectors.getFilterText(store),
        company: reduxSelectors.getFilterCompany(store),
        actionParameters: reduxSelectors.getActionParameters(store),
        scrollTop: reduxSelectors.getScrollPosition(store),
        hiddenTasks: reduxSelectors.getHiddenTasks(store),
        displayedDate: reduxSelectors.getDisplayedDate(store),
        batchTasks: reduxSelectors.getActiveBatch(store),
        tasksWithPayment: reduxSelectors.getTasksWithPayment(store)
    };
};


const connected = connect(mapStateToProps, {
    changeTaskSelection,
    changeAllTaskSelection,
    changeSort,
    changeSearchFilter,
    changeCompanyFilter,
    loadTasks,
    changeOneHandleOnlyFlag,
    changeTryToLoopTasksFlag,
    actionFinished,
    taskAction,
    loadHistory,
    pageLoaded,
    pageDataLoaded,
    saveScrollPosition,
    changeDisplayedDate,
    approveMultipleTasks,
    changeAutopayURL
})(withTranslations);

export default connected;


const NoTasks = (props) => {
    return (
        <div className="no-tasks-message align-center ast_task_list">
            <span className="col-md-12 icon icon-48 smiley-face"/>
            <span
                className="col-md-10 text-left margin-bottom"><b>{props.translate("myTasks.allTasksHandled")}</b></span>
            <br/>
            <span className="col-md-10 text-left">{props.translate("myTasks.notifyYou")}</span>
        </div>)
};

const NoTasksFound = (props) => {
    return (
        <div className="no-tasks-found text-disabled">
            {props.translate('myTasks.noTasksFound')}
        </div>
    )
};

const HiddenTasksWarning = (props) => {
    let message = props.hiddenTasks > 1 ? "myTasks.hiddenTasksWarning" : "myTasks.hiddenTaskWarning";
    let licenseMessage = props.hiddenTasks > 1 ? "myTasks.loginLinkPlural" : "myTasks.loginLinkSingular";
    return (
        <div className="alert alert-sm alert-info mx-0" role="alert">
            {props.translate(message, props.hiddenTasks)}<a href="#"
                                                            onClick={login}>{props.translate(licenseMessage)}</a>{props.translate("myTasks.licenseWarning")}
        </div>
    )
};