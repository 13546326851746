import React from "react";
import resizeLarge from "../../../../assets/editor_resize_large.png";
import resizeLargeActive from "../../../../assets/editor_resize_large_blue.png";
import resizeSmall from "../../../../assets/editor_resize_small.png";
import resizeSmallActive from "../../../../assets/editor_resize_small_blue.png";
import {eventBus} from "../../../pagelayout/EventBus";
import {snowplowTrackEvent} from "utils/snowplow.js";
import * as reduxSelectors from "../../../store/application.reducers";
import {connect} from "react-redux";
import AccountingGridNew from "./AccountingGridNew.component";

class AccountingGridPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            gridToggled: undefined,
            rows: 0,
            totalRows: 0,
            gridWidth: document.body.clientWidth
        }

        this.toggleAccountingGrid = this.toggleAccountingGrid.bind(this);
        this.onUpdateRows = this.onUpdateRows.bind(this);
        this.setGridWidth = this.setGridWidth.bind(this);
        this.trackPanelCollapsing = this.trackPanelCollapsing.bind(this);
        this.accountingGridRef = React.createRef();
    }

    componentDidMount() {
        this.setGridWidth();
        eventBus.on('@@grid/refreshGridLayout', this.setGridWidth);
        window.addEventListener('resize', this.setGridWidth);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setGridWidth);
    }

    setGridWidth() {
        this.setState({
            gridWidth: this.accountingGridRef.current?.clientWidth
        });
    }

    toggleAccountingGrid(event) {
        let newState = this.state.gridToggled === undefined ? true : !this.state.gridToggled;
        this.setState({
            gridToggled: newState
        });
    }

    onUpdateRows({rows, totalRows}) {
        this.setState({rows, totalRows});
    }

    trackPanelCollapsing(isCollapsed) {
        snowplowTrackEvent({
            category: "external_editor_panel",
            action: "external_editor_collapsing",
            label: "external_editor_panel",
            value: isCollapsed ? "collapsed" : "extended"
        }, this.props.userConfiguration);
    }


    render() {
        const accountingGridVisible = this.props.task?.hasAccountingGrid || this.props.task?.allowGridEdit || (this.props.taskDetails && this.props.taskDetails.allowGridEdit);
        const panelHeight = this.state.totalRows < 3 ? 200 : (this.state.totalRows * 30) + 120;
        let windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        const relativePanelHeight = panelHeight >= windowHeight - 240 ? windowHeight - 240 : panelHeight;

        if (accountingGridVisible) {
            return (
                <div className={`grid-layout-accounting-grid ${this.props.renderInPortal ? 'in-portal' : ''}`}>
                    <div className="grid-panel-wrapper">
                        <div className="grid-panel">
                            <div className="grid-panel-header d-flex justify-content-between align-items-center">
                                <strong>
                                    {this.props.translate("accoutingGrid.Accounting")}
                                    {this.state.rows !== 0 && this.state.totalRows !== 0
                                        ? <span>
                                            {`(${this.state.rows} ${this.props.translate("accoutingGrid.of")} ${this.state.totalRows})`}
                                        </span>
                                        : null
                                    }
                                </strong>
                                <div>
                                    <img src={this.props.renderInPortal ? resizeSmall : resizeSmallActive}
                                         alt="resize button"
                                         title={this.props.translate("taskDetail.placeAtTheSide")}
                                         className={`details__action-button ${this.props.renderInPortal ? '' : 'cursor-pointer'}`}
                                         onClick={() => {
                                             this.trackPanelCollapsing(true);
                                             this.props.onRenderInPortalChange(true);
                                         }}/>
                                    <img src={this.props.renderInPortal ? resizeLargeActive : resizeLarge}
                                         alt="resize button"
                                         title={this.props.translate("taskDetail.placeAtTheBottom")}
                                         className={`details__action-button ${this.props.renderInPortal ? 'cursor-pointer' : ''}`}
                                         onClick={() => {
                                             this.trackPanelCollapsing(false);
                                             this.props.onRenderInPortalChange(false);
                                         }}/>
                                </div>
                            </div>
                            <div className="accounting-grid" ref={this.accountingGridRef}
                                 style={{height: relativePanelHeight}}>
                               <AccountingGridNew className="h-100"
                                                    width={this.state.gridWidth}
                                                    taskId={this.props.params.taskId}
                                                    translate={this.props.translate}
                                                    readOnly={this.props.isHistory || this.props.task.isHandled}
                                                    language={this.props.userLanguage}
                                                    lineChangeWarning={(value) => {
                                                        this.setState({lineWarning: value})
                                                    }}
                                                    onUpdateRows={this.onUpdateRows}
                                                    toggle={this.toggleAccountingGrid}/>
                            </div>
                        </div>
                    </div>
                </div>);
        }
        return null
    }
}

const mapStateToProps = function (store) {
    return {
        userConfiguration: reduxSelectors.getUsersData(store)
    };
};

const connected = connect(mapStateToProps, {})(AccountingGridPanel);
export default connected;
