import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as Api from 'utils/api/api.js';
import {connect} from 'react-redux';
import * as reduxSelectors from 'components/store/application.reducers';
import * as _ from 'lodash';
import {
    getTaskDetails,
    hasEditedDocument,
    updateDocumentVersion,
    getTaskDocumentData,
    integrationIframeHasUnsavedChanges,
    iframeHasVBNXTFlag
} from 'components/taskdetails-remake/taskDetails.action';
import {DETAILS_VIEW_TYPE} from 'utils/constants';
import classNames from "classnames";
import {navigateToDocumentOverviewDetails} from "components/router/router.function.js";
import {updateDocumentKey} from "components//documentoverview/documentOverview.action";
import {handleError} from 'utils/errorHandle.function';
import * as APPD from 'utils/appDynamics.function';
import {snowplowTrackEvent} from "utils/snowplow";

const CONFIRMATION = "CONFIRMATION";

export class DocumentEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            documentVersion: null,
            editor: null,
            editorLoadingError: false,
            editorState: null,
            editing: false,
            iframeLoadStart: (+new Date())
        };
        this.requests = [];
        this.receiveMessage = this.receiveMessage.bind(this);
        this.stopEditing = this.stopEditing.bind(this);
        this.onLoadIframe = this.onLoadIframe.bind(this);
        this.vPageView = null;
        this.editorRef = React.createRef();
    }

    initialize() {
        const loadTimeStart = +new Date();
        this.vPageView = APPD.getPageStartTracker("ExternalEditor/" + this.props.externalApplicationName + "/" + this.props.documentType, false);
        if (this.vPageView != null) {
            this.vPageView.parent(APPD.getGlobalPageView());
        }

        const apiCallMethod = this.props.viewType === DETAILS_VIEW_TYPE.DOCUMENT ? Api.getDocumentOverviewEditor : Api.getDocumentEditor;

        return apiCallMethod(this.props.taskId, this.vPageView).then((response) => {
            const loadTime = (+new Date()) - loadTimeStart;

            if (this.vPageView) {
                this.vPageView.markViewChangeEnd();
                this.vPageView.markViewResourcesLoaded();
            }

            const companyId = (this.props.viewType === DETAILS_VIEW_TYPE.TASK ? this.props.companyId : this.props.userConfiguration.currentContext) + "";
            const editRequest = {
                companyId,
                systemId: this.props.externalAppId + "",
                userId: this.props.userConfiguration.currentUserId + "",
                readonly: this.props.isReadOnly, //history task open editor in view mode only
                administrator: this.props.userConfiguration.userRoles.systemAdministrator,
                taskId: this.props.id
            };

            snowplowTrackEvent({
                category: "document_editor",
                action: "get_external_editor",
                label: "execution_time",
                value: loadTime,
                property: this.props.externalApplicationName
            }, this.props.userConfiguration);

            this.setState({
                editor: _.template(response.url)({
                    editRequest: encodeURIComponent(JSON.stringify(editRequest))
                }),
                editorLoadingError: false
            }, () => {
                console.log(this.editorRef.current);
            });
        }).catch((error) => {
            this.setState({
                editorLoadingError: true
            });

            if (this.vPageView) {
                APPD.pushErrorToAppDynamics();
                APPD.pageEnd(this.vPageView);
                this.vPageView = null;
            }
        });
    }

    componentDidMount() {
        window.addEventListener("message", this.receiveMessage, false);

        this.requests.push(this.initialize());
    }

    componentDidUpdate(prevProps) {
        if (this.vPageView != null && this.vPageView.getViewDOMLoaded() == null) {
            this.vPageView.markViewDOMLoaded();
        }

        if (prevProps.taskId !== this.props.taskId) {
            this.setState({
                documentVersion: null,
                editor: null,
                editorLoadingError: false,
                editorState: null,
                editing: false,
                iframeLoadStart: (+new Date())
            });

            this.requests.push(this.initialize());
        }
    }

    stopEditing(version) {
        let skipWorklowRestart = CONFIRMATION === this.props.reapproveType;

        window.removeEventListener("beforeunload", this.preventWindowClose);
        window.removeEventListener('unload', this.preventWindowClose);

        if (this.props.viewType === DETAILS_VIEW_TYPE.DOCUMENT) {
            Api.updateDocument(this.props.taskId, version, skipWorklowRestart).then(response => {
                this.props.integrationIframeHasUnsavedChanges(false);
                this.props.updateDocumentKey(this.props.taskId, response.result);
                this.props.getTaskDocumentData(this.props.taskId, this.props.viewType === DETAILS_VIEW_TYPE.DOCUMENT);
                navigateToDocumentOverviewDetails(response.result);
            }, error => {
                this.props.integrationIframeHasUnsavedChanges(false);
                handleError(error);
            });
        } else {
            this.props.updateDocumentVersion(version);
            Api.updateTask(this.props.taskId, version, skipWorklowRestart).then(() => {
                this.props.integrationIframeHasUnsavedChanges(false);
                this.props.getTaskDetails(this.props.taskId);
                this.props.getTaskDocumentData(this.props.taskId, this.props.viewType === DETAILS_VIEW_TYPE.DOCUMENT);
            }, error => {
                this.props.integrationIframeHasUnsavedChanges(false);
                handleError(error);
            });
        }
    }

    onLoadIframe() {
        const loadTime = (+new Date()) - this.state.iframeLoadStart;

        snowplowTrackEvent({
            category: "document_editor",
            action: "load_external_editor_iframe",
            label: "execution_time",
            value: loadTime,
            property: this.props.externalApplicationName
        }, this.props.userConfiguration);
    }

    componentWillUnmount() {
        if (this.vPageView != null) {
            APPD.prematurePageEnd(this.vPageView);
            this.vPageView = null;
        }
        this.requests.forEach(p => p.cancel());
        window.removeEventListener("message", this.receiveMessage);
        window.removeEventListener("beforeunload", this.preventWindowClose);
        window.removeEventListener('unload', this.preventWindowClose);
    }

    preventWindowClose = (event) => {
        event.preventDefault();
        event.returnValue = true;
        return "";
    }

    receiveMessage(event) {
        if (this.state.editor && event.origin && this.state.editor.indexOf(event.origin) === 0) {
            switch (event.data.event) {
                case "ready":
                case "viewing":
                    if (this.vPageView != null) {
                        APPD.pageEnd(this.vPageView);
                        this.vPageView = null;
                    }


                    if (!Boolean(this.props.isReadOnly) && this.state.editing) {
                        if (event.data.version !== null && event.data.version > this.state.documentVersion) {
                            this.setState({
                                editorState: event.data.event,
                                editing: false,
                                documentVersion: event.data.version
                            });
                            this.stopEditing(event.data.version);
                            this.props.hasEditedDocument();

                            return;
                        } else {
                            this.props.integrationIframeHasUnsavedChanges(false);
                        }

                        this.setState({
                            documentVersion: event.data.version
                        });

                        this.props.hasEditedDocument();
                    }

                    this.setState({
                        editorState: event.data.event,
                        editing: false
                    });
                    this.props.integrationIframeHasUnsavedChanges(false);

                    break;
                case "editing": {
                    const vbnxtflag = event.data.supportsSaveAndDiscard;
                    this.props.iframeHasVBNXTFlag(vbnxtflag);
                    this.props.integrationIframeHasUnsavedChanges(true);
                    window.addEventListener("beforeunload", this.preventWindowClose);
                    window.addEventListener('unload', this.preventWindowClose);
                    this.setState({
                        editorState: event.data.event,
                        editing: true
                    });
                    break;
                }
                case "rejectedlines":
                default:
                    // newState = this.state.state;
                    break;
            }
        }
    }


    render() {
        return (
            <div className="height-100" ref={this.editorRef}>
                {this.state.editor === null && !this.state.editorLoadingError &&
                    <div className={classNames("document-editor-frame height-100",
                        {"document-editor-popup": this.props.isPopup})}>
                        <span className="spinner spinner-default-blue loading"/>
                    </div>
                }
                {this.state.editorLoadingError &&
                    <div className={classNames("col-md-12 document-editor-frame height-100",
                        {"document-editor-popup": this.props.isPopup})}>
                        <EditorError translate={this.props.translate}/>
                    </div>
                }

                {(this.state.editor !== null) && !this.state.editorLoadingError &&
                    <iframe
                        id={"Document-Editor-Iframe"}
                        src={this.state.editor}
                        title={"Document Editor"}
                        onLoad={this.onLoadIframe}
                        {...this.props.externalApplicationName === "Visma Business Cloud" && {allow: "clipboard-read; clipboard-write"}}
                        className={
                            classNames("w-100 height-100 document-editor-frame",
                                {"document-editor-popup": this.props.isPopup})
                        }
                    >
                    </iframe>
                }
            </div>
        );
    }
}

const mapStateToProps = function (store) {
    return {
        userConfiguration: reduxSelectors.getUsersData(store)
    };
};

const connected = connect(mapStateToProps, {
    getTaskDetails,
    hasEditedDocument,
    updateDocumentKey,
    updateDocumentVersion,
    getTaskDocumentData,
    integrationIframeHasUnsavedChanges,
    iframeHasVBNXTFlag
})(DocumentEditor);

export default connected;

DocumentEditor.propTypes = {
    taskId: PropTypes.string, // json for one task
    translate: PropTypes.func, // to translate texts
};

const EditorError = (props) => {
    return (
        <div className="error-message align-center">
            <span className="pull-left vismaicon vismaicon-lg vismaicon-filled vismaicon-error"/>
            <span
                className="col-md-10 text-left margin-bottom"><b>{props.translate("popUp.documentEditor.errorTitle")}</b></span>
            <br/>
            <span className="col-md-10 text-left">{props.translate("popUp.documentEditor.errorSuggestion")}</span>
        </div>)
};