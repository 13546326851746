import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import withTranslations from '../translations/translations.wrapper.jsx';
import MenuAppList from './MenuAppList.component';
import MenuUserBox from './MenuUserBox.component';
import MenuHelpBox from './MenuHelp.component';
import LinkToFeedBack from './LinkToFeedBack.component';
import {connect} from 'react-redux';
import history from '../router/history.jsx';

import {
    getHelpCentreConfiguration,
    getUsersData,
    getUsersCurrentBusinessFeatures,
    getUsersLoggedInData,
    getUsersCurrentContextId,
    getUsersSettings,
    getUsersCurrentLanguage
} from '../store/application.reducers';

import {logoutUser, switchUserContext} from '../usercontext/user.action';
import classNames from 'classnames';
import {
    HOMEPAGE,
    APPROVAL_MENU_ITEMS,
    CONFIGURATION_URL_PART,
    OTHER_SETTINGS_URL_PART
} from "utils/constants";
import _ from 'lodash';
import {launchDarkly} from "utils/launchDarkly";

const MAX_SIZE_MENU_ICON = 1500;
const SMALL_USER_MENU_BREAKPOINT = 1300;


function findItemIndexInApprovalMenuItems(collection, item) {
    return _.findIndex(collection, x => {
        return x.url === item || (x.detailsView && item.indexOf(x.detailsView.url) !== -1);
    });
}


function findPathinApprovalMenuItems(userRoles, showDecisionTables) {
    let currentLocation = window.location.pathname;
    let futureLocation = currentLocation;
    let [parentPath, childPath] = currentLocation.replace('/', '').split("/");

    parentPath = "/" + parentPath;
    let parentIndex = findItemIndexInApprovalMenuItems(APPROVAL_MENU_ITEMS, parentPath);

    if (parentIndex === -1)
        return futureLocation;

    //user has rights to view the parent path - do not change the location unless the path is not accessible
    parentPath = APPROVAL_MENU_ITEMS[parentIndex].url;

    if (APPROVAL_MENU_ITEMS[parentIndex].rightsToView && !userRoles[APPROVAL_MENU_ITEMS[parentIndex].rightsToView]) {
        return "/";
    }

    //childPath = childPath ? "/" + childPath : "/";
    //let childIndex = findItemIndexInApprovalMenuItems(APPROVAL_MENU_ITEMS[parentIndex].children, childPath);
    // if (childIndex === -1)
    //     return futureLocation;


    if (childPath === "/decisionTables" && !showDecisionTables) {
        parentPath = CONFIGURATION_URL_PART;
        futureLocation = parentPath;
    }
    //this doesn't work if view Configuration is false but the child paths can be viewed
    // } else if (APPROVAL_MENU_ITEMS[parentIndex].children[childIndex].rightsToView && userRoles[APPROVAL_MENU_ITEMS[parentIndex].children[childIndex].rightsToView]) {
    //     return APPROVAL_MENU_ITEMS[parentIndex].url + APPROVAL_MENU_ITEMS[parentIndex].children[childIndex].url;
    // }


    const hasApproverGlobalRole = APPROVAL_MENU_ITEMS[parentIndex].globalRoles && APPROVAL_MENU_ITEMS[parentIndex].globalRoles.isApprover && userRoles.globalRoles && userRoles.globalRoles.isApprover;

    //no child url path - configuration is the only one with multiple conditions
    let isConfiguration = parentPath.indexOf(CONFIGURATION_URL_PART) !== -1;

    const hasRightsToViewMenuItem = !APPROVAL_MENU_ITEMS[parentIndex].rightsToView || (APPROVAL_MENU_ITEMS[parentIndex].rightsToView && userRoles[APPROVAL_MENU_ITEMS[parentIndex].rightsToView]);

    const hasRightsToView = isConfiguration ? canViewConfiguration(userRoles) : (hasRightsToViewMenuItem || hasApproverGlobalRole);

    if (!hasRightsToView) {
        //find first location with rights to view
        let nextIndex = _.findIndex(APPROVAL_MENU_ITEMS, (x => {
            return userRoles[x.rightsToView];
        }));

        nextIndex = nextIndex > 0 ? nextIndex : 0;
        futureLocation = APPROVAL_MENU_ITEMS[nextIndex].url;
    }
    return futureLocation;


}

export function canViewConfiguration(userRoles) {
    return userRoles.systemViewer || userRoles.systemAdministrator;
}


/**
 * process the menu definition
 *  - translates labels
 *  - marks active link
 *  - marks application 'homepage'
 * @param items
 * @param currentLocation
 * @param pathPrefix
 * @param translate
 */
function getMenuDefinition(items, context, pathPrefix = '', translate, userRoles, usersBusinessFeatures, child = false) {

    let menuItems = [];
    let currentLocation = window.location.pathname;

    items.forEach((menuItem) => {
        if (menuItem.url === OTHER_SETTINGS_URL_PART && !userRoles.globalRoles.isApprover) {
            return;
        }

        if (menuItem.url === CONFIGURATION_URL_PART) {
            if (!userRoles.systemViewer && !userRoles.systemAdministrator) {
                return;
            }
        }

        if (menuItem.url === "/decisionTables") {
            const showDecisionTables = launchDarkly.companyClient && launchDarkly.companyClient.variation("show-decision-tables", false);
            if (!showDecisionTables) {
                return;
            }
        }


        const hasApproverGlobalRole = menuItem.globalRoles && menuItem.globalRoles.isApprover && userRoles.globalRoles && userRoles.globalRoles.isApprover;

        if (!menuItem.rightsToView || (menuItem.rightsToView && userRoles[menuItem.rightsToView]) || hasApproverGlobalRole) {
            let wholePath = pathPrefix + menuItem.url;
            let index = menuItem.url === HOMEPAGE;
            let pathToSearch = currentLocation;
            if (menuItem.detailsView && currentLocation.indexOf(menuItem.detailsView.url) !== -1)
                pathToSearch = menuItem.url;
            //this is to seleted the default 2nd level meniu item
            if (menuItem.defaultView && currentLocation === menuItem.defaultView.url)
                pathToSearch = menuItem.url;

            let active = index ? menuItem.url === pathToSearch : pathToSearch.indexOf(menuItem.url) !== -1;
            const computed = {
                active: active,
                label: translate(menuItem.label),
                index: index,
                url: wholePath
            };

            // add children only for active link
            if (menuItem.children) {
                computed.children = getMenuDefinition(menuItem.children, context, pathPrefix + menuItem.url, translate, userRoles, usersBusinessFeatures, true);
            } else {
                computed.children = [];
            }

            menuItems.push(Object.assign({}, menuItem, computed));
        }
    });

    return menuItems;

}


// for midddle layout original ODP component uses tabDrop jquery plugin, to hide menu items one by one into dropdown
// OWR said we don't need to move items one by one, so to keep it simple, put everything aside from selected into
// dropdown
class TopMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // on mobile layout, we compact everything into 1 one huge dropdown
            mobileMenuExpanded: false,
            visibleMenuItems: 1
        };

        this.locationIsReachable = this.locationIsReachable.bind(this);
        this.onBlurAppMenu = this.onBlurAppMenu.bind(this);
        this.getNumberOfItemsToShow = this.getNumberOfItemsToShow.bind(this);
        this.setVisibleMenuItems = this.setVisibleMenuItems.bind(this);
      }

    componentDidMount() {
        window.addEventListener("resize", this.setVisibleMenuItems);

        // this will probably force new rerender, because we might recieve new props (because of the new layout), so we
        // have to move it here from constructor (otherwise react complains)

        this.setVisibleMenuItems();
        //set last to allow the observer to bind
        this.locationIsReachable();

    }

    componentDidUpdate(prevProps) {
        if (prevProps.userData.currentContext !== this.props.userData.currentContext) {
            this.locationIsReachable();
        }
    }

    locationIsReachable() {
        const showDecisionTables = launchDarkly.companyClient && launchDarkly.companyClient.variation("show-decision-tables", false);
        let accessibleLocation = findPathinApprovalMenuItems(this.props.userData.userRoles, showDecisionTables);
        if (accessibleLocation && accessibleLocation !== window.location.pathname) {
            history.push(accessibleLocation);
            history.go();
        }
    }


    onBlurAppMenu(e) {
        // delay the click a bit to allow links to work
        let self = this;
        let currentTarget = e.currentTarget;
        setTimeout(function () {
            if (!currentTarget.contains(document.activeElement)) {
                self.setState({mobileMenuExpanded: false})
            }
        }, 100);

    }

    getNumberOfItemsToShow(menu) {
        let width = window.innerWidth;
        //these are constants based on the size of the elemens - fixed by vud - if they change we need to update
        const applicationMenu = 300;
        let userMenu = width < 1500 ? 420 : 640;
        let shouldFit = width - userMenu - applicationMenu;

        let allMenuItems = menu.map(item => {
            return item.label
        });
        let maxToShow = allMenuItems.length;

        let countWidth = 0;
        for (let i = 0; i < maxToShow; i++) {
            let elemWidth = allMenuItems[i].length * 11;
            if (elemWidth + countWidth > shouldFit) {
                maxToShow = i < maxToShow ? i : maxToShow;
            }

            countWidth += elemWidth;
        }

        const activeItemIndex = _.findIndex(menu, (item) => {
            return item.active;
        });

        if ((this.state.visibleMenuItems - activeItemIndex) < 1) {
            maxToShow -= 1;
        }

        return maxToShow;
    }

    setVisibleMenuItems() {
        const menu = getMenuDefinition(APPROVAL_MENU_ITEMS, this.props.currentContextId, '', this.props.translate, this.props.userData.userRoles, this.props.usersBusinessFeatures);

        let maxToShow = this.getNumberOfItemsToShow(menu);
        this.setState({visibleMenuItems: maxToShow});
    }

    render() {
        const MENU_DEFINITION = getMenuDefinition(APPROVAL_MENU_ITEMS, this.props.currentContextId, '', this.props.translate, this.props.userData.userRoles, this.props.usersBusinessFeatures);

        const applicationMenu = [];
        let restOfMenuDropDown = [];
        let visibleMenuItems = this.getNumberOfItemsToShow(MENU_DEFINITION);

        MENU_DEFINITION.forEach((item, index) => {
            let menuItem = <TopLevelMenuItem item={item} key={index} context={this.props.currentContextId}/>;

            if (item.active) { // currently selected
                applicationMenu.push(menuItem);
            } else {
                if (visibleMenuItems > index) {
                    applicationMenu.push(menuItem);
                } else {
                    restOfMenuDropDown.push(menuItem);
                }
            }

        });
        // add dropdown into menu
        if (restOfMenuDropDown.length > 0) {
            applicationMenu.push(<DropdownForMenuItems menuItems={restOfMenuDropDown}
                                                       translate={this.props.translate}
                                                       key={applicationMenu.length + 10}/>);
        }

        const isSmallUserMenu = document.documentElement.clientWidth < SMALL_USER_MENU_BREAKPOINT;
        const topClass = classNames({
            "navbar navbar-default fixed-top": true,
            "close-navigation": this.state.mobileMenuExpanded && isSmallUserMenu,
            "collapsed-nav": isSmallUserMenu
        });

        const appMenuClass = classNames({
            "navbar-collapse": true,
            'in': this.state.mobileMenuExpanded && isSmallUserMenu
        });

        const appMenuProps = {
            className: appMenuClass,
            tabIndex: "-1"
        };
        if (isSmallUserMenu) {
            appMenuProps.onBlur = this.onBlurAppMenu;
        }

        return (
            <header id="navigation-top-main"
                    className={topClass}>

                <div className="navbar-header">
                    <MenuAppList/>
                </div>

                <nav {...appMenuProps} >
                    <ul className="nav navbar-nav nav-tabs first-level border-0" role="menubar" id="application-menu">
                        {applicationMenu}
                    </ul>
                    <ul className="nav navbar-nav nav-tabs navbar-right first-level context-selector flex-row border-0"
                        id="user-menu" role="menubar">
                        <LinkToFeedBack translate={this.props.translate}/>


                        <MenuHelpBox
                            rootUrl={this.props.helpCentreConfiguration.rootUrl}
                            widgetUrl={this.props.helpCentreConfiguration.widgetUrl}
                            userConfiguration={this.props.userData}
                            label={this.props.translate('menu.helpBox.label')}
                        />

                        <MenuUserBox
                            collapsed={document.body.clientWidth < MAX_SIZE_MENU_ICON}
                            currentContextId={this.props.currentContextId}
                            switchUserContext={this.props.switchUserContext}
                            loggedInData={this.props.loggedInData}
                            userData={this.props.userData}
                            logoutUser={this.props.logoutUser}
                            translate={this.props.translate}/>
                    </ul>
                </nav>
            </header>
        );
    }

}

class DropdownForMenuItems extends Component {

    static propTypes = {
        menuItems: PropTypes.array
    };

    constructor(props) {
        super(props);
        this.state = {open: false};
        this.onBlurDropDown = this.onBlurDropDown.bind(this);
    }

    onBlurDropDown() {
        // delay the click a bit to allow links to work
        let self = this;
        setTimeout(function () {
            self.setState({open: false})
        }, 100);

    }


    render() {

        const liProps = {
            className: "menudrop dropdown",
            onBlur: this.onBlurDropDown,
            style: {display: 'block'},
            role: "button"
        };

        if (this.state.open) {
            liProps.className += " open";

        }

        return (
            <li {...liProps} tabIndex={0} title={this.props.translate("menu.more")}>
                <a className="dropdown-toggle no-caret d-block" data-toggle="dropdown"
                   onClick={() => this.setState({open: !this.state.open})}
                   href="#"
                   aria-expanded={this.state.open}>
                    <i className="icon-align-justify"/>
                </a>
                <ul id="menuDropContent" className="dropdown-menu dropdown-menu-right">
                    {this.props.menuItems}
                </ul>
            </li>
        );
    }
}


const TopLevelMenuItem = ({item, classSent, context}) => {
    let secondLevelList = null;
    // ugly, but odp component has multiple components on the same level, which we cannot render unconditionally
    const secondLevel = item.children.length !== 0;
    if (secondLevel) {
        secondLevelList = (
            <ul className="second-level">
                {item.children.map((subItem, subIndex) => {
                    return <SecondLevelMenuItem item={subItem} key={subIndex} context={context}/>;
                })}
            </ul>
        );
    }

    let liClass = classNames({
        'active': item.active,
        // 'second-level-children': secondLevel
    });

    return (
        <li className={liClass + " menu-list-element"}>
            <MenuLink item={item} withSecondLevel={secondLevel} context={context}/>
            {secondLevelList}
        </li>
    );
};

const SecondLevelMenuItem = ({item}) => {

    let liClass = classNames({
        'active': item.active
    });

    return (
        <li className={liClass}>
            <MenuLink item={item}/>
        </li>
    );
};

const MenuLink = ({item, withSecondLevel, context}) => {
    let liClass = "nav-item fl-menu-item no-children " + (item.active ? 'active' : '');

    return <Link to={item.url} className={liClass}>
        {item.label}
        {withSecondLevel}
    </Link>

};


const localized = withTranslations(TopMenu);

const mapStateToProps = function (store) {
    return {
        userData: getUsersData(store),
        usersBusinessFeatures: getUsersCurrentBusinessFeatures(store),
        userSettings: getUsersSettings(store),
        helpCentreConfiguration: getHelpCentreConfiguration(store),
        loggedInData: getUsersLoggedInData(store),
        currentContextId: getUsersCurrentContextId(store),
        userLanguage: getUsersCurrentLanguage(store),
    };
};

export default connect(mapStateToProps, {
    logoutUser,
    switchUserContext
})(localized)