import * as Api from 'utils/api/api';
import {
    PROCESS_OVERVIEW_LOADED,
    PROCESS_OVERVIEW_LOADING,
    PROCESS_OVERVIEW_PROCESS_TYPE_CHANGED,
    LOAD_MORE_PROCESS_OVERVIEW,
    PROCESS_OVERVIEW_FILTER_CHANGED,
    PROCESS_OVERVIEW_COST_UNITS_LOADED,
    PROCESS_ADVANCED_SEARCH_OPEN,
    PROCESS_PREVENT_DATA_RELOAD,
    PROCESS_SAVE_SCROLL_POSITION
} from '../store/actionTypes';

import {TASK_OVERVIEW_FILTERS, HISTORY_FILTERS} from 'utils/constants';
import {handleError} from 'utils/errorHandle.function';
import {returnCostUnits} from "components/myhistory/getCostUnits.function";

const FIRST_PAGE = 1;
const NUMBER_OF_ROWS = 40;
let callObject = {
    page: FIRST_PAGE,
    rows: NUMBER_OF_ROWS,
    asstype: "all"
};

export function processOverviewLoading() {
    return {
        type: PROCESS_OVERVIEW_LOADING,
        data: null
    };
}

export function processOverviewLoaded(counters = {}) {
    return {
        type: PROCESS_OVERVIEW_LOADED,
        data: counters
    };
}

export function processTypeChanged(filter) {
    return {
        type: PROCESS_OVERVIEW_PROCESS_TYPE_CHANGED,
        processType: filter
    };
}

export function filterChanged(filter) {
    return {
        type: PROCESS_OVERVIEW_FILTER_CHANGED,
        filter: filter
    };
}

export function moreOverviewLoaded(tasks = []) {
    return {
        type: LOAD_MORE_PROCESS_OVERVIEW,
        data: tasks
    };
}

export function toggleAdvancedSearch(isOpen) {
    return {
        type: PROCESS_ADVANCED_SEARCH_OPEN,
        advancedSearchOpen: isOpen
    };
}

export function costUnitsLoaded(costUnits = []) {
    return {
        type: PROCESS_OVERVIEW_COST_UNITS_LOADED,
        data: costUnits
    };
}

export function preventProcessReload(isStopDataReload) {
    return {
        type: PROCESS_PREVENT_DATA_RELOAD,
        data: isStopDataReload
    };
}

export function saveProcessScrollPosition(topScroll) {
    return {
        type: PROCESS_SAVE_SCROLL_POSITION,
        data: topScroll
    };
}

export function getOverviewCounters(filters, counter) {
    return function (dispatch) {
        dispatch(processOverviewLoading());
        dispatch(saveProcessScrollPosition(0));

        let value = {};
        let filter = prepareFilters(filters);
        let tocall = Object.assign({}, callObject, filter);

        let getOverview = Api.getProcessOverview(tocall, counter).then(response => {
                value.tasks = response;
            },
            error => {
                handleError(error);
            });

        let getAssignmentType = Api.getAssignmentType().then(response => {
                value.userRoles = response;
            },
            error => {
                handleError(error);
            });

        return Promise.all([getOverview, getAssignmentType]).then(
            () => {
                return dispatch(processOverviewLoaded(value));
            }
        );
    }
}

export function getCostUnits() {
    return function (dispatch) {
        dispatch(returnCostUnits(costUnitsLoaded));
    }
}

export function loadMoreOverview(callObject, counter) {
    return function (dispatch) {
        let filter = prepareFilters(callObject.filters);
        let tocall = Object.assign({}, callObject, filter);
        Api.getProcessOverview(tocall, counter).then(
            response => {
                dispatch(moreOverviewLoaded(response));
            },
            error => {
                handleError(error);
            });
    }
}

function prepareFilters(callObject) {
    let currentFilter = Object.assign({}, callObject);
    let filter = {};

    if (currentFilter) {
        Object.keys(currentFilter).forEach(key => {
            switch (key) {
                case HISTORY_FILTERS.ACCOUNT:
                case HISTORY_FILTERS.COST_UNITS: {
                    filter[key] = JSON.stringify(currentFilter[key]);
                    break;
                }
                case TASK_OVERVIEW_FILTERS.COLUMNS: {
                    filter = Object.assign({}, filter, currentFilter[key]);
                    break;
                }
                case TASK_OVERVIEW_FILTERS.SORTING: {
                    filter[TASK_OVERVIEW_FILTERS.SORT_DIRECTION] = currentFilter[key].direction;
                    filter[TASK_OVERVIEW_FILTERS.SORT_INDEX] = currentFilter[key].index;
                    break;
                }
                default: {
                    filter[key] = currentFilter[key];
                    break;
                }
            }
        });
    }
    return filter;
}