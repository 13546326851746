import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {DETAILS_VIEW_TYPE} from 'utils/constants';

import AsyncTaskActionExecutor from '../mytasks/AsyncTaskActionExecutor.component';
import AsyncTaskActionExecutorDocument from '../documentoverview/AsyncTaskActionExecutor.component';
import AsyncTaskActionExecutorProcess from '../processoverview/AsyncTaskActionExecutor.component';
import AsyncTaskActionExecutorHistory from '../myhistory/AsyncTaskActionExecutor.component';
import { launchDarkly } from "utils/launchDarkly";
import SSEAsyncTaskActionExecutorProcess from "components/processoverview/SSEAsyncTaskActionExecutor.component";
/**
 * this is used in TaskDetails
 * routes to the proper AsyncTaskActionExecutor for each Details View Type
 */
export class AsyncTaskActionExecutorTask extends Component {

    constructor(props) {
        super(props);
        this.loadSystemOverviewSSE = launchDarkly.userClient && launchDarkly.userClient.variation("fe-load-system-overview-with-sse", false);
    }

    static propTypes = {
        actionParameters: PropTypes.object,
        tasks: PropTypes.object,
        translate: PropTypes.func,
        actionFinished: PropTypes.func,
        reloadTasks: PropTypes.func
    };

    render() {
        switch (this.props.type) {
            case DETAILS_VIEW_TYPE.DOCUMENT:
                return (<AsyncTaskActionExecutorDocument {...this.props}/>);
            case DETAILS_VIEW_TYPE.HISTORY_TASK:
                return (<AsyncTaskActionExecutorHistory {...this.props}/>);
            case DETAILS_VIEW_TYPE.PROCESS:
                return (this.loadSystemOverviewSSE ? (<SSEAsyncTaskActionExecutorProcess {...this.props}/>) : (<AsyncTaskActionExecutorProcess {...this.props}/>));
            default:
                return (<AsyncTaskActionExecutor {...this.props}/>);
        }
    }
}

export default AsyncTaskActionExecutorTask;