import React from "react";

export const AlternativePageView = ({pdf, zoom, index, key, style}: any): React.ReactElement => {
    if (!pdf || index < 0) return null;

    const thisCanvas = React.createRef<HTMLCanvasElement>();
    const loadingImageRef = React.createRef<HTMLImageElement>();

    pdf.getPage(index + 1).then(function (page) {
        let viewport = page.getViewport({scale: zoom / 80});

        if (thisCanvas.current) {
            thisCanvas.current.height = viewport.height;
            thisCanvas.current.width = viewport.width;

            // Render PDF page into canvas context
            const renderContext = {
                canvasContext: thisCanvas.current.getContext('2d'),
                viewport: viewport
            };
            const renderTask = page.render(renderContext);

            renderTask.promise.then(function () {
                loadingImageRef.current?.classList.add("hidden");
            });
        }

    });

    return <div key={key} style={style} className={"d-grid justify-content-center"}>
        <canvas id={"canvas_" + (index + 1)} ref={thisCanvas} className={"mt-16 d-block"}/>
        <span ref={loadingImageRef} className={"spinner spinner-default-blue loading mt-128 float-left"}/>
    </div>

};

export default AlternativePageView;