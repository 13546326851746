import {
    ADD_RULE_CONFIGURATION,
    REMOVE_RULE_CONFIGURATION,
    UPDATE_RULE_CONFIGURATION,
    WORKFLOWS_LOADED,
    WORKFLOWS_LOADING,
    WORKFLOWS_AMOUNT_LIMITS_LOADED,
    WORKFLOWS_HISTORY_LOADED,
    WORKFLOWS_HISTORY_LOADING,
    CLEAR_WORKFLOWS_HISTORY,
    MORE_WORKFLOWS_HISTORY_LOADED, WORKFLOWS_PREVENT_ACTION,
} from "../store/actionTypes";

import * as Api from '../../utils/api/api';
import {handleError} from "utils/errorHandle.function";
import { NOTIFICATION_TYPE, showNotification, showNotificationWithAction } from "../notifications/notification.action";
import {translate} from '../translations/translations.wrapper.jsx';

export function workflowsLoaded(workflows = []) {
    return {
        type: WORKFLOWS_LOADED,
        data: workflows
    };
}

export function workflowsPreventAction(prevent) {
    return {
        type: WORKFLOWS_PREVENT_ACTION,
        data: prevent
    }
}


export function workflowsAmountLimitsLoaded(limits) {
    return {
        type: WORKFLOWS_AMOUNT_LIMITS_LOADED,
        data: limits
    };
}

export function workflowsLoading() {
    return {
        type: WORKFLOWS_LOADING,
        data: null
    };
}

export function addRule(rule) {
    return {
        type: ADD_RULE_CONFIGURATION,
        data: rule
    }
}

export function removeRule(id) {
    return {
        type: REMOVE_RULE_CONFIGURATION,
        data: id
    }
}

export function updateRule(rule) {
    return {
        type: UPDATE_RULE_CONFIGURATION,
        data: rule
    }
}

export function workflowsHistoryLoading() {
    return {
        type: WORKFLOWS_HISTORY_LOADING
    }
}

export function workflowsHistoryLoaded(data) {
    return {
        type: WORKFLOWS_HISTORY_LOADED,
        data: data
    }
}

export function moreWorkflowsHistoryLoaded(data) {
    return {
        type: MORE_WORKFLOWS_HISTORY_LOADED,
        data: data
    }
}
export function clearWorkflowsHistory() {
    return {
        type: CLEAR_WORKFLOWS_HISTORY
    }
}


export function getWorkflows(skipLoad = false, cancelPreventAction = false) {
    return function (dispatch) {
        if (!skipLoad)
            dispatch(workflowsLoading());
        dispatch(getWorkflowsAmountLimits());
        let value = {};

        let getWorkflows = Api.getRuleConfiguration().then(response => {
            value.workflows = response;
        }, error => {
            handleError(error);
            if(cancelPreventAction)
                dispatch(workflowsPreventAction(false));
        });

        let getDocTypes = Api.getDocumentTypesForWorkflowConfiguration().then(response => {
            value.docTypes = response;
        }, error => {
            handleError(error);
            if(cancelPreventAction)
                dispatch(workflowsPreventAction(false));
        });

        Promise.all([getWorkflows, getDocTypes]).then(
            () => {
                dispatch(workflowsLoaded(value));
                if(cancelPreventAction)
                    dispatch(workflowsPreventAction(false));
            }
        );
    }
}


export function getWorkflowsAmountLimits() {
    return function (dispatch) {

        Api.getWorkflowsAmountLimits().then(repsonse => {
            dispatch(workflowsAmountLimitsLoaded(repsonse));
        }, error => {
            handleError(error);
        });

    }
}

export function getCopiedRule(id) {
    return function (dispatch) {
        Api.getCopiedRuleConfiguration(id).then(response => {
            dispatch(addRule(response));
        }, error => {
            handleError(error);
        });
    }
}

export function activateRule(rule, isActive, errorCallback) {
    return function (dispatch, getState) {
        const state = getState();
        dispatch(workflowsPreventAction(true));
        Api.activateRule(rule, isActive).then(response => {
            dispatch(getWorkflows(true, true));
            if (isActive)
                dispatch(showNotification(translate(state, "workflows.workflowActivated")));
        }, error => {
            errorCallback();
            handleError(error);
            dispatch(workflowsPreventAction(false));
            // dispatch(showNotification(translate(state, "workflows.workflowActivatedError"), undefined, NOTIFICATION_TYPE.NEGATIVE_PERMANENT));
        });
    }
}

export function activateReadonlyRule(rule, errorCallback) {
    return function (dispatch, getState) {
        const state = getState();
        dispatch(workflowsPreventAction(true));
        Api.activateReadonlyRule(rule.idRuleConfiguration).then(response => {
            dispatch(getWorkflows(true, true));
            dispatch(showNotification(translate(state, "workflows.workflowActivated")));
        }, error => {
            errorCallback();
            handleError(error);
            dispatch(workflowsPreventAction(false));
        });
    }
}

export function deactivateReadonlyRule(rule, errorCallback) {
    return function (dispatch) {
        dispatch(workflowsPreventAction(true));
        Api.deactivateReadonlyRule(rule.idRuleConfiguration).then(response => {
            dispatch(getWorkflows(true, true));
        }, error => {
            errorCallback();
            handleError(error);
            dispatch(workflowsPreventAction(false));
        });
    }
}

export function addNewRule(name, docType) {
    return function (dispatch) {
        Api.addRule(name, docType).then(response => {
            dispatch(addRule(response));
        }, error => {
            handleError(error);
        });
    }
}

export function copyRule(id) {
    return function (dispatch, getState) {
        const state = getState();

        Api.copyRule(id).then(response => {
            dispatch(getCopiedRule(response));
            dispatch(showNotification(translate(state, "workflows.duplicateSuccessful")));
        }, error => {
            handleError(error);
            //dispatch(showNotification(translate(state, "workflows.duplicateError"), undefined, NOTIFICATION_TYPE.NEGATIVE_PERMANENT));
        });
    }
}

export function copyRuleWithName(rule, successToastCallback, successCallback) {
    return function (dispatch, getState) {
        const state = getState();

        Api.copyRuleWithName(rule).then(response => {
            dispatch(addRule(response));
            dispatch(showNotificationWithAction(translate(state, "workflows.duplicateSuccessful"), {
                info: translate(state, "workflows.viewWorkflow"),
                callback: () => successToastCallback(response.idRuleConfiguration)
            }));
            if(successCallback){
                successCallback();
            }
        }, error => {
            handleError(error);
        });
    }
}

export function deleteRule(id, comment, successCallback) {
    return function (dispatch, getState) {
        const state = getState();

        Api.deleteRule(id, comment).then(response => {
            dispatch(getWorkflows(true));
            dispatch(showNotification(translate(state, "workflows.ruleDeleted")));
            if(successCallback){
                successCallback();
            }
        }, error => {
            //TODO there are some problems with the delete response
            //discuss with Alban
            //dispatch(removeRule(id));
            dispatch(getWorkflows(true));
            dispatch(showNotification(translate(state, "workflows.deleteError"), undefined, NOTIFICATION_TYPE.NEGATIVE_PERMANENT));
        });
    }
}

export function getWorkflowsHistory(docType) {
    let data = {
        page: 1,
        rows: 20
    };
    if (docType)
        data.documentType = docType;
    return function (dispatch) {
        dispatch(workflowsHistoryLoading());
        Api.getWorkflowHistory(data).then(response => {
            dispatch(workflowsHistoryLoaded(response));
        }, error => {
            handleError(error);
        });
    }
}

export function loadMoreWorkflowsHistory(data) {
    return function (dispatch) {
        Api.getWorkflowHistory(data).then(response => {
            dispatch(moreWorkflowsHistoryLoaded(response));
        }, error => {
            handleError(error);
        });
    }
}

export function getRuleHistory(id) {
    return function (dispatch) {
        Api.getRuleConfigurationHistory(id).then(response => {
            dispatch(workflowsHistoryLoaded(response));
        }, error => {
            handleError(error);
        });
    }
}