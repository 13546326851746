import ApprovalError from './ApprovalError.class.js';
import store from 'components/store/Store.component';
import * as log from '../logger.function';
import BluebirdPromise from 'bluebird';
import {getAjaxTracker, pushAjaxTracker} from "../appDynamics.function";
import {navigateToErrorPage} from "components/router/router.function.js";
import {showErrorMessage} from 'components/usercontext/user.action.js';
import * as OdpApi from './odp.api';
import MIMEType from "whatwg-mimetype";

BluebirdPromise.config({
    // Enable warnings
    warnings: true,
    // Enable long stack traces
    longStackTraces: true,
    // Enable cancellation
    cancellation: true,
    // Enable monitoring
    monitoring: true
});

// init: https://developer.mozilla.org/en-US/docs/Web/API/GlobalFetch/fetch
/**
 * @type {RequestInit}
 */
export const SHARED_PARAMETERS = {
    credentials: 'include',
    cache: 'default',
    // mode: The mode you want to use for the request, e.g., cors, no-cors, or same-origin.
    mode: 'cors',
    method: "GET"
};

export const CSRF_HTTP_HEADER = 'X-CSRF-Token';
export const SESSION_CHECK_HTTP_HEADER = 'X-Session-Hash';
export const CONTEXT_ID_HEADER = "Visma-ContextId";


/**
 * Enum for content types
 * @readonly
 * @enum {string}
 */
const DATA_TYPES = {
    JSON: "application/json;charset=UTF-8",
    FORM: "application/x-www-form-urlencoded;charset=UTF-8"
}

/**
 * A fetch request parameter
 * @typedef {Object} FetchParameter
 * @property {string} method - HTTP method
 * @property {string} credentials - fixed value 'include'
 * @property {string} cache - fixed value 'default'
 * @property {string} mode - cors, no-cors, or same-origin
 * @property {Object.<string, string>} headers - HTTP headers
 * @property {string=} body - HTTP body
 * @property {AbortSignal=} signal - abort controller signal
 */


/**
 * Request builder
 */
export class RequestBuilder {
    /**
     * @type {RequestInit}
     */
    #parameters;
    /**
     * @type {string}
     */
    #url;

    /**
     * @type {null|string}
     */
    #vPageView = null;

    /**
     * @type {boolean}
     */
    #wrapForEtag = false
    /**
     * @type {null|Object.<string, string>}
     */
    #queryStringParameters = null;

    /**
     *
     * @type {AbortSignal=}
     */
    #signal = undefined;

    /**
     * @param {string} url
     */
    constructor(url) {
        this.#url = url;
        this.#parameters = Object.assign({}, SHARED_PARAMETERS);
        this.#parameters.headers = {};
        this.header("X-Requested-With", "XMLHttpRequest");
        if (window.csrfToken != null) {
            this.header(CSRF_HTTP_HEADER, window.csrfToken);
        }
        if (window.sessionHash != null) {
            this.header(SESSION_CHECK_HTTP_HEADER, window.sessionHash);
        }
        if (window.currentContext) {
            this.header(CONTEXT_ID_HEADER, window.currentContext);
        }
    }

    /**
     * @param {string} path
     */
    path(path) {
        this.#url += "/" + encodeURIComponent(path);
        return this;
    }

    /**
     *
     * @param {AbortSignal} signal
     * @returns {RequestBuilder}
     */
    signal(signal) {
        this.#signal = signal;
        return this;
    }

    /**
     *
     * @param {DATA_TYPES} dataType - content-type in the request
     */
    dataType(dataType) {
        return this.header("Content-Type", dataType);
    }

    /**
     *
     * @param {DATA_TYPES} dataType - requested content-type of the response
     */
    accept(dataType) {
        return this.header("Accept", dataType);
    }

    /**
     *
     * @param {string} name - parameter name. will be url encoded
     * @param {string} value - parameter value. will be url encoded
     * @returns {RequestBuilder}
     */
    queryParam(name, value) {
        if (this.#queryStringParameters == null) {
            this.#queryStringParameters = {};
        }
        this.#queryStringParameters[name] = value;
        return this;
    }

    /**
     *
     * @param {Object.<string,string>} object
     * @returns {RequestBuilder}
     */
    queryParams(object) {
        for (let key in object) {
            if (this.#queryStringParameters == null) {
                this.#queryStringParameters = {};
            }
            this.#queryStringParameters[key] = object[key];
        }
        return this;
    }

    /**
     * @param {any} data - data to be serialized to JSON. Automatically sets Content-Type header to application/json
     */
    jsonData(data) {
        this.dataType(DATA_TYPES.JSON);
        this.#parameters.body = JSON.stringify(data);
        return this;
    }

    /**
     * @param {any} data - data to be serialized to URL encoded form data. Automatically sets Content-Type header to application/x-www-form-urlencoded
     */
    formData(data) {
        this.dataType(DATA_TYPES.FORM);
        let result = '';
        for (let key in data) {
            let val = data[key];
            if (val == null) {
                continue;
            }
            if (result.length > 0) {
                result += "&"
            }
            result += encodeURIComponent(key) + "=" + encodeURIComponent(val);
        }
        this.#parameters.body = result;
        return this;
    }

    /**
     * @param {FormData} data
     */
    multipartData(data) {
        if (window.csrfToken) {
            data.append("X-CSRF-Token", window.csrfToken);
        }
        this.#parameters.body = data;
        return this;
    }

    /**
     * @param {string} name - header name
     * @param {string} value - header value
     */
    header(name, value) {
        this.#parameters.headers[name.toLowerCase()] = value;
        return this;
    }

    /**
     * @param {string} value - Entity version, for optimistic locking. X-Versions is the old header, If-Match is the new one
     */
    xVersion(value) {
        return this.header("X-Version", value)
                   .header("If-Match", value);
    }

    /**
     * @param {string|null} value
     */
    vPageView(value) {
        this.#vPageView = value;
        return this;
    }

    /**
     * @returns {BluebirdPromise<any>}
     * @param {string} method
     */
    #build(method = "GET") {
        this.#parameters.method = method;
        if (this.#queryStringParameters != null) {
            this.#url += objectToQueryString(this.#queryStringParameters);
        }
        return new RequestHandler(this.#url, this.#parameters, this.#vPageView, this.#wrapForEtag).execute();
    }

    /**
     * @returns {Promise<any>}
     * @param {string} method
     */
    async #buildAsync(method = "GET") {
        this.#parameters.method = method;
        if (this.#queryStringParameters != null) {
            this.#url += objectToQueryString(this.#queryStringParameters);
        }
        return new RequestHandler(this.#url, this.#parameters, this.#vPageView, this.#wrapForEtag, this.#signal).executeAsync();
    }

    /**
     * @param {boolean} value
     */
    wrapForEtag(value) {
        this.#wrapForEtag = value;
        return this;
    }

    /**
     *
     * @returns {BluebirdPromise<any>}
     */
    get() {
        if (this.#signal != null) {
            return this.#buildAsync();
        } else {
            return this.#build();
        }
    }

    /**
     *
     * @returns {BluebirdPromise<any>}
     */
    delete() {
        return this.#build("DELETE");
    }

    /**
     *
     * @returns {BluebirdPromise<any>}
     */
    post() {
        return this.#build("POST");
    }

    /**
     *
     * @returns {BluebirdPromise<any>}
     */
    put() {
        return this.#build("PUT");
    }
}

/**
 * @param {string} url
 */
export function _delete(url, xversion = null) {
    let requestBuilder = new RequestBuilder(url);
    if (xversion != null) {
        requestBuilder.xVersion(xversion);
    }
    return requestBuilder.delete();
}

/**
 *
 * @param {string} url
 * @param {null|Object.<string, string>}header
 * @param {null|string} xversion
 * @returns {BluebirdPromise<*>}
 */
export function _deleteWithJsonHeaders(url, header = {}, xversion = null) {
    let requestBuilder = new RequestBuilder(url);
    if (xversion != null) {
        requestBuilder.xVersion(xversion);
    }
    for (let key in header) {
        requestBuilder.header(key, header[key]);
    }
    return requestBuilder.delete();
}

export function putJson(url, data = {}) {
    return new RequestBuilder(url)
        .jsonData(data)
        .put();
}

/**
 *
 * @param {string} url
 * @param {null|Object.<string, string>}header
 * @param {any} data
 * @returns {BluebirdPromise<*>}
 * @private
 */
export function putWithJsonInHeaders(url, header = {}, data = {}) {
    let requestBuilder = new RequestBuilder(url);
    for (let key in header) {
        requestBuilder.header(key, header[key]);
    }
    return requestBuilder
        .jsonData(data)
        .put();
}

/**
 * executes HTTP GET call
 * @param {string} url
 * @param {string|null}  vPageView optional virtual page to attach to
 * @returns {BluebirdPromise<*>}
 * */
export function get(url, vPageView = null, wrapForEtag = false) {
    return new RequestBuilder(url)
        .vPageView(vPageView)
        .wrapForEtag(wrapForEtag)
        .get();
}

/**
 * executes HTTP GET call
 * @param {string} url
 * @param {boolean} wrapForEtag
 * @returns {BluebirdPromise<*>}
 * */
export function getWithJsonInHeaders(url, header = {}, wrapForEtag = false) {
    let requestBuilder = new RequestBuilder(url)
    for (let key in header) {
        requestBuilder.header(key, header[key]);
    }
    return requestBuilder
        .wrapForEtag(wrapForEtag)
        .get();
}

/**
 * converts object to query string
 * @param obj
 * @returns {string}
 */

export function objectToQueryString(obj) {
    return Object.keys(obj)
        .filter(value => value != null)
        .reduce(function (str, key, i) {
            let delimiter, val;
            delimiter = (i === 0) ? '?' : '&';
            key = encodeURIComponent(key);
            val = encodeURIComponent(obj[key]);
            return [str, delimiter, key, '=', val].join('');
        }, '');
}

/**
 * executes HTTP POST call
 * @param url
 * @param formData data to be posted
 * @return promise, failed in case of error response (including #401 unauthorized)
 */
export function postJson(url, data = {}, xversion = null) {
    let requestBuilder = new RequestBuilder(url)
    if (xversion != null) {
        requestBuilder.xVersion(xversion);
    }
    return requestBuilder
        .jsonData(data)
        .post();

}


export function postWithJsonInHeaders(url, data = {}, header = {}, xversion = null) {
    let requestBuilder = new RequestBuilder(url)
    for (let key in header) {
        requestBuilder.header(key, header[key]);
    }
    if (xversion != null) {
        requestBuilder.xVersion(xversion);
    }
    return requestBuilder
        .jsonData(data)
        .post();
}

/**
 * executes HTTP POST call
 * @param url
 * @param formData data to be posted .. top level keys will be used as variable names, their values will be treated as strings to be sent
 * @return promise, failed in case of error response (including #401 unauthorized)
 */
export function postForm(url, formData = {}, header = {}, xversion = null) {
    let requestBuilder = new RequestBuilder(url);
    for (let key in header) {
        requestBuilder.header(key, header[key]);
    }
    if (xversion != null) {
        requestBuilder.xVersion(xversion);
    }
    return requestBuilder.formData(formData)
        .post();
}

class RequestHandler {
    /**
     * @type {RequestInit}
     */
    #parameters;
    /**
     * @type {string}
     */
    #url;

    /**
     * @type {null|string}
     */
    #vPageView = null;

    /**
     * @type {boolean}
     */
    #wrapForEtag = false

    /**
     * @type {any}
     */
    #ajaxTracker;

    /**
     * @type {AbortController}
     */
    #abortController;

    /**
     *
     * @param {string} url
     * @param {RequestInit} parameters
     * @param {null|string} vPageView
     * @param {boolean} wrapForEtag
     * @param {AbortSignal=} abortSignal
     */
    constructor(url, parameters, vPageView, wrapForEtag, abortSignal) {
        this.#parameters = parameters;
        this.#url = url;
        this.#vPageView = vPageView;
        this.#wrapForEtag = wrapForEtag;
        this.#ajaxTracker = getAjaxTracker(url, parameters.method, vPageView);
        if (abortSignal != null) {
            this.#parameters.signal = abortSignal;
        } else {
            this.#abortController = new AbortController();
            this.#parameters.signal = this.#abortController.signal;
        }
    }

    async executeAsync() {
        const self = this;
        let fetchResponse;
        try {
            fetchResponse = await fetch(self.#url, self.#parameters);
        } catch (error) {
            if (error instanceof DOMException && error.name === "AbortError") {
                throw error;
            } else {
                pushAjaxTracker(self.#ajaxTracker);
                log.error('network error', self.#url, self.#parameters, error);
                throw error;
            }
        }
        if (fetchResponse.ok) {
            return await self.handleFetchResponse(fetchResponse);
        } else {
            return await self.handleFetchError(fetchResponse);
        }
    }

    execute() {
        const self = this;
        return new BluebirdPromise((resolve, reject, onCancel) => {
            fetch(self.#url, self.#parameters)
                .then(fetchResponse => {
                    // fetch handles errors differently from jquery: https://www.tjvantoll.com/2015/09/13/fetch-and-errors/
                    if (fetchResponse.ok) {
                        self.handleFetchResponse(fetchResponse)
                            .then(resolve)
                            .catch(reject);
                    } else {
                        self.handleFetchError(fetchResponse)
                            .then(resolve)
                            .catch(reject);
                    }
                })
                .catch(error => {
                    if (error instanceof DOMException && error.name === "AbortError") {
                        // do nothing??
                    } else {
                        pushAjaxTracker(self.#ajaxTracker);
                        log.error('network error', self.#url, self.#parameters, error);
                        reject(error);
                    }
                });
            onCancel(() => {
                self.#abortController.abort();
            });
        });
    }

    /**
     * @return {Promise<any>}
     */
    async handleFetchResponse(response) {
        const contentType = MIMEType.parse(response.headers.get('content-type'));
        //no content response - the response.json throws a warning when the response has no content
        if (response.status === 204 || contentType == null) {
            /*
             TODO: it would be better to return null to indicate that the response is empty, but we need to
              assess the impact on existing code
            */
            return true;
        }

        if (contentType.type === "text") {
            return await response.text();
        }
        if (contentType.essence === "application/json") {
            const result = await response.json();
            if (this.#wrapForEtag) {
                let responseWithETag = {
                    result: result,
                    etag: response.headers.has('etag') ? response.headers.get('etag') : null
                }
                return responseWithETag;
            }
            return result;
        }
        throw new Error("Unsupported content type: " + contentType.toString());
    }

    /**
     * @return {Promise<void>}
     */
    async handleFetchError(response) {
        // specific handling of not-authorized http header
        if (response.status === 401) {
            //check if user is logged in - if there is no token saved - navigate directly to login
            if (window.csrfToken == null && window.sessionHash == null) {
                OdpApi.login();
            } else {
                store.dispatch(showErrorMessage({shouldRedirect: true}));
            }
            // return rejected promise without trying to parse response
        } else if (response.status === 403 && response.headers?.get('x-csrf-status') === 'Failed') {
            store.dispatch(showErrorMessage({shouldRedirect: true}));
        } else if (response.status === 403 && response.headers?.get('x-session-status') === 'Failed') {
            store.dispatch(showErrorMessage({shouldRedirect: true}));
        } else if (response.status === 503) {
            if (response.headers.has("Retry-After")) {
                store.dispatch(showErrorMessage({
                    applyTranslations: true,
                    statusText: "popUp.error.cannotComplete",
                    shouldRedirect: false
                }));
            } else {
                navigateToErrorPage();
            }
        } else {
            const contentType = MIMEType.parse(response.headers.get('content-type'));
            // backend returns error details in the response, so extract them
            if (contentType.type === "text") {
                throw await response.text();
            }
            throw await response.json();
        }
        throw new ApprovalError(response.statusText, response.status);
    }

}
