import React from "react";
import translate from "components/translations/translations.wrapper";
import SpacePlanetPictogram from "components/taskdetails-remake/icons/pictogram-space-planet.svg";

export const Unknown = ({translate}: any): React.ReactElement => {
    return <div className={"attachment-image-container unknown h-100 px-64 pb-96"}>

        <img src={SpacePlanetPictogram} alt={"planet"}/>
        <div>{translate("taskDetail.panels.attachments.noFile")}</div>
        <span>{translate("taskDetail.panels.attachments.noFileMore")}</span>

    </div>
}

export default translate(Unknown);