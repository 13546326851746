import React from "react";
import maximizePanelDisabled from "components/taskdetails-remake/icons/maximize-disabled_dark.svg";
import maximizePanelImg from "components/taskdetails-remake/icons/maximize_dark.svg";
import minimizePanelDisabled from "components/taskdetails-remake/icons/minimize-disabled_dark.svg";
import minimizePanelImg from "components/taskdetails-remake/icons/minimize_dark.svg";
import translate from "components/translations/translations.wrapper";


interface MinMaxGroupProps {
    translate: React.FC;
    isAttachmentPanelMaxWidth: boolean;
    isAttachmentPanelWidthDefault: boolean;
    maximizePanel: () => void;
    minimizePanel: () => void;
}


export const MinMaxGroup = ({
                                translate,
                                isAttachmentPanelMaxWidth,
                                isAttachmentPanelWidthDefault,
                                maximizePanel,
                                minimizePanel
                            }: MinMaxGroupProps): React.ReactElement => {


    return <div className="min-max-btn-group">
        <button className="action_btn cursor-pointer m-0 pe-0"
                title={translate("taskDetail.maximize")?.toString()}
                type="button">
            <img
                className={`dark details__action-button${isAttachmentPanelMaxWidth ? ' disabled' : ''}`}
                src={isAttachmentPanelMaxWidth ? maximizePanelDisabled : maximizePanelImg}
                alt={translate("taskDetail.maximize")?.toString()}
                onClick={isAttachmentPanelMaxWidth ? undefined : maximizePanel}
            />
        </button>
        <button className="action_btn cursor-pointer mx-0 px-0"
                title={translate("taskDetail.minimize")?.toString()}
                type="button">
            <img
                className={`dark details__action-button ${isAttachmentPanelWidthDefault ? ' disabled' : ''}`}
                src={isAttachmentPanelWidthDefault ? minimizePanelDisabled : minimizePanelImg}
                alt={translate("taskDetail.minimize")?.toString()}
                onClick={isAttachmentPanelWidthDefault ? undefined : minimizePanel}
            />
        </button>
    </div>

}

export default translate(MinMaxGroup);