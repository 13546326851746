import * as Api from "utils/api/api";
import { handleError } from "utils/errorHandle.function";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as reduxSelectors from "components/store/application.reducers";
import { getTaskCommentsData } from "components/taskdetails-remake/taskDetails.action";
import { ActionCommentsProps } from "types/comments";
import translate from "components/translations/translations.wrapper";
import CommentsPanelComponent from "components/taskdetails-remake/panels/newcomments/CommentsPanel.component";
import { COMMENT_SAVING_STATUS } from "utils/constants";

import { cancelRequests } from "utils/api/api";


const ActionComments = (props: ActionCommentsProps) => {

    const [currentComment, setCurrentComment] = useState<string | null>(null);
    const [savingCommentStatus, setSavingCommentStatus] = useState(COMMENT_SAVING_STATUS.IDLE);
    const [offline, setOffline] = useState(false);
    const [saveTimeout, setSaveTimeout] = useState(undefined);
    const savedCurrentComment = useSelector(reduxSelectors.getTaskCurrentComment);
    const dispatch = useDispatch();
    const requests = [];

    useEffect(() => {
        setCurrentComment(null);
        setSavingCommentStatus(COMMENT_SAVING_STATUS.IDLE);
        if (!props.isDocument)
            requestData(props.taskId);
        return () => {
            cancelRequests(requests);
        };
    }, [props.taskId]);

    const requestData = (taskId) => {
        //get task comments
        if (!props.hasDocumentDataOnly) {
            dispatch(getTaskCommentsData(taskId));
        }
    };

    const addComment = async (comment) => {
        if (!navigator.onLine) {
            setOffline(true);
            setSavingCommentStatus(COMMENT_SAVING_STATUS.SAVING);
            setSaveTimeout(setTimeout(addComment, 1000, comment));
            return;
        } else {
            setOffline(false);
        }

        setSavingCommentStatus(COMMENT_SAVING_STATUS.SAVING);

        for (const request of requests) {
            await request;
        }

        requests.push(Api.insertComment(props.taskId, comment)
            .then(() => {
                setSavingCommentStatus(COMMENT_SAVING_STATUS.DONE);
            })
            .catch((error: any) => {
                setSavingCommentStatus(COMMENT_SAVING_STATUS.ERROR);
                if (error.errorCode !== 330) {
                    handleError(error);
                }
            }),
        );
    };

    const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        let comment = event.target.value;

        if (comment !== currentComment) {
            setCurrentComment(comment);
            setSavingCommentStatus(COMMENT_SAVING_STATUS.IDLE);

            if (saveTimeout !== undefined) {
                clearTimeout(saveTimeout);
            }

            setSaveTimeout(setTimeout(addComment, 300, comment));
        }
    };

    const saveCommentContent = savingCommentStatus === COMMENT_SAVING_STATUS.DONE ?
        <span className="m-16 vismaicon vismaicon-checkmark-dyn"
              data-tooltip-warning={props.translate("taskDetail.commentSavedTooltip")} /> :
        <div className="mx-16" />;

    return <CommentsPanelComponent
        readOnly={props.readOnly}
        commentsCount={props.commentsCount ? props.commentsCount : 0}
        comment={currentComment !== null ? currentComment : savedCurrentComment}
        savingCommentStatus={savingCommentStatus}
        automaticSave={true}
        offline={offline}
        onChange={onChange}
        saveCommentContent={saveCommentContent} />;
};


const withTranslations = translate(ActionComments);

export default withTranslations;

