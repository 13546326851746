import commentsDefaultUserImage from "assets/comments-default-user.png";
import { Tooltip } from "components/tooltip/Tooltip";
import UserProfileTooltip from "components/tooltip/UserProfileTooltip.component";
import { UserProfileProps } from "types/userDTO";

export function UserProfile(props: UserProfileProps) {
    const profilePictureBaseUrl = typeof window.ENVIRONMENT !== "undefined" && window.ENVIRONMENT.PROFILE_PICTURE_BASE_URL;
    const profilePicture = props.user.connectUserId ? `${profilePictureBaseUrl}${props.user.connectUserId}?type=initials` : commentsDefaultUserImage;
    const tooltipId = `${props.user.idUser}-approvers-row-tooltip-${props.uniqueKey}`;

    const setFallbackImage = function(event: any) {
        event.target.src = "https://static-odp.visma.net/commoncomponents/vismaSkin/images/services/default/medium.png";
    };

    return (
        <div className="display-flex align-items-center" data-testid={tooltipId}>
            <div className="user-profile  display-flex align-items-center cursor-pointer"
                 data-for={tooltipId} data-tip="">
                <img src={profilePicture}
                     width="24"
                     height="24"
                     alt="user profile avatar"
                     onError={(event) => {
                         setFallbackImage(event);
                     }}
                />
                <div>{props.user.fullName}</div>
            </div>
            <Tooltip
                id={tooltipId}
                content={() => <UserProfileTooltip user={props.user} />}
            />
        </div>
    );
}

export default UserProfile;