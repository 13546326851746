import React, {useEffect, useState} from "react";
import FetchAttachmentBlobFunction from "./FetchAttachmentBlob.function";
import {AttachmentRendererProps, RendererTypes, StatusRendererTypes} from "types/attachment";
import Image from "./attachmentTypes/Images/Image.function";
import Pdf from "./attachmentTypes/Pdf.function";
import Html from "./attachmentTypes/Html.function";
import PDFAlternativeView from "./attachmentTypes/PdfAlternativeView.function";
import Unknown from "./attachmentTypes/Unknown.function";
import Unavailable from "./attachmentTypes/Unavailable.function";
import {IMAGE_MIME_TYPE} from "utils/constants";
import BypassCIRSkeleton from "./BypassCIR.Skeleton";
import Immutable from "seamless-immutable";
import AttachmentInformation from "components/taskdetails-remake/panels/attachments/AttachmentInformation";
import {useSelector} from "react-redux";
import {Store} from "redux";
import * as reduxSelectors from "components/store/application.reducers";
import {launchDarkly} from "utils/launchDarkly";


const MimeTypeRenderer: RendererTypes = Immutable.from({
    "application/pdf": Pdf,
    "text/html": Html,
    "image/png": Image,
    "image/jpeg": Image,
    "image/jpg": Image,
    "image/tiff": Image,
    "text/xml": Unknown,

    asComponent(key: string) {
        return this.hasOwnProperty(key) ? this[key] : Unknown;
    }
});

const StatusTypeRenderer: StatusRendererTypes = {
    "UNAVAILABLE": Unavailable
}


const AttachmentRenderer = ({
                                attachment,
                                isPanelCollapsed,
                                collapseCallback
                            }: AttachmentRendererProps): React.ReactElement => {
    const [url, setUrl] = useState<string>("");
    const userData = useSelector((store: Store) => reduxSelectors.getUsersData(store));
    const enableAlternativeView: null | boolean = launchDarkly.userClient && launchDarkly.userClient.variation("enable-alternative-pdf-view", false) as boolean;


    const getAttachmentType = () => {
        if (attachment.mimeType === null || !attachment.mimeType)
            return Unknown;

        if (attachment.status === "OK" && attachment.mimeType.includes("pdf") && enableAlternativeView && !navigator?.pdfViewerEnabled) {
            return PDFAlternativeView;
        }

        return (attachment.status === "OK" ? MimeTypeRenderer.asComponent(attachment.mimeType) : StatusTypeRenderer[attachment.status]) || BypassCIRSkeleton;
    }

    const loadAttachments = () => {
        FetchAttachmentBlobFunction(attachment.originalUrl, attachment.mimeType, userData).then((result: string) => {
            setUrl(result)
        });
    }
    useEffect(() => {
        if (attachment.mimeType === null || !attachment.mimeType)
            return;
        if (!attachment.mimeType.includes(IMAGE_MIME_TYPE) && attachment.urlsOriginalJson && url === "") {
            loadAttachments();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const AttachmentRenderer = getAttachmentType();
    const hasAttachmentInformation = attachment.information?.uploaderId !== 0;

    return (
        <div className={`h-100 ${hasAttachmentInformation ? "pt-12" : ""}`}>
            {hasAttachmentInformation &&
                <AttachmentInformation {...attachment.information}
                                       canBeDeleted={attachment.deleteUrlTemplate}
                                       isPanelCollapsed={isPanelCollapsed}
                                       collapseCallback={collapseCallback}/>}
            <div
                className={`${hasAttachmentInformation ? `attachment-container-with-info ${isPanelCollapsed ? 'info-collapsed' : 'info-expanded'}` : "h-100"} `}>
                <AttachmentRenderer collection={attachment} url={url} callback={loadAttachments}/>
            </div>
        </div>
    )
}
export default AttachmentRenderer;




