import {SHARED_PARAMETERS} from "utils/api/api.impl";
import {snowplowTrackEvent} from "utils/snowplow";


const HTML_MIME_TYPE = "text/html";

export async function fetchAttachmentBlob(url: string, mimeType: string, userData: any): Promise<string> {

    let objectURL: string = "";
    let startTime = new Date().getTime();
    try {
        // @ts-ignore
        let approvalResponse = await fetch(url, SHARED_PARAMETERS);
        const urlToDataStorage = await approvalResponse?.json();


        let apiStorageUrl = urlToDataStorage.result + "?corsOrigin=" + encodeURIComponent(window.location.origin);
        let dataStorageReply = await fetch(apiStorageUrl);
        const attachmentBlob: Blob | undefined = await dataStorageReply?.blob();

        if (attachmentBlob) {
            const encodedBlob = mimeType === HTML_MIME_TYPE ? new Blob([attachmentBlob], {type: mimeType + ';charset=utf-8'}) : attachmentBlob;
            objectURL = URL.createObjectURL(encodedBlob);
        }

    } catch (error) {
        if (error instanceof Error)
            console.log("Could not get the attachments url " + error.message);
    }
    let stopTime = new Date().getTime();
    let total = ((stopTime - startTime)/1000).toString() || "unknown";

    snowplowTrackEvent({
        category: "bypass-cir",
        action: "fetch-attachment",
        label: "fetch-attachment",
        value: total
    }, userData);

    return objectURL;
}

export default fetchAttachmentBlob