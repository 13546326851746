import React, {Component} from 'react';
import {DOCUMENT_TYPE} from 'utils/constants';
import translate from '../../translations/translations.wrapper.jsx';
import {connect} from "react-redux";
import {getEnabledFeaturesForDocumentType, setStepAction} from "../workflowdetails.action";
import {FindEmployees, UserSearch} from "../../input/UserSearch.component";
import NumericInput from "react-numeric-input";
import * as api from "utils/api/api";
import {SimulatorProjects} from "./SimulatorProjects.component";
import {SimulatorBranches} from "./SimulatorBranches.component";
import {SimulatorHeader} from "./SimulatorHeader.component";
import {SimulatorDepartments} from "./SimulatorDepartments.component";
import {SimulatorAmount} from "./SimulatorAmount.component";
import {SimulatorCostUnits} from "./SimulatorCostUnits.component";
import * as reduxSelectors from "../../store/application.reducers";
import {getCostUnitsData} from "../../costunits/costunits.action";
import preloadingImage from "../../../workflow-preloading.svg";
import errorImage from "../../../workflow-error.svg";
import WorkflowMap from "../workflowmap/WorkflowMapView.component";
import SimulatorOrgansationChart from "./SimulatorOrganisationChart.component";
import _ from "lodash";

class Simulator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hideContent: true,
            requester: null,
            numberOfWorkDays: 1,
            excludeRequester: true,
            excludeInitialApprover: true,
            approvers: [],
            project: null,
            department: null,
            amount: 1,
            amountValid: true,
            currency: 'NOK',
            selectedCostUnits: [],
            simulatorError: false,
            errorMessages: [],
            simulatorImage: '',
            simulatorCoordinates: [],
            orgChartId: null
        };

        this.requesterRef = null;
        this.initialApproverRef = null;
        this.projectRef = null;
        this.branchRef = null;
        this.departmentRef = null;
        this.costUnitRef = null;
        this.orgChartIdRef = null;

        this.toggle = this.toggle.bind(this);
        this.simulate = this.simulate.bind(this);
        this.onReset = this.onReset.bind(this);
        this.onAmountChange = this.onAmountChange.bind(this)
    }

    componentDidMount() {
        this.props.getEnabledFeaturesForDocumentType(this.props.documentType);
        this.props.getCostUnitsData();
    }

    toggle() {
        this.setState({
            hideContent: !this.state.hideContent
        });
    }

    onAmountChange(event) {
        this.setState({
            amount: event.target.value,
            amountValid: event.target.value >= 1
        })
    }

    onReset(event) {
        if (this.requesterRef) {
            this.requesterRef.htmlInput.select.clearValue();
        }
        if (this.initialApproverRef) {
            this.initialApproverRef.htmlInput.select.clearValue();
        }
        if (this.projectRef) {
            this.projectRef.fieldRef.select.clearValue();
        }
        if (this.branchRef) {
            this.branchRef.fieldRef.select.clearValue();
        }
        if (this.departmentRef) {
            this.departmentRef.fieldRef.select.clearValue();
        }
        if (this.orgChartIdRef) {
            this.orgChartIdRef.select.clearValue();
        }
        if (this.costUnitRef) {
            this.costUnitRef.forEach((item) => {
                return item.select.clearValue();
            });
        }
        this.setState({
            requester: null,
            approvers: [],
            project: null,
            branch: null,
            numberOfWorkDays: 1,
            amount: 1,
            selectedCostUnits: [],
            simulatorError: false,
            errorMessages: [],
            simulatorImage: '',
            simulatorCoordinates: [],
            orgChartId: null
        });
    }


    simulate() {
        const activeSimulatorFields = this.props.features[this.props.documentType].simulatorFields;
        let simulatedWorkflow = _.cloneDeep(this.props.workflow);
        const document = {
            xtype: DOCUMENT_TYPE.asXtype(this.props.documentType),
            organizationUnitID: this.state.orgChartId ? this.state.orgChartId.value : null,
            costUnits: this.props.selectedCostUnitTypes.map((item, index) => {
                return {
                    dimension: this.props.selectedCostUnitTypes[index].dimension,
                    number: this.state.selectedCostUnits[index] ? this.state.selectedCostUnits[index].value : '',
                    dimensionName: item.name
                }
            })

        };

        if (this.props.documentType === DOCUMENT_TYPE.LEAVE_REQUEST) {
            document.numberOfDays = this.state.numberOfWorkDays;
        }

        if (activeSimulatorFields.indexOf('Requester') !== -1) {
            document.requester = this.state.requester ? this.state.requester.value : '';
            document.employeeCode = this.state.requester ? this.state.requester.id : null;
            document.excludeRequester = this.state.excludeRequester;
        }

        if (activeSimulatorFields.indexOf('InitialApprover') !== -1) {
            document.initiators = this.state.approvers.map((item, index) => {
                return item.value;
            });
            document.excludeInitiators = this.state.excludeInitialApprover;
        }

        if (activeSimulatorFields.indexOf('Project') !== -1) {
            document.projectID = this.state.project ? this.state.project.value : null;
        }

        if (activeSimulatorFields.indexOf('Branches') !== -1) {
            document.branchId = this.state.branch ? this.state.branch.value : null;
        }

        if (activeSimulatorFields.indexOf('Department') !== -1) {
            document.departmentID = this.state.department ? this.state.department.value : null;
        }

        if (activeSimulatorFields.indexOf('Amount') !== -1) {
            document.amount = this.state.amount;
            document.currencyCode = this.state.currency;
        }

        if (simulatedWorkflow.twoPersonsRule.enabled === false) {
            delete simulatedWorkflow.twoPersonsRule;
        }

        api.workflowSimulator(simulatedWorkflow, document).then((response) => {
            this.setState({
                simulatorImage: response.imageBase64,
                simulatorCoordinates: response.coordinates,
                simulatorError: false,
                errorMessages: []
            });
        }, (response) => {
            this.setState({
                simulatorImage: '',
                simulatorCoordinates: [],
                simulatorError: true,
                errorMessages: response.errorMessages ? response.errorMessages.split('\n') : ''
            });
        });
    }

    renderField(type, key) {
        switch (type) {
            case "Requester":
                return (
                    <div className="form-group margin-top" key={key}>
                        <label
                            className="form-label">{this.props.translate("workflowDetails.simulator.requester")}</label>
                        <FindEmployees ref={(field) => this.requesterRef = field}
                                       clearable={true}
                                       propagateValue={(value) => {
                                           this.setState({requester: value})
                                       }}
                                       value={this.state.requester}
                                       translate={this.props.translate}/>
                    </div>
                );
            case "ExcludeRequester":
                return (
                    <div className="form-group form-inline" key={key}>
                        <input type="checkbox"
                               defaultChecked={this.state.excludeRequester}
                               onChange={() => {
                                   this.setState({excludeRequester: !this.state.excludeRequester})
                               }}/>
                        <label
                            className="label-checkbox">{this.props.translate("workflowDetails.simulator.excludeRequester")}</label>
                    </div>
                );
            case "InitialApprover":
                return (
                    <div className="form-group mt-4" key={key}>
                        <label
                            className="form-label">{this.props.translate("workflowDetails.simulator.initialApprover")}</label>
                        <UserSearch value={this.state.approvers}
                                    multi={true}
                                    clearable={true}
                                    ref={(field) => this.initialApproverRef = field}
                                    propagateValue={(value) => {
                                        this.setState({approvers: value})
                                    }}
                                    userConfiguration={{}}
                                    translate={this.props.translate}
                        />
                    </div>
                );
            case "ExcludeInitialApprover":
                return (
                    <div className="form-group form-inline" key={key}>
                        <input type="checkbox"
                               defaultChecked={this.state.excludeInitialApprover}
                               onChange={() => {
                                   this.setState({excludeInitialApprover: !this.state.excludeInitialApprover})
                               }}/>
                        <label
                            className="label-checkbox">{this.props.translate("workflowDetails.simulator.excludeInitialApprover")}</label>
                    </div>
                );
            case "Project":
                return <SimulatorProjects key={key}
                                          translate={this.props.translate}
                                          project={this.state.project}
                                          onChange={(value) => {
                                              this.setState({project: value})
                                          }}
                                          ref={(field) => {
                                              this.projectRef = field
                                          }}/>
            case "Branches":
                return <SimulatorBranches key={key}
                                          translate={this.props.translate}
                                          project={this.state.branch}
                                          onChange={(value) => {
                                              this.setState({branch: value})
                                          }}
                                          ref={(field) => {
                                              this.branchRef = field
                                          }}/>
            case "Department":
                return <SimulatorDepartments key={key}
                                             translate={this.props.translate}
                                             project={this.state.department}
                                             onChange={(value) => {
                                                 this.setState({department: value})
                                             }}
                                             ref={(field) => {
                                                 this.departmentRef = field
                                             }}/>
            case "Amount":
                return <SimulatorAmount key={key}
                                        valid={this.state.amountValid}
                                        translate={this.props.translate}
                                        amount={this.state.amount}
                                        currency={this.state.currency}
                                        onCurrencyChange={(event) => {
                                            this.setState({currency: event.target.value})
                                        }}
                                        onAmountChange={this.onAmountChange}/>
            default:
                return <div/>
        }
    }

    render() {
        return (
            <div
                className={"col-md-12 margin-bottom step simulator " + (this.state.hideContent ? " collapsed" : "uncollapsed")}
                ref="step">

                <SimulatorHeader
                    collapsed={this.state.hideContent}
                    toggle={this.toggle}
                    translate={this.props.translate}
                    docType={this.props.documentType}
                />

                <div className={"toggleable-content" + (this.state.hideContent ? " toggled" : "")}>
                    <div className="row margin-top">
                        <div className="col-md-6">
                            <h4>&nbsp;</h4>
                            {(this.props.features[this.props.documentType] && this.props.features[this.props.documentType].simulatorFields) ?
                                this.props.features[this.props.documentType].simulatorFields.map((item, index) => {
                                    return this.renderField(item, index)
                                }) : null}
                            {this.props.documentType === DOCUMENT_TYPE.LEAVE_REQUEST ?
                                <div className="form-group">
                                    <label
                                        className="form-label margin-right">{this.props.translate("workflowDetails.simulator.noOfWorkDays")}</label>
                                    <NumericInput min={1} size={1}
                                                  value={this.state.numberOfWorkDays}
                                                  className="align-right vertical-center"
                                                  strict={true}
                                                  onChange={(val) => {
                                                      this.setState({numberOfWorkDays: val});
                                                  }}/>
                                </div>
                                : null}
                            {this.props.features[this.props.documentType] && this.props.features[this.props.documentType].actions.includes("Visma.Approval.Rules.FindByOrgChartRoleAction") ?
                                <SimulatorOrgansationChart translate={this.props.translate}
                                                           orgChartId={this.state.orgChartId}
                                                           onRefInit={(input) => {
                                                               this.orgChartIdRef = input
                                                           }}
                                                           onChange={(value) => {
                                                               this.setState({orgChartId: value})
                                                           }}/> : null
                            }
                        </div>
                        {this.props.selectedCostUnitTypes.length > 0 ?
                            <SimulatorCostUnits translate={this.props.translate}
                                                costUnitTypes={this.props.selectedCostUnitTypes}
                                                selected={this.state.selectedCostUnits}
                                                onChange={(value) => {
                                                    this.setState({selectedCostUnits: value})
                                                }}
                                                ref={(input) => {
                                                    this.costUnitRef = input ? input.inputRef : null
                                                }}/>
                            : null}
                    </div>

                    <div className="row">
                        <div className="col-md-6">

                        </div>
                        <div className="col-md-6" id="simulator-actions">
                            <button className="btn btn-primary margin-right"
                                    type="button"
                                    disabled={!this.state.amountValid}
                                    onClick={this.simulate}>{this.props.translate("workflowDetails.simulator.simulate")}</button>
                            <button className="btn btn-default"
                                    type="button"
                                    onClick={this.onReset}>{this.props.translate("workflowDetails.simulator.reset")}</button>
                        </div>
                        <div className="col-md-12" id="simulator-results">
                            {this.state.simulatorImage ?
                                <WorkflowMap url={this.state.simulatorImage}
                                             coordinates={this.state.simulatorCoordinates}/>
                                :
                                this.state.simulatorError ?
                                    <span>
                                        <SimulatorPlaceholder className={"simulator-error"}
                                                              image={errorImage}
                                                              alt={'error'}
                                                              title={this.props.translate("workflowDetails.simulator.placeholderErrorTitle")}
                                                              description={this.props.translate("workflowDetails.simulator.placeholderError")}
                                        />
                                        <div className="error-messages">
                                            <h3>
                                                <strong>{this.props.translate("workflowDetails.simulator.errorLog")}</strong>
                                                <hr/>
                                            </h3>
                                            {this.state.errorMessages.map((item) => {
                                                return (
                                                    <div>
                                                        {item}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </span> :
                                    <SimulatorPlaceholder className={"simulator-valid"}
                                                          image={preloadingImage}
                                                          alt={'preloading'}
                                                          title={this.props.translate("workflowDetails.simulator.placeholderTitle")}
                                                          description={this.props.translate("workflowDetails.simulator.placeholder")}
                                    />

                            }

                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

const withTranslations = translate(Simulator);

const mapStateToProps = function (store) {
    return {
        features: store.workflowDetails.features || {},
        selectedCostUnitTypes: reduxSelectors.getSeletedCostUnitTypes(store),
    };
};

const connected = connect(mapStateToProps, {
    setStepAction,
    getEnabledFeaturesForDocumentType,
    getCostUnitsData
})(withTranslations);
export default connected;

export function SimulatorPlaceholder(props) {
    return (
        <div id="simulator-placeholder" className={props.className}>
            <div className="row">
                <div className="col-md-4">
                    <img src={props.image} alt={props.alt}/>
                </div>
                <div className="col-md-8">
                    <h3>
                        {props.title}
                    </h3>
                    <p>
                        {props.description}
                    </p>
                </div>
            </div>
        </div>
    );
}