import React, { Component } from "react";
import PropTypes from "prop-types";
import closeIcon from "../../../src/assets/Icon-16-close.svg";
import { NOTIFICATION_TYPE } from "./notification.action";

export default class TransientNotification extends Component {

    static propTypes = {
        data: PropTypes.object,
        close: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.hide = this.hide.bind(this);
        this.state = { hiding: false, pauseHiding: false };
        this.pauseHide = this.pauseHide.bind(this);
        this.startHide = this.startHide.bind(this);
    }

    hide() {
        if (!this.state.pauseHiding)
            this.props.close(this.props.data.id);
    }

    pauseHide() {
        this.setState({
            hiding: false,
            pauseHiding: true,
        });
    }

    startHide() {
        // start css animation, css animation takes 3s
        setTimeout(() => {
            this.setState({ hiding: true, pauseHiding: false });
        }, 2500);
        // start complete removal after 5s
        setTimeout(this.hide, 5 * 1000);
    }

    componentDidMount() {
        this.startHide();
    }

    render() {
        return (
            <li className={(this.state.hiding ? "hiding-notification notification" : "notification")}
                onMouseOver={this.pauseHide}
                onMouseLeave={this.startHide}>
                <div
                    className={"toast right-4 " + (this.props.data.type === NOTIFICATION_TYPE.NEGATIVE_PERMANENT ? "toast-danger" : "toast-success")}
                    role="alert">
                    <span className="vismaicon vismaicon-filled vismaicon-info" aria-hidden="true" />
                    <span>
                            <div className="notification-text text-bold">{this.props.data.data}</div>
                        {this.props.data.action &&
                            <a className="text-primary text-no-underline font-13"
                               href={"#"}
                               onClick={this.props.data.action.callback}>
                                {this.props.data.action.info}
                            </a>
                        }
                        </span>
                    <img className={"close-icon cursor-pointer"} src={closeIcon} alt={"close"} onClick={(event) => {
                        this.props.close(this.props.data.id);
                    }} />
                </div>
            </li>
        );
    }
}