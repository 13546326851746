import React from 'react';
import TaskDetailsMasterLayer from './TaskDetailsMasterLayer.component';
import SSETaskDetailsMasterLayer from './SSETaskDetailsMasterLayer.component';
import {launchDarkly} from "utils/launchDarkly";

const ProcessTaskDetail = props => {
    const loadSystemOverviewSSE = launchDarkly.userClient && launchDarkly.userClient.variation("fe-load-system-overview-with-sse", false);

    if (loadSystemOverviewSSE)
        return <SSETaskDetailsMasterLayer  {...props}></SSETaskDetailsMasterLayer>;

    return <TaskDetailsMasterLayer  {...props}></TaskDetailsMasterLayer>;
};

export default ProcessTaskDetail;

