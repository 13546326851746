import history from "../../../assets/document-history-new.svg";
import workflow from "../../../assets/workflow_details.svg";
import comments from "../../../assets/comment-chat.svg";
import preview from "../../../assets/document-preview.svg";
import historyGrey from "../../../assets/document-history-grey-new.svg";
import workflowGrey from "../../../assets/workflow-history-grey.svg";
import commentsGrey from "../../../assets/comment-chat-grey.svg";
import previewGrey from "../../../assets/document-preview-grey.svg";

import {COMMENTS, DOCUMENT_DETAILS, DOCUMENT_PREVIEW, WORKFLOW_DETAILS} from "utils/constants.js";
import React from "react";
import {WarningIcon} from "../panels/documentdata/WarningsTooltip.component";


export function TaskDetailsSidebar(props) {

    const documentHasWarnings = props.documentData?.warnings?.length > 0;
    const {panelAvailability,panelVisibility, translate} = props;
    return (
        <div className="taskdetails-sidebar">
            <div className="fixed-buttons">
                <button className={`action_btn panel-button${panelAvailability[DOCUMENT_DETAILS] ? '' : ' disabled'}`}
                     title={panelVisibility[DOCUMENT_DETAILS] ? translate("taskDetail.panelButtons.hideDocumentDetails") : translate("taskDetail.panelButtons.showDocumentDetails")}
                     onClick={() => props.togglePanelVisibility(DOCUMENT_DETAILS)} type={"button"}>
                    <img src={panelVisibility[DOCUMENT_DETAILS] ? historyGrey : history}
                         alt={translate("taskDetail.panelButtons.documentDetails")}/>
                    {documentHasWarnings && !panelVisibility[DOCUMENT_DETAILS] ? <WarningIcon/> : null}
                </button>
                <button className={`action_btn panel-button${panelAvailability[WORKFLOW_DETAILS] ? '' : ' disabled'}`}
                     title={panelVisibility[WORKFLOW_DETAILS] ? translate("taskDetail.panelButtons.hideWorkflowDetails") : translate("taskDetail.panelButtons.showWorkflowDetails")}
                     onClick={() => props.togglePanelVisibility(WORKFLOW_DETAILS)} type={"button"}>
                    <img src={panelVisibility[WORKFLOW_DETAILS] ? workflowGrey : workflow}
                         alt={translate("taskDetail.panelButtons.workflowDetails")}/>
                </button>
                {props.isCommentsVisible &&
                    <button className={`action_btn panel-button${panelAvailability[COMMENTS] ? '' : ' disabled'}`}
                         title={panelVisibility[COMMENTS] ? translate("taskDetail.panelButtons.hideComments") : translate("taskDetail.panelButtons.showComments")}
                         onClick={() => props.togglePanelVisibility(COMMENTS)} type={"button"}>
                        <img src={panelVisibility[COMMENTS] ? commentsGrey : comments}
                             alt={translate("taskDetail.panelButtons.comments")}/>
                        {(!panelVisibility[COMMENTS] && props.commentsCount > 0) &&
                            <span className="panel-button-badge text-red">
                                    {props.commentsCount <= 99 ? props.commentsCount : '99+'}
                                </span>}
                    </button>}
                <button className={`action_btn panel-button${panelAvailability[DOCUMENT_PREVIEW] ? '' : ' disabled'}`}
                     title={panelVisibility[DOCUMENT_PREVIEW] ? translate("taskDetail.panelButtons.hideDocumentPreview") : translate("taskDetail.panelButtons.showDocumentPreview")}
                     onClick={() => props.togglePanelVisibility(DOCUMENT_PREVIEW)} type={"button"}>
                    <img src={props.panelVisibility[DOCUMENT_PREVIEW] ? previewGrey : preview}
                         alt={translate("taskDetail.panelButtons.documentPreview")}/>
                </button>
            </div>
        </div>
    )
}