import React, {useCallback, useEffect} from "react";
import translate from "components/translations/translations.wrapper";
import zoomFit from "components/taskdetails-remake/icons/zoom-fit.svg";
import rotateIcon from "assets/24_rotate.svg";
import printIcon from "assets/24_print.svg";
import downloadIcon from "assets/24_download.svg";
import zoomOutIcon from "components/taskdetails-remake/icons/icon-24-minus.svg";
import zoomInIcon from "components/taskdetails-remake/icons/icon-24-plus.svg";
import {useDispatch, useSelector} from "react-redux";
import * as reduxSelectors from "components/store/application.reducers";
import {Store} from "redux";
import {changeZoomPercentage} from "components/taskdetails-remake/taskDetails.action";
import _ from "lodash";


const MAX_ZOOM = 300;
const MIN_ZOOM = 50;

export const Menu = ({
                         totalImages,
                         rotateImage,
                         pageInView,
                         scrollToImage,
                         translate,
                         zoomPercentage,
                         changeZoom
                     }: any): React.ReactElement => {


    const dispatch = useDispatch();

    const downloadLinks = useSelector((store: Store) => reduxSelectors.getAttachmentsDownloadLinks(store));
    const menuRef = React.useRef<HTMLDivElement>(null);
    const zoomInputRef = React.useRef<HTMLInputElement>(null);
    const pageInViewRef = React.useRef<HTMLInputElement>(null);


    const handlePageChange = _.debounce((event: React.ChangeEvent<HTMLInputElement>) => {
        let value = parseInt(event.target.value, 10);
        if (!value) return;
        if (value > 0 && value <= totalImages) {
            scrollToImage(value);
        }

        if (pageInViewRef.current)
            pageInViewRef.current.value = event.target.value;
    },300);

    const handleChangeZoom = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = parseInt(event.target.value, 10);
        if (!value) return;
        if (zoomInputRef.current)
            zoomInputRef.current.value = value.toString();

        if (value >= 50 && value <= 300) {
            dispatch(changeZoom(value));
        }
    }

    const handleZoomOut = () => {
        let newZoom = (zoomPercentage - 20 >= MIN_ZOOM) ? zoomPercentage - 20 : MIN_ZOOM;
        if (newZoom !== zoomPercentage) {
            dispatch(changeZoom(newZoom));
        }
    }

    const handleZoomIn = () => {
        let newZoom = (zoomPercentage + 20 <= MAX_ZOOM) ? zoomPercentage + 20 : MAX_ZOOM;
        if (newZoom !== zoomPercentage) {
            dispatch(changeZoom(newZoom));
        }
    }

    const handleZoomToFit = () => {
        dispatch(changeZoomPercentage(100));
    }

    useEffect(() => {
        if (zoomInputRef.current)
            zoomInputRef.current.value = Math.ceil(zoomPercentage).toString();
    }, [zoomPercentage, zoomInputRef]);

    useEffect(() => {
        if (pageInViewRef.current)
            pageInViewRef.current.value = pageInView.toString();
    }, [pageInView, pageInViewRef]);


    return (
        <div
            className={`attachments-panel__images__menu d-flex align-items-center ${totalImages > 0 ? "justify-content-between" : "justify-content-center"}`}
            role={"menu"} ref={menuRef}>

            {totalImages > 0 &&
                <div className={"d-flex align-items-center"}>
                    <span className="mx-2">
                        <input type={"number"}
                               ref={pageInViewRef}
                               className={"px-2 py-1 text-center bg-transparent color-white page-in-view-input"}
                               onChange={handlePageChange}
                               min={1}
                               max={totalImages} step={1}/>
                    </span>
                    <span className={"mx-4 color-white"}>/</span>
                    <span className={"mx-2 color-white"}> {totalImages || 0}</span>
                </div>
            }


            <div className={"d-flex align-items-center"}>
                <button className="action_btn px-0" title={translate("taskDetail.zoomOut")} type="button">
                    <img
                        className={"details__action-button dark me-1 "}
                        src={zoomOutIcon}
                        alt={translate("taskDetail.zoomOut")}
                        onClick={handleZoomOut}/>
                </button>
                <span className="mx-2">
                        <input type={"number"}
                               ref={zoomInputRef}
                               className={"px-2 py-1 text-center bg-transparent color-white manual-zoom-input"}
                               onChange={useCallback(handleChangeZoom, [dispatch, changeZoom])}
                               min={MIN_ZOOM}
                               max={MAX_ZOOM} step={1}/>
                    </span>

                <button className="action_btn px-0" title={translate("taskDetail.zoomIn")} type="button">
                    <img
                        className={"details__action-button dark mx-1"}
                        src={zoomInIcon}
                        alt={translate("taskDetail.zoomIn")}
                        onClick={handleZoomIn}
                    />
                </button>
            </div>

            <div className={"d-flex justify-content-between align-items-center"}>
                {rotateImage &&
                    <div className={"d-flex align-items-center"}>
                        <button className="action_btn px-0" title={translate("taskDetail.zoomToFit")} type="button">
                            <img
                                className={`details__action-button dark mx-1`}
                                src={zoomFit}
                                alt={translate("taskDetail.zoomToFit")}
                                onClick={handleZoomToFit}
                            />
                        </button>

                        <button className="action_btn" title={translate("taskDetail.rotateRight")} type="button">
                            <img className="details__action-button dark rotate me-3 cursor-pointer"
                                 src={rotateIcon}
                                 alt={translate("taskDetail.rotateRight")}
                                 onClick={() => rotateImage()}/>
                        </button>
                    </div>
                }

                <div className={`d-flex align-items-center ${(totalImages > 0) ? " " : " position-absolute right-0"}`}>
                    <a className="details__action-button dark me-2" href={downloadLinks.downloadZIPUrl} download
                       title={translate("taskDetail.download")}>
                        <img src={downloadIcon}
                             alt={translate("taskDetail.download")}/>
                    </a>
                    <a className={"details__action-button dark me-3"}
                       href={downloadLinks.downloadPDFUrl} target='blank'
                       title={translate("taskDetail.print")}>
                        <img src={printIcon} alt={translate("taskDetail.print")}/>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default translate(Menu);