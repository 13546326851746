import React, {Fragment} from 'react';
import Menu from '../menu/Menu.component.jsx';
import SmallScreenAlert from './SmallScreenAlert.component';
import translate from '../translations/translations.wrapper.jsx';
import { Outlet } from "react-router-dom";

// this.props.children is determined by router definition
const PageLayout = (props) => {
      return (
        <div>
            <Menu location={props.location}/>
            <div className="app-width full-height-container container-fluid">
                {props.children}
                <Outlet />
                <SmallScreenAlert/>
            </div>
        </div>
    )
}
const withTranslations = translate(PageLayout);
export default withTranslations;
