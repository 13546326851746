import DocumentEditor from "./DocumentEditor.component";
import React from "react";
import {DETAILS_VIEW_TYPE} from "../../../../utils/constants";
import resizeLarge from "../../../../assets/editor_resize_large.png";
import resizeLargeActive from "../../../../assets/editor_resize_large_blue.png";
import resizeSmall from "../../../../assets/editor_resize_small.png";
import resizeSmallActive from "../../../../assets/editor_resize_small_blue.png";
import {snowplowTrackEvent} from "../../../../utils/snowplow";
import * as reduxSelectors from "../../../store/application.reducers";
import {connect} from "react-redux";

class DocumentEditorPanel extends React.Component {

    constructor(props) {
        super(props);

        this.getEditorExternalApplicationName = this.getEditorExternalApplicationName.bind(this);
        this.getDocumentType = this.getDocumentType.bind(this);
        this.getEditorExternalId = this.getEditorExternalId.bind(this);
        this.trackPanelCollapsing = this.trackPanelCollapsing.bind(this);
    }

    getEditorExternalApplicationName() {
        switch (this.props.type) {
            case DETAILS_VIEW_TYPE.TASK:
                return this.props.task.externalApplicationName;
            case DETAILS_VIEW_TYPE.HISTORY_TASK:
            case DETAILS_VIEW_TYPE.PROCESS:
                return this.props.task.applicationTypeName;
            case DETAILS_VIEW_TYPE.DOCUMENT:
                return this.props.task.applicationTypeName;
            default:
                return this.props.task.externalApplicationName;
        }
    }

    getDocumentType() {
        return this.props.task.documentType;
    }

    getEditorExternalId() {
        switch (this.props.type) {
            case DETAILS_VIEW_TYPE.TASK:
                return this.props.task.externalId;
            case DETAILS_VIEW_TYPE.HISTORY_TASK:
            case DETAILS_VIEW_TYPE.PROCESS:
                return this.props.task.externalApplicationId;
            case DETAILS_VIEW_TYPE.DOCUMENT:
                return this.props.task.claimId;
            default:
                return this.props.task.externalId;
        }
    }

    trackPanelCollapsing(isCollapsed) {
        snowplowTrackEvent({
            category: "external_editor_panel",
            action: "external_editor_collapsing",
            label: "external_editor_panel",
            value: isCollapsed ? "collapsed" : "extended"
        }, this.props.userConfiguration);
    }

    render() {
        const applicationName = this.props.task.externalApplicationName || this.props.task.applicationTypeName || '';
        const externalApplicationNameKey = applicationName.replace(/[ .]/g, '');
        //const isLarge = ['VismanetFinancials', 'VismanetAbsence', 'VismaBusinessCloud'].includes(externalApplicationNameKey);

        if (this.props.taskDetails.externalEditorAvailable) {
            return (
                <div className={`grid-layout-editor ${this.props.renderInPortal ? 'in-portal' : ''}`}>
                    <div className="grid-panel-wrapper">
                        <div className="grid-panel">
                            <div className="grid-panel-header d-flex justify-content-between align-items-center">
                                <strong>
                                    {this.props.translate("taskDetail.externalEditor")} {this.props.translationKeyExists(`taskDetail.applicationName.${externalApplicationNameKey}`) ?
                                    this.props.translate(`taskDetail.applicationName.${externalApplicationNameKey}`) : applicationName}
                                </strong>
                                <div className={`editor-resize-buttons ${this.props.inEditMode ? ' disabled' : ''}`}>
                                    <img src={this.props.renderInPortal ? resizeSmall : resizeSmallActive}
                                         alt="resize button"
                                         title={this.props.translate("taskDetail.placeAtTheSide")}
                                         className={`details__action-button ${this.props.renderInPortal ? '' : ' cursor-pointer'}`}
                                         onClick={() => {
                                             this.trackPanelCollapsing(true);
                                             this.props.onRenderInPortalChange(true);
                                         }}/>
                                    <img src={this.props.renderInPortal ? resizeLargeActive : resizeLarge}
                                         alt="resize button"
                                         title={this.props.translate("taskDetail.placeAtTheBottom")}
                                         className={`details__action-button ${this.props.renderInPortal ? 'cursor-pointer' : ''}`}
                                         onClick={() => {
                                             this.trackPanelCollapsing(false);
                                             this.props.onRenderInPortalChange(false);
                                         }}/>
                                </div>

                            </div>
                            <div className={`grid-editor ${this.props.renderInPortal ? '' : 'grid-editor-large'}`}>
                                <DocumentEditor translate={this.props.translate}
                                                taskId={this.props.params.taskId}
                                                id={this.props.taskDetails ? this.props.taskDetails.id : null}
                                                isHistory={this.props.isHistory}
                                                isReadOnly={this.props.editorReadOnly || this.props.taskDetails.readonly}
                                                externalAppId={this.getEditorExternalId()}
                                                externalApplicationName={this.getEditorExternalApplicationName()}
                                                documentType={this.getDocumentType()}
                                                companyId={this.props.task.companyId}
                                                reapproveType={this.props.taskDetails ? this.props.taskDetails.reapproveType : null}
                                                route={this.props.route}
                                                viewType={this.props.type}
                                                isPopup={false}/>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = function (store) {
    return {
        userConfiguration: reduxSelectors.getUsersData(store)
    };
};

const connected = connect(mapStateToProps, {})(DocumentEditorPanel);
export default connected;
