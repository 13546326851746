import {
    LOAD_MORE_PROCESS_OVERVIEW,
    LOGGED_IN_TYPE,
    PROCESS_ADVANCED_SEARCH_OPEN,
    PROCESS_OVERVIEW_COST_UNITS_LOADED,
    PROCESS_PREVENT_DATA_RELOAD,
    PROCESS_OVERVIEW_FILTER_CHANGED,
    PROCESS_OVERVIEW_LOADING,
    PROCESS_OVERVIEW_LOADED,
    PROCESS_OVERVIEW_PROCESS_TYPE_CHANGED,
    PROCESS_SAVE_SCROLL_POSITION
} from '../store/actionTypes';
import {createSelector} from 'reselect';
import {PROCESS_TYPE, LOADING_STATUS} from 'utils/constants';
import _ from "lodash";
import {getUsersCurrentContextId} from '../usercontext/user.reducer';

export const DEFAULT_STATE = {
    //boolean - to display the advanced search in states expanded/hidden
    advancedSearchOpen: false,
    costUnitsOptions: [],
    counters: null,
    filter: {
        costUnits: [], //cost units filter - has the format required for backend
        columns: {}, //top grid filtering
        account: undefined
    },
    loadingStatus: LOADING_STATUS.LOADING,
    //boolean to stop the gird data from refreshing when navigation details -> Process Overview
    preventReload: false,
    processType: PROCESS_TYPE.ACTIVE,
    //scroll position from top for the Process Overview grid
    scrollTop: 0,
    selectedTasks: [],
    //sorting logic needed for the reactabular grid
    sortingLogic: {},
    tasks: [],
    userRoles: null
};


export const getProcessOverviewTasks = createSelector(
    [getTasks, getOverviewUserRoles],
    (tasks, roles) => {

        let value = Object.assign({}, tasks);
        if (roles && tasks?.rows) {
            value.rows = tasks.rows.map(task => {
                let role = _.find(roles, ['actorTypeCode', task.actorType]);
                task.role = role ? role.actorTypeName : '';
                return task;
            });
        }

        return {
            allTasks: value.rows,
            records: tasks?.records,
            page: tasks?.page
        };
    }
);

export default function processOverviewReducer(state = DEFAULT_STATE, action) {
    let partialState;
    switch (action.type) {

        case  LOGGED_IN_TYPE:
            const newContextId = getUsersCurrentContextId(action.data);
            if (newContextId !== state.currentContext) {
                partialState = {
                    currentContext: newContextId,
                    processType: PROCESS_TYPE.ACTIVE,
                    filter: Object.assign({}, DEFAULT_STATE.filter),
                    sortingLogic: {},
                    selectedTasks: []
                };
            } else {
                partialState = Object.assign({}, state);
            }
            return Object.assign({}, state, partialState);

        case LOAD_MORE_PROCESS_OVERVIEW:
            let oldTasks = Object.assign([], state.tasks.rows);
            // concatenate old rows with new rows
            let allTasks = oldTasks.concat(action.data.rows);
            let newData = Object.assign({}, action.data);
            newData.rows = allTasks;
            partialState = {
                tasks: newData
            };
            return Object.assign({}, state, partialState);

        case PROCESS_ADVANCED_SEARCH_OPEN:
            partialState = {
                advancedSearchOpen: action.advancedSearchOpen
            };
            return Object.assign({}, state, partialState);

        case PROCESS_OVERVIEW_COST_UNITS_LOADED:
            partialState = {
                costUnitsOptions: action.data
            };
            //map new with old
            if (state.costUnitsOptions.length > 0) {
                let costUnits = [];
                costUnits = partialState.costUnitsOptions.map(costUnit => {
                    let currentValue = _.find(state.costUnitsOptions, (value => value.dimension === costUnit.dimension));
                    if (currentValue && currentValue.selectedValue) {
                        costUnit.selectedValue = currentValue.selectedValue;
                        costUnit.label = currentValue.label;
                    }
                    return costUnit;
                });
                partialState = {
                    costUnitsOptions: costUnits
                };
            }

            return Object.assign({}, state, partialState);


        case PROCESS_PREVENT_DATA_RELOAD:
            partialState = {
                preventReload: action.data
            };
            return Object.assign({}, state, partialState);

        case PROCESS_OVERVIEW_FILTER_CHANGED:
            partialState = {
                filter: action.filter
            };
            return Object.assign({}, state, partialState);


        case PROCESS_OVERVIEW_PROCESS_TYPE_CHANGED:
            let newFilters = state.filter;
            if (newFilters.columns.status)
                delete newFilters.columns.status;

            partialState = {
                processType: action.processType,
                sortingLogic: {},
                filter: newFilters
            };

            return Object.assign({}, state, partialState);

        case PROCESS_OVERVIEW_LOADING:
            partialState = {
                loadingStatus: LOADING_STATUS.LOADING,
                tasks: []
            };
            return Object.assign({}, state, partialState);

        case PROCESS_OVERVIEW_LOADED:
            partialState = Object.assign([], state);
            partialState.loadingStatus = LOADING_STATUS.DONE;
            partialState.tasks = action.data.tasks;
            partialState.userRoles = action.data.userRoles;
            return Object.assign({}, state, partialState);

        case PROCESS_SAVE_SCROLL_POSITION:
            partialState = {
                scrollTop: action.data
            };
            return Object.assign({}, state, partialState);

        default:
            return state;
    }
}

export function getProcessToggleAdvancedSearch(state) {
    return state.advancedSearchOpen;
}

export function getCostUnitsOptions(state) {
    return state.costUnitsOptions;
}

export function getOverviewCounters(state) {
    return state.counters;
}

export function getProcessOverviewFilter(state) {
    return state.filter;
}

export function getOverviewLoadingStatus(state) {
    return state.loadingStatus;
}

export function shouldPreventProcessReload(state) {
    return state.preventReload;
}

export function getProcessOverviewProcessType(state) {
    return state.processType;
}

export function getProcessScrollTop(state) {
    return state.scrollTop;
}

export function getTasks(state) {
    return state.tasks;
}

export function getOverviewUserRoles(state) {
    return state.userRoles;
}