import React from 'react';
import translate from '../translations/translations.wrapper.jsx';


const SmallScreenAlert = (props) => {
    return (
        <div id="min-rezolution-message">
            <div className="alert alert-danger">
             <span className="vismaicon vismaicon-lg vismaicon-filled vismaicon-error"/>
                <div>{props.translate("smallScreen.title")}</div>
                <br/>
                <div>
                    {props.translate("smallScreen.message")}
                    <br/>
                    {props.translate("smallScreen.appManager")}
                </div>
            </div>
        </div>
    )
}
const withTranslations = translate(SmallScreenAlert);
export default withTranslations;