import React, { Component } from "react";
import review from "../../../../assets/24_sent_for_review.svg";
import forwarded from "../../../../assets/24_forwarded_task.svg";
import assigned from "../../../../assets/24_my_task.svg";
import reassigned from "../../../../assets/24_reassign.svg";
import substitute from "../../../../assets/24_substitute.svg";
import unassigned from "../../../../assets/24_no_type.svg";
import translate from "../../../translations/translations.wrapper.jsx";
import UserProfile from "components/workflows/UserProfile.component";


export class WorkflowDetailsApproversRow extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const explanation = [].concat(
            this.props.assignment.explanation?.replace("Reason: ", "")
                .split("\n")
                .filter((part) => {
                    return part.trim() !== "";
                })
                .reverse(),
        );

        const icons = {
            REVIEW: review,
            FORWARDED: forwarded,
            ASSIGNED: assigned,
            REASSIGNED: reassigned,
        };

        const taskAssignmentTypeIcon = this.props.completed ? assigned : unassigned;
        return (
            <div className="row current-workflow-details-row">
                <div className="col-3 current-workflow-approver display-flex align-items-center">
                    <UserProfile user={this.props.assignment.userDTO} />
                </div>
                <div className="col-9 display-flex align-items-center">
                    <img
                        src={this.props.assignment.substitute ? substitute : (this.props.assignment.taskAssignmentType ? icons[this.props.assignment.taskAssignmentType] : taskAssignmentTypeIcon)}
                        alt={"reason icon"}
                        title={this.props.translate(`taskDetail.icons.${this.props.assignment.substitute ? "SUBSTITUTE" : (this.props.assignment.taskAssignmentType ? this.props.assignment.taskAssignmentType : "ASSIGNED")}`)}
                    />
                    <div>
                        {explanation[0]}
                        {explanation[1] ?
                            <div className="current-workflow-reason">
                                {explanation[1]}
                            </div> : null
                        }
                    </div>

                </div>
            </div>
        );
    }

}

const withTranslations = translate(WorkflowDetailsApproversRow);
export default withTranslations;
