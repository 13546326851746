import React, {Component} from "react";
import { DropdownButton, Dropdown} from "react-bootstrap";
import UserSearch from "../../input/UserSearch.component";
import {AssignApproversCopyRow} from "./CostUnitsApproversNewRow.component";
import {connect} from "react-redux";
import {editCostUnitsRoles, getCostUnitsBranches, saveCostUnitsRoles} from "../costunits.action";
import * as reduxSelectors from "../../store/application.reducers";
import okGreen from "../../../assets/okVectorGreen.svg"
import okGray from "../../../assets/okVectorGray.svg"
import rejected from "../../../assets/24_rejectedcolor.svg"
import {autoMenuStyle} from "../../../utils/reactSelectTheme";

class CostUnitsApproversRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inEditMode: this.props.lastRow,
            showNewRow: false,
            costUnit: this.props.costUnit
        };

        this.deleteRole = this.deleteRole.bind(this);
        this.enterEditMode = this.enterEditMode.bind(this);
        this.exitEditMode = this.exitEditMode.bind(this);
        this.saveRow = this.saveRow.bind(this);
        this.changeUser = this.changeUser.bind(this);
        this.changeRole = this.changeRole.bind(this);
        this.changeBranch = this.changeBranch.bind(this);
        this.changeDeputy = this.changeDeputy.bind(this);
        this.copyRow = this.copyRow.bind(this);
        this.hideNewRow = this.hideNewRow.bind(this);
        this.saveNewRow = this.saveNewRow.bind(this);
    }

    copyRow() {
        this.setState({showNewRow: true});
        this.props.setEditMode(true);
    }

    hideNewRow() {
        this.setState({showNewRow: false});
        this.props.setEditMode(false);
    }

    changeUser(user) {
        let newCostUnit = Object.assign({}, this.state.costUnit);
        newCostUnit.idUser = user.value;
        newCostUnit.userNameAndEmail = user.label;
        this.setState({
            costUnit: newCostUnit
        });
    }

    changeRole(value) {
        let newCostUnit = Object.assign({}, this.state.costUnit);
        newCostUnit.role = value.roleId;
        newCostUnit.roleName = value.name;
        this.setState({
            costUnit: newCostUnit
        });
    }

    changeBranch(value) {
        let newCostUnit = Object.assign({}, this.state.costUnit);
        newCostUnit.branchId = value.branchId;
        newCostUnit.branchName = value.name;
        this.setState({
            costUnit: newCostUnit
        });
    }

    changeDeputy() {
        let newCostUnit = Object.assign({}, this.state.costUnit);
        newCostUnit.deputy = !this.state.costUnit.deputy;

        this.setState({
            costUnit: newCostUnit
        });
    }

    deleteRole() {
        this.props.deleteCostUnitRole(this.props.costUnit.id, this.props.costUnit.idUser, this.props.costUnit.role, this.props.costUnit.branchId, this.props.nodeId, this.props.nodeLevel, this.props.sort);
    };

    enterEditMode() {
        this.setState({
            inEditMode: true,
            costUnit: this.props.costUnit
        });

        this.props.setEditMode(true);
    }

    exitEditMode() {
        this.setState({
            inEditMode: false
        });
        if (this.props.cancelEdit) {
            this.props.cancelEdit();
        }
        if (this.props.setEditMode)
            this.props.setEditMode(false);
    }

    saveRow() {
        if (this.props.lastRow) {
            this.props.saveCostUnitsRoles(this.props.nodeId, this.props.nodeLevel, this.state.costUnit.role, this.state.costUnit.idUser, this.state.costUnit.branchId, this.state.costUnit.deputy, this.props.costUnit.role, this.props.costUnit.idUser, this.props.sort);
        }
        else
            this.props.editCostUnitsRoles(this.props.nodeId, this.props.nodeLevel, this.state.costUnit.role, this.state.costUnit.idUser, this.state.costUnit.branchId, this.state.costUnit.deputy, this.props.costUnit.role, this.props.costUnit.idUser, this.props.costUnit.branchId, this.props.sort);
        this.exitEditMode();
    }

    saveNewRow(newCostUnitRole) {
        this.props.saveCostUnitsRoles(this.props.nodeId, this.props.nodeLevel, newCostUnitRole.role, newCostUnitRole.idUser, newCostUnitRole.branchId, newCostUnitRole.deputy, this.props.sort);
        this.hideNewRow();
    }

    render() {
        if (this.state.inEditMode) {
            let isSaveEnabled = this.state.costUnit.role && this.state.costUnit.idUser;

            return (
                <div className="row assign-approvers-row-edit">
                    <div className={`${this.props.costUnitsBranches.length > 0 ? "col-md-2" : "col-md-3"} `}>
                        <div className="margin-left-8 role-dropdown">
                            <DropdownButton id="cost_role"
                                            className="align-left"
                                            bsPrefix="btn btn-default"
                                            title={this.state.costUnit.roleName}
                                            onToggle={this.toggleCombo}>
                                {this.props.costUnitTypes.map((costUnit) => {
                                    return (<Dropdown.Item key={`${costUnit.name}-${costUnit.dimension}`}
                                                           value={costUnit.dimension}
                                                           type={costUnit.name}
                                                           eventKey={costUnit}
                                                           onClick={() => this.changeRole(costUnit)}>
                                        {costUnit.name}
                                    </Dropdown.Item>);
                                })}
                            </DropdownButton>
                        </div>
                    </div>
                    <div className={`${this.props.costUnitsBranches.length > 0 ? "col-md-4" : "col-md-6"}`}>
                        <UserSearch className="col-md-5" ref="recipientInput" propagateValue={this.changeUser}
                                    value={{
                                        label: this.state.costUnit.userNameAndEmail,
                                        value: this.state.costUnit.idUser
                                    }}
                                    styles={autoMenuStyle}
                                    menuPlacement={"auto"}
                                    menuPosition={"fixed"}
                                    menuPortalTarget={document.body}
                                    placeholder={this.props.translate("settings.substitute.selectUser")}
                                    includeSameUser={true}
                                    translate={this.props.translate}/>
                    </div>
                    {this.props.costUnitsBranches.length > 0 &&
                        <div className="col-md-3 branch-dropdown">
                            <DropdownButton id="cost_branch"
                                            className="align-left"
                                            bsPrefix="btn btn-default"
                                            title={this.state.costUnit.branchName || this.props.translate("costUnitAssignApprovers.anyBranch")}>
                                <Dropdown.Item
                                    key={0}
                                    value={0}
                                    eventKey={{ branchId: 0, name: this.props.translate("costUnitAssignApprovers.anyBranch") }}
                                    onClick={() => this.changeBranch({ branchId: 0, name: this.props.translate("costUnitAssignApprovers.anyBranch") })}>
                                    {this.props.translate("costUnitAssignApprovers.anyBranch")}
                                </Dropdown.Item>
                                {this.props.costUnitsBranches.filter((branch) => {
                                    return branch.active && !branch.deleted
                                }).map((branch, index) => {
                                    return (<Dropdown.Item key={branch.branchId || index}
                                                           value={branch.branchId}
                                                           type={branch.name}
                                                           eventKey={branch}
                                                           onClick={() => this.changeBranch(branch)}>
                                        {branch.name}
                                    </Dropdown.Item>);
                                })}
                            </DropdownButton>
                        </div>
                    }
                    <div className="col-md-2">
                        <div className="column-content">
                            {this.state.costUnit.deputy ?
                                <img src={okGreen} alt={"checked"} onClick={this.changeDeputy}/> :
                                <img src={okGray} alt={"not checked"} onClick={this.changeDeputy}/>
                            }
                        </div>
                    </div>

                    <div className="col-md-1 text-right">
                        <div className="column-content actions-edit-mode">
                            <button type="button"
                                    className={`btn btn-primary ${isSaveEnabled ? "" : " disabled"}`}
                                    onClick={this.saveRow}>
                                {this.props.translate("costUnitAssignApprovers.save")}
                            </button>
                            <img src={rejected} alt={"cancel"} onClick={this.exitEditMode}/>
                        </div>
                    </div>
                </div>
            )
        } else {
            const selectedBranch = this.props.costUnitsBranches.find((branch) => {
                return branch.branchId === this.props.costUnit.branchId
            }) || { branchId: 0, active: true};

            if (selectedBranch.branchId === 0) {
                selectedBranch.name = this.props.translate("costUnitAssignApprovers.anyBranch");
            }
            return (
                <div className={"assign-approvers-row" + (this.props.costUnit.hasRole ? "" : " text-danger text-bold")}>
                    <div className="row">
                        <div className={`${this.props.costUnitsBranches.length > 0 ? "col-md-2" : "col-md-3"}`}>
                            <div className="column-content">
                                {this.props.costUnit.roleName}
                            </div>
                        </div>
                        <div className={`approver ${this.props.costUnitsBranches.length > 0 ? "col-md-4" : "col-md-6"}`}>
                            <div className="column-content">
                                {this.props.costUnit.userNameAndEmail}
                            </div>
                        </div>

                        {this.props.costUnitsBranches.length > 0 &&
                            <div className="col-md-3">
                                <div
                                    title={!selectedBranch.active || selectedBranch.deleted ? this.props.translate("costUnitAssignApprovers.noBranchTooltip") : null                                         }
                                    className={`${!selectedBranch.active || selectedBranch.deleted ? 'column-content-error' : ''} column-content branch-name`}
                                >
                                    {selectedBranch.name}
                                </div>
                            </div>
                        }

                        <div className="col-md-1">
                            <div className="column-content">
                                {this.props.costUnit.deputy ?
                                    <img src={okGreen} alt={"checked"}/> :
                                    <img src={okGray} alt={"not checked"}/>
                                }
                            </div>
                        </div>
                        <div className="col-md-2 text-right">
                            <div className="column-content actions">
                                <span
                                    className={"vismaicon vismaicon-edit vismaicon-dynamic" + (this.props.isEditing || !this.props.allowActions ? " disabled" : "")}
                                    onClick={this.enterEditMode}/>
                                <span
                                    className={"vismaicon vismaicon-copy vismaicon-dynamic" + (this.props.isEditing || !this.props.allowActions ? " disabled" : "")}
                                    onClick={this.copyRow}/>
                                <span
                                    className={"vismaicon vismaicon-delete vismaicon-dynamic" + (this.props.isEditing || !this.props.allowActions ? " disabled" : "")}
                                    onClick={this.deleteRole}/>
                            </div>
                        </div>
                    </div>

                    {this.state.showNewRow &&
                        <AssignApproversCopyRow costUnit={this.props.costUnit}
                                                costUnitTypes={this.props.costUnitTypes}
                                                cancelEdit={this.hideNewRow}
                                                saveNewRow={this.saveNewRow}
                                                translate={this.props.translate}/>}
                </div>)
        }
    }
}

const mapStateToProps = function (store) {
    return {
        costUnitsBranches: reduxSelectors.getCostUnitBranches(store)
    }
};

export const AssignApproversRow = connect(mapStateToProps, {
    saveCostUnitsRoles,
    editCostUnitsRoles,
    getCostUnitsBranches,
})(CostUnitsApproversRow);