import { UserCommentAreaProps } from "types/userArea";

export function UserCommentArea(props: UserCommentAreaProps) {
    return (
        <div>
            <div className="mb-4 text-default">
                {props.message}
            </div>
            <br />
            <div className="text-default">{props.inputMessage}</div>
            <textarea
                id="workflow_user_comment_textarea"
                data-testid="workflow_user_comment_textarea"
                ref={props.userInput}
                className={"w-100 comment-box-textarea"} />
            <div className={"text-secondary font-13"}>
                {props.moreInfo}
            </div>
        </div>

    );
}

export default UserCommentArea;