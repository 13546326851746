import { Component } from "react";
import { launchDarkly } from "utils/launchDarkly";
import LegacyTable from "components/resizableTable/LegacyTable.component";
import SSETable from "components/resizableTable/SSETable.component";

export class ResizableTable extends Component {

    constructor(props) {
        super(props);
        this.loadSystemOverviewSSE = launchDarkly.userClient && launchDarkly.userClient.variation("fe-load-system-overview-with-sse", false);

    }
    render() {
        return this.loadSystemOverviewSSE ? <SSETable {...this.props}></SSETable> : <LegacyTable {...this.props}></LegacyTable>;
    }
}

export default ResizableTable;


