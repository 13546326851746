import React, {Component} from "react";
import {DropdownButton, Dropdown} from "react-bootstrap";
import UserSearch from "../../input/UserSearch.component";
import * as reduxSelectors from "../../store/application.reducers";
import {connect} from "react-redux";
import {editCostUnitsRoles, getCostUnitsBranches, saveCostUnitsRoles} from "../costunits.action";
import okGreen from "../../../assets/okVectorGreen.svg";
import okGray from "../../../assets/okVectorGray.svg";
import rejected from "../../../assets/24_rejectedcolor.svg";
import {autoMenuStyle} from "../../../utils/reactSelectTheme";

class CostUnitsApproversNewRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            costUnit: this.props.costUnit
        };

        this.saveRow = this.saveRow.bind(this);
        this.changeUser = this.changeUser.bind(this);
        this.changeRole = this.changeRole.bind(this);
        this.changeDeputy = this.changeDeputy.bind(this);
    }

    saveRow() {
        this.props.saveNewRow(this.state.costUnit);
    }

    changeUser(user) {
        let newCostUnit = Object.assign({}, this.state.costUnit);
        newCostUnit.idUser = user.value;
        newCostUnit.userNameAndEmail = user.label;
        this.setState({
            costUnit: newCostUnit
        });
    }

    changeRole(value) {
        let newCostUnit = Object.assign({}, this.state.costUnit);
        newCostUnit.role = value.roleId;
        newCostUnit.roleName = value.name;
        this.setState({
            costUnit: newCostUnit
        });
    }

    changeBranch(value) {
        let newCostUnit = Object.assign({}, this.state.costUnit);
        newCostUnit.branchId = value.branchId;
        newCostUnit.branchName = value.name;
        this.setState({
            costUnit: newCostUnit
        });
    }

    changeDeputy(event) {
        let newCostUnit = Object.assign({}, this.state.costUnit);
        newCostUnit.deputy = event.target.checked;

        this.setState({
            costUnit: newCostUnit
        });
    }

    render() {
        let isSaveEnabled = this.state.costUnit.role && this.state.costUnit.idUser;
        return (<div className="row assign-approvers-row-edit">

            <div className={`${this.props.costUnitsBranches.length > 0 ? "col-md-2" : "col-md-3"}`}>
                <div className="margin-left-8 role-dropdown">
                    <DropdownButton id="cost_role"
                                    className="align-left"
                                    bsPrefix="btn btn-default"
                                    title={this.state.costUnit.roleName}>
                        {this.props.costUnitTypes.map((costUnit) => {
                            return (<Dropdown.Item key={costUnit.name+"-"+costUnit.dimension}
                                                   value={costUnit.dimension}
                                                   type={costUnit.name}
                                                   eventKey={costUnit}
                                                   onClick={() => this.changeRole(costUnit)}>
                                {costUnit.name}
                            </Dropdown.Item>);
                        })}
                    </DropdownButton>
                </div>
            </div>
            <div className={`${this.props.costUnitsBranches.length > 0 ? "col-md-4" : "col-md-6"}`}>
                <UserSearch className="col-md-5" ref="recipientInput" propagateValue={this.changeUser}
                            value={{
                                label: this.state.costUnit.userNameAndEmail,
                                value: this.state.costUnit.idUser
                            }}
                            placeholder={this.props.translate("settings.substitute.selectUser")}
                            includeSameUser={true}
                            styles={autoMenuStyle}
                            menuPosition={"fixed"}
                            menuPortalTarget={document.body}
                            translate={this.props.translate}/>
            </div>
            {this.props.costUnitsBranches.length > 0 &&
                <div className="col-md-3 branch-dropdown">
                    <DropdownButton id="cost_branch"
                                    className="align-left"
                                    bsPrefix="btn btn-default"
                                    title={this.state.costUnit.branchName || this.props.translate("costUnitAssignApprovers.anyBranch")}>
                        <Dropdown.Item
                            key={0}
                            value={0}
                            eventKey={{ branchId: 0, name: this.props.translate("costUnitAssignApprovers.anyBranch") }}>
                            {this.props.translate("costUnitAssignApprovers.anyBranch")}
                        </Dropdown.Item>
                        {this.props.costUnitsBranches.filter((branch) => {
                            return branch.active && !branch.deleted
                        }).map((branch, index) => {
                            return (<Dropdown.Item key={branch.name+"-"+branch.branchId}
                                                   value={branch.branchId}
                                                   type={branch.name}
                                                   eventKey={branch}
                                                   onClick={() => this.changeBranch(branch)}>
                                {branch.name}
                            </Dropdown.Item>);
                        })}
                    </DropdownButton>
                </div>
            }
            <div className="col-md-2">
                <div className="column-content">
                    {this.state.costUnit.deputy ?
                        <img src={okGreen} alt={"checked"} onClick={this.changeDeputy}/> :
                        <img src={okGray} alt={"not checked"} onClick={this.changeDeputy}/>
                    }
                </div>
            </div>

            <div className="col-md-1 text-right">
                <div className="column-content actions-edit-mode">
                    <button type="button"
                            className={`btn btn-primary ${isSaveEnabled ? "" : " disabled"}`}
                            onClick={this.saveRow}>
                        {this.props.translate("costUnitAssignApprovers.save")}
                    </button>
                    <img src={rejected} alt={"cancel"} onClick={this.props.cancelEdit}/>
                </div>
            </div>

        </div>)
    }
}

const mapStateToProps = function (store) {
    return {
        costUnitsBranches: reduxSelectors.getCostUnitBranches(store)
    }
};

export const AssignApproversCopyRow = connect(mapStateToProps, {
    saveCostUnitsRoles,
    editCostUnitsRoles,
    getCostUnitsBranches,
})(CostUnitsApproversNewRow);