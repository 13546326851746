import React, {Component} from 'react';
import './Settings.scss';
import {Outlet} from 'react-router-dom';

export class Settings extends Component {

    render() {
        return (
            <main className="settings-page">
                <Outlet/>
            </main>
        );
    }
}

export default Settings;
