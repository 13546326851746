import React from 'react';
import './tasksActionButtons.scss';
import SplitButton from '../input/SplitButton.component';
import {TASK_ACTIONS} from 'utils/constants';
import {addTasksAmount} from 'utils/valueFormatter.function';
import {useNavigate} from "react-router-dom";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({actions, userSettings, selectedTaskKeys, tasks, action, translate}) => {
    /**
     * count the count/amount price of approve label
     * @param tasks
     * @return {{count: number, label: string}}
     */
    function computeApproveMessage(tasks) {

        let selectedTasks = [];
        tasks.forEach(task => {
            if (task.selected) {
                selectedTasks.push(task.task);
            }
        });


        let finalLabel = "";
        if (selectedTasks.length > 1) {
            finalLabel = " (" + selectedTasks.length + ")";
            let cost = addTasksAmount(selectedTasks);
            if (cost) {
                finalLabel = finalLabel + ": " + cost;
            }
        }
        if (userSettings.showCommentOnApprove || selectedTaskKeys.length > 1)
            finalLabel += " ...";

        return {
            count: selectedTasks.length,
            label: finalLabel
        };
    }

    const approveAllowed = actions.isEnabled(TASK_ACTIONS.APPROVE);
    const displayMessage = computeApproveMessage(tasks);

    const navigate = useNavigate();

    let actionList = actions.getSplitButtons().map((actionItem) => {

            let itemLabel = translate("myTasks.actionBar." + actionItem.type);
            // for 'open' action and at least 2 tasks selected change the label
            if (displayMessage.count > 1 && actionItem.type === TASK_ACTIONS.OPEN) {
                itemLabel = translate('myTasks.actionBar.' + TASK_ACTIONS.OPEN)
            }

            // split button item
            const res = {
                type: actionItem.type,
                active: actionItem.active,
                shortKey: actionItem.shortKey,
                onClick: () => {
                    action(actionItem.type, selectedTaskKeys, actionItem.options, navigate);
                },
                label: itemLabel
            };
            return res;
        }
    );

    const tasksWithQuickApprove = tasks.filter((task) => {return task.task.taskActions.includes("quick-approve") || task.task.taskActions.includes("review")});
    const showApproveSelected = tasks.length === tasksWithQuickApprove.length ;

    return (
        <footer
            className="fixed-bottom align-center task-action-buttons px-0 mb-3">
            <div className="float-right col-md-6 col-xs-6 align-right me-3">
                { showApproveSelected &&
                    <button
                        className={"btn btn-primary btn-margin-right align-right ast_approve_btn" + (approveAllowed ? "" : " disabled")}
                        onClick={() => {
                            action(TASK_ACTIONS.APPROVE, selectedTaskKeys);
                        }}>
                        {translate("myTasks.actionBar.approveSelected") + " " + displayMessage.label}
                    </button>
                }

                <SplitButton
                    initialLabel={translate('myTasks.actionBar.open')}
                    bsClass={"dropup float-right mx-0"}
                    actions={actionList}
                />
            </div>
        </footer>
    )
};

