import React, {Component} from 'react';
import translate from "../../translations/translations.wrapper.jsx";
import "./documentdata.scss";
import InvoiceDocumentDetailsDTO from "./documentdata/InvoiceDocumentDetailsDTO.component";
import PurchaseOrderDocumentDetailsDTO from "./documentdata/PurchaseOrderDocumentDetailsDTO.component";
import ExpenseDocumentDetailsDTO from "./documentdata/ExpenseDocumentDetailsDTO.component";
import LeaveRequestDocumentDetailsDTO from "./documentdata/LeaveRequestDocumentDetailsDTO.component";
import TimesheetDocumentDetailsDTO from "./documentdata/TimesheetDocumentDetailsDTO.component";
import VoucherDocumentDetailsDTO from "./documentdata/VoucherDocumentDetailsDTO.component";
import WorkflowDocumentDetailsDTO from "./documentdata/WorkflowDocumentDetailsDTO.component";
import {SupplierDocumentDetailsDTO} from "./documentdata/SupplierDocumentDetailsDTO.component";
import {DocumentDataDefault} from "./documentdata/DocumentDataDefault.component";
import {formatWarnings} from "./formatWarnings";
import {DOCUMENT_TYPE} from "utils/constants";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import * as Api from "utils/api/api";
import {connect} from "react-redux";
import * as reduxSelectors from "../../store/application.reducers";
import {getTaskDocumentData} from "../taskDetails.action";


class DocumentData extends Component {
    constructor(props) {
        super(props);
        this.loadDocumentData = this.loadDocumentData.bind(this);
        this.saveDocumentData = this.saveDocumentData.bind(this);
    }

    componentDidMount() {
        this.loadDocumentData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.taskId !== prevProps.taskId) {
            this.loadDocumentData();
        }
    }

    loadDocumentData() {
        this.props.getTaskDocumentData(this.props.taskId, this.props.isDocument)
    }

    saveDocumentData(data) {
        Api.postTaskDocumentDetails(this.props.taskId, data).then(() => {
            this.loadDocumentData();
        });
    }

    renderLoading() {
        return <div className="grid-document-data-panel-loading">
            <Skeleton className="title-loading-skeleton"/>
            <Skeleton className="value-loading-skeleton"/>
            <Skeleton className="title-loading-skeleton"/>
            <Skeleton className="value-loading-skeleton"/>
            <Skeleton className="title-loading-skeleton"/>
            <Skeleton className="value-loading-skeleton"/>
            <Skeleton className="title-loading-skeleton"/>
            <Skeleton className="value-loading-skeleton"/>
            {/*<Skeleton className="title-loading-skeleton" />*/}
            {/*<Skeleton className="value-loading-skeleton" />*/}
            {/*<Skeleton className="footer-loading-skeleton" />*/}
        </div>
    }

    render() {
        //Added 2 entries for Purchase Order in case the backend will rename the CostRequestDTO
        const typeMap = {
            "CostRequestDocumentDetailsDTO": PurchaseOrderDocumentDetailsDTO,
            "PurchaseOrderDocumentDetailsDTO": PurchaseOrderDocumentDetailsDTO,
            "ExpenseDocumentDetailsDTO": ExpenseDocumentDetailsDTO,
            "InvoiceDocumentDetailsDTO": InvoiceDocumentDetailsDTO,
            "LeaveRequestDocumentDetailsDTO": LeaveRequestDocumentDetailsDTO,
            "TimesheetDocumentDetailsDTO": TimesheetDocumentDetailsDTO,
            "VoucherDocumentDetailsDTO": VoucherDocumentDetailsDTO,
            "WorkflowDocumentDetailsDTO": WorkflowDocumentDetailsDTO,
            "SupplierDocumentDetailsDTO": SupplierDocumentDetailsDTO,
            "DocumentDataDefault": DocumentDataDefault
        };

        const DynamicDocumentType = this.props.documentData ? typeMap[this.props.documentData.xtype || "DocumentDataDefault"] : null;
        const formattedWarnings = this.props.documentData ? formatWarnings(this.props.documentData.warnings, Object.keys(this.props.documentData)) : {};

        // Testing top warning
        // formattedWarnings.hasTopWarning = true;
        // formattedWarnings[""] = [{ source: 'Autopay', message: "You have received a warning."}]

        return (
            <span>
                <div className="grid-document-data-panel">
                    <div className="grid-panel-header">
                        <strong>
                            {this.props.translate("taskDetail.details",this.props.translate("documentType." + DOCUMENT_TYPE.asString(this.props.documentType)) )}
                        </strong>
                    </div>
                    {formattedWarnings.hasTopWarning && topWarnings(formattedWarnings[""])}
                    <div id="document-data" className="grid-document-data-body">
                        {this.props.loading ?
                            this.renderLoading()
                            : (
                                this.props.documentData ?
                                    <DynamicDocumentType details={this.props.documentData}
                                                         translate={this.props.translate}
                                                         userLanguage={this.props.userLanguage}
                                                         saveData={this.saveDocumentData}
                                                         readOnly={this.props.readOnly}/>
                                    : null
                            )
                        }
                    </div>
                </div>
            </span>
        );
    }
}

function topWarnings(warningEntries) {
    const warningsBySource = _.groupBy(warningEntries, w => w.source);
    const content = Object.keys(warningsBySource).map((source) => {
        const warnings = warningsBySource[source];
        return (
            <span>
                <p><strong>{source}</strong></p>
                <p>
                    {warnings.map((warning, index) => {
                        return (<div key={index}>{warning.message}</div>);
                    })}
                </p>
            </span>
        )
    })
    return (
        <div className="alert alert-warning alert-nc4" id="document-data-warning">
            <span className="vismaicon vismaicon-lg vismaicon-filled vismaicon-warning" aria-hidden="true"/>
            {content}
        </div>
    )
}

const withTranslations = translate(DocumentData);

const connected = connect((store) => ({
    documentData: reduxSelectors.getTaskDocumentData(store),
    loading:reduxSelectors.getTaskDocumentDataLoadingStatus(store),
}), {getTaskDocumentData})(withTranslations);

export default connected;