import {launchDarkly} from "utils/launchDarkly";
import ProcessOverview from "components/processoverview/ProcessOverview.component";
import SSEProcessOverview from "components/processoverview/SSEProcessOverview.component";

export const SSEProcessOverviewWrapper = (props) => {
    const loadSystemOverviewSSE = launchDarkly.userClient && launchDarkly.userClient.variation("fe-load-system-overview-with-sse", false);

    if (loadSystemOverviewSSE)
        return <SSEProcessOverview {...props}/>;

    return <ProcessOverview {...props} />;
}

export default SSEProcessOverviewWrapper;