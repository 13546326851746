import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import Popup from './PopUp.component';
import Comment from '../input/Comment.component';
import * as Api from '../../utils/api/api.js';
import {TASK_ACTIONS} from '../../utils/constants';
import ErrorDialog from './ErrorDialog.component';
import {formatDate} from '../../utils/valueFormatter.function';
import NumericInput from 'react-numeric-input';
import classNames from 'classnames';
import translate from '../translations/translations.wrapper.jsx';
import './postponePopup.scss';
import Datepicker from "react-flatpickr";


let TOMORROW = new Date();
TOMORROW.setDate(TOMORROW.getDate() + 1);
TOMORROW = new Date(TOMORROW);

const RADIO_SELECTION = {
    daysBefore: 1,
    daysFromNow: 2,
    thisDay: 3,
};

//this is to make sure the tabbing stays inside the dialog
const TAB_INDEX = 1000;

/**
 * @param date Date
 * @return {boolean}
 */
function isAtLeastTomorrow(date) {
    const today = new Date();
    today.setDate(today.getDate() + 1);
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);
    return date.getTime() >= today.getTime();
}

export class Postpone extends Popup {

    static propTypes = {
        tasks: PropTypes.array, // selected tasks
        closeCallback: PropTypes.func, //callback for closing the popup
        translate: PropTypes.func, // to translate texts
        showNotification: PropTypes.func, // to show transient notification
        refreshTaskList: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.state = {
            daysBefore: 1,
            daysFromNow: 1,
            thisDay: this.getPostponedDate(props.tasks),
            radioSelected: RADIO_SELECTION.daysFromNow,
            comment: this.getOldPostponeComment(props.tasks),
            actionRunning: false,
            displayError: null // error to be shown
        };

        this.minDocDueDate = this.getMinDocumentDueDate(props.tasks);
        this.buildContent = this.buildContent.bind(this);
        this.focusFirst = this.focusFirst.bind(this);
        this.focusAfterCancel = this.focusAfterCancel.bind(this);
        this.switchRadio = this.switchRadio.bind(this);
        this.ok = this.ok.bind(this);
        this.changeDaysBefore = this.changeDaysBefore.bind(this);
        this.changeDaysFromNow = this.changeDaysFromNow.bind(this);
        this.changeThisDay = this.changeThisDay.bind(this);
        this.getPostponedDate = this.getPostponedDate.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
        this.focusFirst();
    }

    focusFirst() {
        this.dayFromNow?.focus();
    }

    focusAfterCancel() {
        if (this.daysBefore && !this.daysBefore.disabled)
            this.daysBefore.focus();
    }


    /**
     * because of parent
     */
    getType() {
        return TASK_ACTIONS.POSTPONE;
    }


    switchRadio(e) {
        this.setState({radioSelected: parseInt(e.target.value, 10)});
    }

    /**
     * returns sanitized data from inputs for current radio selection ..
     * adds 'valid':boolean field for validation (that we have valid data in selected 'option')
     * adds 'afterDocDueDate':boolean field for resolved value after #minDocDueDate
     * @param state
     * @param minDocDueDate earliest of document due dates (for validation)
     * @return {{valid: boolean, afterDocDueDate: boolean, daysFromNow: undefined, thisDay: undefined, daysBefore: undefined}}
     */
    extractValues(state, minDocDueDate) {
        let out = {
            valid: false,
            afterDocDueDate: false,
            daysFromNow: undefined,
            thisDay: undefined,
            daysBefore: undefined
        };

        let resolvedToDate;
        switch (state.radioSelected) {
            case RADIO_SELECTION.daysBefore:
                out.daysBefore = state.daysBefore;
                out.valid = this.isValidDaysBefore(minDocDueDate, state.daysBefore);
                // in case we're already over due date we disable this option, so we don't need to show warning
                resolvedToDate = minDocDueDate;
                break;
            case RADIO_SELECTION.daysFromNow:
                out.daysFromNow = state.daysFromNow;
                out.valid = this.isValidDaysFromNow(state.daysFromNow);
                resolvedToDate = new Date();
                resolvedToDate.setDate(resolvedToDate.getDate() + state.daysFromNow);
                break;
            case RADIO_SELECTION.thisDay:
            default:
                out.thisDay = state.thisDay.toISOString().substring(0, 10);
                out.valid = this.isValidThisDay(state.thisDay);
                resolvedToDate = new Date(state.thisDay);
                break;
        }
        // show warning, if it's valid input, but over minDocDueDate
        out.afterDocDueDate = out.valid && new Date(resolvedToDate).getTime() > minDocDueDate;
        return out;
    }

    /**
     * returns postpone last used postpone comment .. in case of multiple tasks, it returns only in case all the comments are the same
     * @param tasks
     * @return {string}
     */
    getOldPostponeComment(tasks) {
        let comment = '';
        for (let i = 0; i < tasks.length; i++) {
            let normalizedPostponeComment = tasks[i].postponedComment || '';
            if (comment !== '' && comment !== normalizedPostponeComment) return '';
            else comment = normalizedPostponeComment;
        }
        return comment;
    }

    getPostponedDate(tasks) {
        if (tasks.length === 1) {
            let postponedDate = new Date(tasks[0].dueDate).setHours("12");
            return new Date(postponedDate);
        }
        return TOMORROW;
    }

    /**
     * get earliest document due date from all tasks
     * @param tasks
     * @return {*}
     */
    getMinDocumentDueDate(tasks) {
        return tasks.reduce(function (acc, val) {
            if (acc === null) return val.documentDueDate;
            if (acc < val.documentDueDate) return acc;
            else return val.documentDueDate;
        }, null);
    }

    ok() {

        const self = this;
        const dayFields = this.extractValues(this.state, this.minDocDueDate);
        const taskKeys = this.props.tasks.map((task) => {
            return task.key;
        });

        if (this.state.comment && dayFields.valid) {
            this.setState({actionRunning: true});

            Api.postponeTask(taskKeys, this.state.comment, dayFields.thisDay, dayFields.daysFromNow, dayFields.daysBefore).then(function (response) {
                    if (response.success > 0) {
                        self.props.showNotification(self.props.translate("popUp.postpone.actionSuccess", taskKeys.length));

                        if (response.total !== response.success) {
                            const err = {
                                detail: {
                                    errorType: 'WARNING',
                                    errorTitle: self.props.translate("popUp.postpone.title"),
                                    errorMessages: self.props.translate("popUp.postpone.actionError")
                                }
                            };
                            self.setState({displayError: err});
                        } else {
                            self.props.closeCallback();
                            self.props.refreshTaskList();

                        }
                    } else {
                        const err = {
                            detail: {
                                errorType: 'WARNING',
                                errorTitle: self.props.translate("popUp.postpone.title"),
                                errorMessages: self.props.translate("popUp.postpone.actionError")
                            }
                        };
                        self.setState({displayError: err});
                    }

                },
                function (error) {
                    self.setState({displayError: error});
                }
            );
        }
    }

    changeDaysBefore(e) {
        let value = parseInt(e, 10);
        if (isNaN(value)) value = 0;
        // set regardless the validity value, because date input cannot reset itself back to last valid value
        // and for validity we will check inside #extractValues

        this.setState({daysBefore: value});

    }

    /**
     * @param minDocDueDate timestamp
     * @param value int
     * @return {*|boolean}
     */
    isValidDaysBefore(minDocDueDate, value) {
        if (!value || !minDocDueDate) return false;
        let base = new Date(minDocDueDate);
        base.setDate(base.getDate() - value);
        // cannot go in the past and accept only 2 chars
        return isAtLeastTomorrow(base) && String(value).length < 3;
    }

    changeDaysFromNow(e) {
        let value = parseInt(e, 10);
        if (isNaN(value)) value = 0;
        // set regardless the validity value, because date input cannot reset itself back to last valid value
        // and for validity we will check inside #extractValues
        this.setState({daysFromNow: value});
    }

    /**
     * @param value int
     * @return {boolean}
     */
    isValidDaysFromNow(value) {
        // cannot go in the past and accept only 2 chars
        return value >= 0 && String(value).length < 3;
    }

    changeThisDay(e) {
        const value = e[0];
        // set regardless the validity value, because date input cannot reset itself back to last valid value
        // and for validity we will check inside #extractValues
        this.setState({thisDay: value});
    }

    /**
     * @param value string (yyyy-mm-dd)
     * @return {*}
     */
    isValidThisDay(value) {
        if (!value) return false;
        // cannot go in the past
        return isAtLeastTomorrow(new Date(value));
    }

    buildContent() {

        const dayFields = this.extractValues(this.state, this.minDocDueDate);
        const okEnabled = !this.state.actionRunning && dayFields.valid && this.state.comment;

        let daysFromNowPreview = '';
        if (this.state.daysFromNow) {
            daysFromNowPreview = new Date();
            daysFromNowPreview.setDate(daysFromNowPreview.getDate() + this.state.daysFromNow);
            daysFromNowPreview = formatDate(daysFromNowPreview);
        }

        const daysBeforePreview = formatDate(this.minDocDueDate);
        const daysBeforeDisabled = this.minDocDueDate < Date.now();
        const afterDocDueDate = dayFields.afterDocDueDate ?
            <DateAfterDocumentDueDate translate={this.props.translate} formattedDate={daysBeforePreview}/> : null;


        const daysBeforeClass = classNames({
            "has-error": this.state.daysBefore && !this.isValidDaysBefore(this.minDocDueDate, this.state.daysBefore)
        });

        const daysFromNowClass = classNames({
            "has-error": this.state.daysFromNow && !this.isValidDaysFromNow(this.state.daysFromNow)
        });

        const thisDayClass = classNames({
            "has-error": this.state.thisDay && !this.isValidThisDay(this.state.thisDay)
        });

        return (
            <Fragment>
                {this.state.displayError && <ErrorDialog
                    error={this.state.displayError}
                    closeCallback={() => {
                        this.props.closeCallback();
                        this.props.refreshTaskList();
                    }}
                    translate={this.props.translate}
                />}
                <div className="modal-body postpone-dialog">
                    <p>
                        {this.props.translate("popUp.postpone.postponeExplanation")}
                    </p>

                    <table className="col-md-12 px-0 email-table" role="presentation">
                        <tbody>
                        <tr>
                            <td>{this.props.translate("popUp.postpone.remindMe")}</td>
                            <td>
                                <div className="radio">
                                    <input type="radio" name="optradio"
                                           className="option-radio"
                                           id="daysBefore"
                                           value={RADIO_SELECTION.daysBefore}
                                           checked={this.state.radioSelected === RADIO_SELECTION.daysBefore}
                                           disabled={daysBeforeDisabled}
                                           onChange={this.switchRadio}
                                           tabIndex={TAB_INDEX}
                                           ref={htmlInput => {
                                               this.daysBefore = htmlInput
                                           }}
                                    />
                                    <label htmlFor="daysBefore">
                                        {this.props.translate("popUp.postpone.daysBeforeDoc")}
                                    </label>
                                </div>
                            </td>
                            <td className={daysBeforeClass}>
                                <NumericInput disabled={this.state.radioSelected !== RADIO_SELECTION.daysBefore}
                                              value={this.state.daysBefore}
                                              aria-label={this.props.translate("popUp.postpone.daysBeforeDoc")}
                                              onChange={this.changeDaysBefore}
                                              min={1} max={99} step={1}
                                              tabIndex={TAB_INDEX + 1}
                                />
                                <span className="text-disabled input-preview">
                                    {this.props.translate("popUp.postpone.daysBeforeDocRelative", daysBeforePreview)}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td/>
                            <td>
                                <div className="radio">
                                    <input type="radio" name="optradio"
                                           className="option-radio"
                                           id="dayFromNow"
                                           checked={this.state.radioSelected === RADIO_SELECTION.daysFromNow}
                                           onChange={this.switchRadio}
                                           value={RADIO_SELECTION.daysFromNow}
                                           ref={htmlInput => {
                                               this.dayFromNow = htmlInput
                                           }}
                                           tabIndex={TAB_INDEX + 2}
                                    />
                                    <label htmlFor="dayFromNow">
                                        {this.props.translate("popUp.postpone.daysFromNow")}
                                    </label>
                                </div>
                            </td>
                            <td className={daysFromNowClass}>
                                <NumericInput disabled={this.state.radioSelected !== RADIO_SELECTION.daysFromNow}
                                              value={this.state.daysFromNow}
                                              min={1} max={99} step={1}
                                              tabIndex={TAB_INDEX + +3}
                                              aria-label={this.props.translate("popUp.postpone.daysFromNow")}
                                              onChange={this.changeDaysFromNow}

                                />
                                <span className="text-disabled input-preview">{daysFromNowPreview}</span>
                            </td>
                        </tr>
                        <tr>
                            <td/>
                            <td>
                                <div className="radio">
                                    <input type="radio" name="optradio"
                                           className="option-radio"
                                           id="thisDay"
                                           checked={this.state.radioSelected === RADIO_SELECTION.thisDay}
                                           onChange={this.switchRadio}
                                           value={RADIO_SELECTION.thisDay}/>
                                    <label htmlFor="thisDay">
                                        {this.props.translate("popUp.postpone.onThisDate")}
                                    </label>
                                </div>
                            </td>
                            <td className={thisDayClass}>
                                <Datepicker
                                    options={{
                                        defaultDate: TOMORROW,
                                        minDate: TOMORROW,
                                        dateFormat: 'd/m/Y',
                                    }}
                                    aria-label={this.props.translate("popUp.postpone.onThisDate")}
                                    disabled={this.state.radioSelected !== RADIO_SELECTION.thisDay}
                                    value={this.state.thisDay < TOMORROW ? TOMORROW : this.state.thisDay}
                                    onChange={this.changeThisDay}
                                    className={"flatpickr"}
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    {afterDocDueDate}
                    <div>
                        <label htmlFor="comment"><span
                            className="text-danger">* </span> {this.props.translate("popUp.postpone.commentLabel")}
                        </label>
                    </div>
                    <Comment
                        id="comment"
                        inputHint={this.props.translate("popUp.postpone.commentHint")}
                        translate={this.props.translate}
                        propagateValue={value => this.setState({comment: value})}
                        value={this.state.comment}
                        tabIndex={TAB_INDEX + 2}/>

                </div>
                <div className="modal-footer">
                    {this.props.isCurrentTaskHandled ?
                        <div className="float-left">
                            <button
                                className="btn btn-primary btn-margin-right "
                                onClick={this.props.closeCallback}
                                tabIndex={TAB_INDEX + 6}>{this.props.translate("popUp.reload")}
                            </button>
                        </div> :
                        <div className="float-right">
                            <button className="btn btn-primary btn-margin-right"
                                    onClick={this.ok}
                                    tabIndex={TAB_INDEX + 4}
                                    disabled={!okEnabled}>
                                {this.props.translate("popUp.ok")}
                            </button>
                            <button className="btn btn-default"
                                    tabIndex={TAB_INDEX + 5}
                                    onBlur={this.focusAfterCancel}
                                    onClick={this.props.closeCallback}>
                                {this.props.translate("popUp.cancel")}
                            </button>
                        </div>
                    }
                </div>
            </Fragment>

        )
    }

}

const withTranslations = translate(Postpone);
export default withTranslations;

const DateAfterDocumentDueDate = ({formattedDate, translate}) => {
    return (
        <div className="alert alert-sm clear col-md-12" role="alert">
            <span className="vismaicon vismaicon-filled vismaicon-warning" aria-hidden={true}> </span>
            {translate("popUp.postpone.afterDocDueDate", formattedDate)}
        </div>
    );
}